import Axios from 'axios';
import ENDPOINT from './addresses';

export const getCircuits = () => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/state`)
    .then((response) => resolve(response.data))
    .catch((error) => console.log(error));
});

export const postCircuitStateUpdate = (circuit) => new Promise((resolve) => {
  Axios.post(`${ENDPOINT}/api/v1/state`, {
    name: circuit.name, type: 'switch', state: circuit.state, source: 'ui',
  })
    .then((response) => resolve(response.data))
    .catch((error) => {
      console.log(error);
    });
});


export const postCircuitDimmingLevelUpdate = (circuit) => new Promise((resolve) => {
  Axios.post(`${ENDPOINT}/api/v1/state`, {
    name: circuit.name, type: 'switch', dimmingLevel: circuit.dimmingLevel, source: 'ui',
  })
    .then((response) => resolve(response.data))
    .catch((error) => {
      console.log(error);
    });
});

export const postValueUpdate = ({ name, value }) => new Promise((resolve) => {
  Axios.post(`${ENDPOINT}/api/v1/state/value/${name}`, {
    value,
  })
    .then((response) => resolve(response.data))
    .catch((error) => {
      console.log(error);
    });
});
