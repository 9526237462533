import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { QuestionCircleOutlined, CheckOutlined, NotificationOutlined } from '@ant-design/icons';
import {
  Typography, Button, Popover, Divider, Tooltip,
} from 'antd';
import BlownFuse from './BlownFuse';
import ActiveAlarm from './ActiveAlarm';


const { Paragraph } = Typography;

const Alerts = () => {
  const [visible, setVisible] = useState(false);
  const alarmsList = useSelector((state) => state.Alarms);
  const fuseList = useSelector((state) => state.Circuits.fuses);
  const alarmArray = Object.keys(alarmsList).map((alarm) => alarmsList[alarm]);
  const fuseArray = Object.keys(fuseList).map((fuse) => fuseList[fuse]);
  const trippedFuses = useMemo(() => fuseArray.filter((fuse) => fuse.tripped), [fuseArray]);
  const activeAlarms = useMemo(() => alarmArray.filter((alarm) => (alarm.active && !alarm.acknowledge) && alarm.name !== 'Summary'),
    [alarmArray]);
  const active = useMemo(() => {
    if (trippedFuses.length > 0) {
      return true;
    }
    if (activeAlarms.filter((alarm) => !alarm.acknowledge).length > 0) {
      return true;
    }
    return false;
  }, [trippedFuses, activeAlarms]);
  return (
    <div>
      <Popover
        placement="bottomRight"
        overlayClassName="alerts-popover"
        onVisibleChange={setVisible}
        visible={visible}
        content={(
          <div className="alerts-content-div">
            <div>
              <Paragraph className="alerts-title">
                Blown Fuses
              </Paragraph>
              <Divider />
              {trippedFuses.map((fuse) => (
                <BlownFuse key={fuse.name} fuse={fuse} setVisible={setVisible} />
              ))}
              {trippedFuses.length === 0 && (
              <Paragraph type="secondary" className="alerts-title">
                None
              </Paragraph>
              )}
            </div>
            <div>

              <Paragraph className="alerts-title">
                  Active Alarms&nbsp;
                <Tooltip
                  trigger="click"
                  title={(
                    <div>
                      <Paragraph
                        type="secondary"
                        style={{ textAlign: 'center', margin: 0 }}
                      >
                        Dismiss
                      </Paragraph>
                      <Paragraph
                        style={{ margin: 0 }}
                      >
                        Will deactivate the alarm&nbsp;
                        until the next time the alarm condition is met.
                      </Paragraph>
                      <Paragraph
                        type="secondary"
                        style={{ textAlign: 'center', margin: 0 }}
                      >
                        Disable
                      </Paragraph>
                      <Paragraph
                        style={{ margin: 0 }}
                      >
                       Will deactivate the alarm until it is re-enabled.
                      </Paragraph>
                    </div>
                  )}
                  placement="bottom"
                >
                  <QuestionCircleOutlined className="alarm-info-icon" />
                </Tooltip>
              </Paragraph>

              <Divider />
              {activeAlarms.map((alarm) => (
                <ActiveAlarm alarm={alarm} key={alarm.name} />
              ))}
              {activeAlarms.length === 0 && (
                <Paragraph type="secondary" className="alerts-title">
                  None
                </Paragraph>
              )}
            </div>
          </div>
        )}
        trigger="click"
      >
        <Button
          className={active ? 'alerts-button' : 'inactive-alert-button'}
          shape="circle"
          icon={!active ? <CheckOutlined /> : <NotificationOutlined />}
        >
          {active ? trippedFuses.length + activeAlarms.length : ''}
        </Button>
      </Popover>
    </div>
  );
};

export default Alerts;
