import React from 'react';
import { Row, Col } from 'antd';
import Battery from '../widgets/batteries/BatteryWidget';

const Batteries = () => (
  <Row justify="space-around">
    <Col md={8} sm={24} xs={24}>
      <Battery name="House_Bank" batteryName="House_Bank" />
    </Col>
    <Col md={8} sm={24} xs={24}>
      <Battery name="Start_Port" batteryName="Start_Port" />
    </Col>
    <Col md={8} sm={24} xs={24}>
      <Battery name="Start_Stbd" batteryName="Start_Stbd" />
    </Col>
  </Row>
);

export default Batteries;
