import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'antd';

const Rudders = () => (
  <div style={{ padding: 16 }}>
    <Row gutter={16} justify="space-around">
      <Col sm={12} xs={24}>
        <Link to="/actuators/Rudder_Elevator_Port">
          <Button type="primary" ghost className="hydraulic-menu-item" size="large">Rudder Port</Button>
        </Link>
      </Col>
      <Col sm={12} xs={24}>
        <Link to="/actuators/Rudder_Elevator_Stbd">
          <Button type="primary" ghost className="hydraulic-menu-item" size="large">Rudder Stbd</Button>
        </Link>
      </Col>
    </Row>
  </div>

);

export default Rudders;
