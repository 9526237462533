import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';

const { Text, Paragraph } = Typography;
const Sensor = ({
  label, format, actuator, id, displayConversion = null, rotatorPositionAngle, float = 0,
}) => {
  const value = useSelector((state) => state.Hydraulics[actuator][`analog_${id}`]);
  const renderedStatus = useMemo(() => {
    if (value === -1) {
      return 'N/A';
    }
    if (rotatorPositionAngle) {
      if (value === 32767) {
        return 'Fault';
      }
    }
    if (displayConversion) {
      return displayConversion(value).toFixed(float);
    }
    if (value === 65535) {
      return 'Fault';
    }
    return value.toFixed(float);
  }, [value, displayConversion, rotatorPositionAngle, float]);
  const renderedFormat = useMemo(() => {
    if (value === -1 || value === 65535) {
      return '';
    } if (format) {
      return format;
    }
    return '';
  }, [value, format]);
  return (
    <div className="hydraulic-sensor">
      <Text
        className="hydraulic-sensor-label"
        type="secondary"
      >
        {label}
      </Text>
      <Paragraph
        className="hydraulic-sensor-value"
      >
        {renderedStatus}
        <Text type="secondary" className="hydraulic-sensor-format">{renderedFormat}</Text>
      </Paragraph>
    </div>
  );
};

Sensor.propTypes = {
  label: PropTypes.string.isRequired,
  actuator: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  format: PropTypes.string,
  displayConversion: PropTypes.func,
  rotatorPositionAngle: PropTypes.bool,
  float: PropTypes.number,
};

export default Sensor;
