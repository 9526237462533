export const STOP = 0;
export const ON = 1;
export const SET = 2;
export const PUMP = 3;
export const PUMP_FAST = 4;
export const EASE = 5;
export const EASE_FAST = 6;
export const DUMP = 7;
export const TRIP = 8;
export const UNTRIP = 9;
export const PORT = 10;
export const STARBOARD = 11;
export const UP = 12;
export const DOWN = 13;
export const CLOCKWISE = 14;
export const CLOCKWISE_FAST = 15;
export const COUNTER_CLOCKWISE = 16;
export const COUNTER_CLOCKWISE_FAST = 17;
export const TACK = 18;
export const FORCE_STOP = 19;
export const MOVE = 20;
export const FAST_IDLE_OFF = 32;
export const FAST_IDLE_ON = 33;
export const FAST_IDLE_TIMED = 34;
