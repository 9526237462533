import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import GaugeChart from 'react-gauge-chart';

const RPM = ({ rpm, name, ignitionOn }) => {
  const renderRPMData = useMemo(() => {
    if (ignitionOn) {
      if (rpm) {
        if (rpm === -1) {
          return 'N/A';
        }
        return `${(rpm * 60).toFixed(0)} RPM`;
      }
      return 'No Data';
    }
    return 'Ignition Off';
  }, [ignitionOn, rpm]);
  return (
    <div className="engine-gauge">
      <GaugeChart
        id={name}
        arcsLength={[0.4, 0.3, 0.1, 0.2]}
        colors={['#3d3d3d', '#4d4d4d', '#DE8D05', '#A20021']}
        percent={(rpm * 60) / 4000}
        animate={false}
        formatTextValue={() => renderRPMData}
        arcPadding={0.01}
        textColor="rgba(255, 255, 255, 0.90)"
        needleColor="rgba(255, 255, 255, 0.33)"
        needleBaseColor="#1d1d1d"
      />
    </div>
  );
};

RPM.propTypes = {
  rpm: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  ignitionOn: PropTypes.number.isRequired,
};

export default RPM;
