import React from 'react';
import { Row, Col, Typography } from 'antd';
import { useSelector } from 'react-redux';
import Winch from './types/Winch';
import HydraulicsDisconnectedSpinner from '../../widgets/hydraulics/HydraulicsDisconnectedSpinner';

const Winches = () => {
  const hydraulicsConnected = useSelector((state) => state.ServerState.hydraulics);
  if (!hydraulicsConnected) {
    return <HydraulicsDisconnectedSpinner />;
  }
  return (
    <Row className="winch-page">
      <div className="winch-section">
        <Typography.Paragraph className="title">Pit Winches</Typography.Paragraph>
        <Row className="hydraulic-actions" justify="space-between">
          <Col xs={24} sm={11}>
            <Typography.Paragraph className="sub-title">Port</Typography.Paragraph>
            <Winch actuator="Winch_Pit_Port" type="Brushed_DC_Motor" />
          </Col>
          <Col xs={24} sm={11}>
            <Typography.Paragraph className="sub-title">Stbd</Typography.Paragraph>
            <Winch actuator="Winch_Pit_Stbd" type="Brushed_DC_Motor" />
          </Col>
        </Row>
      </div>
      <div className="winch-section">
        <Typography.Paragraph className="title">Traveler Winches</Typography.Paragraph>
        <Row className="hydraulic-actions" justify="space-between">
          <Col xs={24} sm={11}>
            <Typography.Paragraph className="sub-title">Port</Typography.Paragraph>
            <Winch actuator="Winch_Traveler_Port" type="Brushed_DC_Motor" />
          </Col>
          <Col xs={24} sm={11}>
            <Typography.Paragraph className="sub-title">Stbd</Typography.Paragraph>
            <Winch actuator="Winch_Traveler_Stbd" type="Brushed_DC_Motor" />
          </Col>
        </Row>
      </div>
      <div className="winch-section">
        <Typography.Paragraph className="title">Primary Winches</Typography.Paragraph>
        <Row className="hydraulic-actions" justify="space-between">
          <Col xs={24} sm={11}>
            <Typography.Paragraph className="sub-title">Port</Typography.Paragraph>
            <Winch actuator="Winch_Primary_Port" type="Brushed_DC_Motor" />
          </Col>
          <Col xs={24} sm={11}>
            <Typography.Paragraph className="sub-title">Stbd</Typography.Paragraph>
            <Winch actuator="Winch_Primary_Stbd" type="Brushed_DC_Motor" />
          </Col>
        </Row>
      </div>
      <div className="winch-section">
        <Typography.Paragraph className="title">Traveler Line Driver</Typography.Paragraph>
        <Row className="hydraulic-actions" justify="center">
          <Col xs={24} sm={11}>
            <Winch actuator="Traveler" type="Line_Driver" />
          </Col>
        </Row>
      </div>
    </Row>
  );
};

export default Winches;
