import React from 'react';
import { Row, Col } from 'antd';
import EngineWidget from '../widgets/engine/EngineWidget';

const EnginesPage = () => (
  <Row justify="space-around">
    <Col md={12} sm={18} xs={22}>
      <EngineWidget
        engineName="Engine_Port"
      />
    </Col>
    <Col md={12} sm={18} xs={22}>
      <EngineWidget
        engineName="Engine_Stbd"
      />
    </Col>
  </Row>
);

export default EnginesPage;
