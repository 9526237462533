// LIVE SERVER
// export const 'https://kinetic-server.decipher.digital';

// TEST SERVER
// export default 'https://kinetic-server.decipher.digital';
export default 'https://kinetic-julian.decipher.digital';

// REMOTE_TESTING_SERVER
// export default 'http://localhost:1337';

// LOCAL TEST SERVER
// export default 'http://192.168.21.113:1337';
