export const mobile_map = {
  name: 'mobile-map',
  areas: [
    {
      shape: 'poly', name: 'cabins/stbdGuest', displayName: 'Stbd Guest Cabin', coords: [245, 196, 313, 195, 314, 230, 313, 275, 314, 327, 312, 394, 245, 394, 244, 333],
    }, {
      shape: 'poly', name: 'cabins/owners', displayName: "Owner's Cabin", coords: [8, 172, 85, 173, 83, 263, 78, 264, 76, 394, 7, 394, 6, 300, 8, 230, 7, 196],
    }, {
      shape: 'poly', name: 'cabins/portGuest', displayName: 'Port Guest Cabin', coords: [71, 0, 78, 106, 84, 107, 86, 172, 11, 173, 11, 113, 14, 54, 15, 27, 16, 0],
    }, {
      shape: 'poly', name: 'cabins/crew', displayName: 'Crew Cabin', coords: [302, 2, 304, 53, 307, 107, 310, 171, 311, 192, 243, 193, 244, 101, 247, 1],
    }, {
      shape: 'poly', name: 'saloon', displayName: 'Saloon', coords: [86, 106, 122, 107, 154, 110, 153, 295, 79, 294, 83, 203, 84, 137],
    }, {
      shape: 'poly', name: 'navAndGalley', displayName: 'Nav Station & Galley', coords: [241, 104, 202, 100, 198, 110, 156, 110, 156, 297, 212, 298, 214, 290, 242, 291],
    },
  ],
};

export const sm_map = {
  name: 'sm-map',
  areas: [
    {
      shape: 'poly', coords: [270, 417, 350, 417, 352, 463, 352, 532, 351, 608, 348, 645, 274, 647, 268, 642], name: '/cabins/stbdGuest', displayName: 'Stbd Guest Cabin',
    },
    {
      shape: 'poly', coords: [8, 397, 95, 397, 93, 455, 90, 497, 87, 568, 88, 647, 9, 647, 6, 565, 7, 481], name: 'cabins/owners', displayName: "Owner's Cabin",
    },
    {
      shape: 'poly', coords: [18, 203, 81, 203, 83, 221, 86, 280, 87, 323, 93, 355, 92, 396, 10, 396, 11, 326, 14, 266], name: 'cabins/portGuest', displayName: 'Port Guest Cabin',
    },
    {
      shape: 'poly', coords: [277, 201, 337, 202, 343, 259, 346, 318, 349, 415, 270, 416, 270, 340], name: 'cabins/crew', displayName: 'Crew Cabin',
    },
    {
      shape: 'poly', coords: [89, 322, 135, 323, 179, 326, 179, 533, 123, 535, 91, 533], name: 'saloon', displayName: 'Saloon',
    },
    {
      shape: 'poly', coords: [180, 326, 221, 324, 223, 312, 269, 321, 268, 399, 268, 426, 270, 527, 238, 533, 181, 534], name: 'navAndGalley', displayName: 'Nav Station & Galley',
    },
  ],
};

export const md_map = {
  name: 'md-map',
  areas: [
    {
      shape: 'poly', coords: [136, 299, 222, 301, 260, 297, 314, 296, 386, 295, 387, 383, 311, 386, 236, 385, 173, 384, 136, 383], name: '/cabins/stbdGuest', displayName: 'Stbd Guest Cabin',
    },
    {
      shape: 'poly', coords: [134, 11, 224, 8, 273, 8, 341, 10, 386, 10, 408, 12, 409, 101, 333, 98, 297, 97, 220, 95, 137, 91], name: 'cabins/owners', displayName: "Owner's Cabin",
    },
    {
      shape: 'poly', coords: [411, 11, 488, 14, 543, 16, 620, 22, 620, 89, 589, 90, 535, 92, 490, 96, 458, 100, 412, 103], name: 'cabins/portGuest', displayName: 'Port Guest Cabin',
    },
    {
      shape: 'poly', coords: [388, 296, 467, 298, 536, 302, 619, 304, 620, 370, 537, 377, 442, 381, 389, 383], name: 'cabins/crew', displayName: 'Crew Cabin',
    },
    {
      shape: 'poly', coords: [257, 97, 338, 100, 411, 102, 458, 103, 490, 99, 491, 146, 484, 149, 485, 195, 259, 193], name: 'saloon', displayName: 'Saloon',
    },
    {
      shape: 'poly', coords: [257, 195, 486, 197, 486, 243, 498, 247, 496, 297, 407, 294, 374, 294, 259, 294], name: 'navAndGalley', displayName: 'Nav Station & Galley',
    },
  ],
};

export const lg_map = {
  name: 'lg-map',
  areas: [
    {
      shape: 'poly', coords: [155, 343, 257, 343, 297, 341, 356, 336, 440, 336, 441, 436, 389, 439, 331, 440, 249, 439, 154, 436], name: '/cabins/stbdGuest', displayName: 'Stbd Guest Cabin',
    },
    {
      shape: 'poly', coords: [152, 11, 292, 9, 395, 12, 466, 14, 467, 115, 380, 115, 297, 111, 253, 110, 154, 105], name: 'cabins/owners', displayName: "Owner's Cabin",
    },
    {
      shape: 'poly', coords: [469, 13, 554, 15, 620, 19, 709, 26, 710, 99, 658, 103, 613, 106, 560, 109, 520, 114, 469, 118], name: 'cabins/portGuest', displayName: 'Port Guest Cabin',
    },
    {
      shape: 'poly', coords: [442, 336, 523, 337, 591, 343, 673, 345, 708, 348, 708, 421, 658, 426, 598, 430, 525, 435, 442, 437], name: 'cabins/crew', displayName: 'Crew Cabin',
    },
    {
      shape: 'poly', coords: [295, 113, 408, 119, 468, 120, 519, 117, 560, 110, 560, 167, 553, 172, 554, 223, 295, 223], name: 'saloon', displayName: 'Saloon',
    },
    {
      shape: 'poly', coords: [295, 225, 555, 225, 555, 278, 569, 280, 566, 338, 527, 336, 466, 334, 430, 333, 377, 334, 330, 336, 296, 341], name: 'navAndGalley', displayName: 'Nav Station & Galley',
    },
  ],
};

export const xl_map = {
  name: 'xl-map',
  areas: [
    {
      shape: 'poly', coords: [174, 386, 288, 387, 333, 386, 413, 381, 494, 379, 495, 491, 409, 495, 308, 494, 173, 490], name: '/cabins/stbdGuest', displayName: 'Stbd Guest Cabin',
    },
    {
      shape: 'poly', coords: [174, 14, 266, 12, 362, 11, 454, 11, 526, 14, 529, 127, 429, 129, 333, 129, 287, 126, 174, 124], name: 'cabins/owners', displayName: "Owner's Cabin",
    },
    {
      shape: 'poly', coords: [530, 17, 606, 17, 689, 21, 797, 30, 797, 115, 747, 115, 691, 121, 635, 124, 585, 129, 530, 129], name: 'cabins/portGuest', displayName: 'Port Guest Cabin',
    },
    {
      shape: 'poly', coords: [495, 380, 537, 380, 593, 385, 686, 387, 796, 391, 797, 474, 690, 482, 619, 488, 497, 492], name: 'cabins/crew', displayName: 'Crew Cabin',
    },
    {
      shape: 'poly', coords: [333, 130, 433, 132, 530, 132, 585, 130, 630, 129, 632, 189, 624, 194, 623, 248, 333, 248], name: 'saloon', displayName: 'Saloon',
    },
    {
      shape: 'poly', coords: [333, 252, 624, 252, 627, 312, 641, 315, 638, 386, 526, 377, 425, 379, 333, 385], name: 'navAndGalley', displayName: 'Nav Station & Galley',
    },
  ],
};

export const full_size_map = {
  name: 'full-size-map',
  areas: [
    {
      shape: 'poly', name: 'cabins/stbdGuest', displayName: 'Stbd Guest Cabin', coords: [194, 421, 369, 421, 469, 420, 550, 422, 551, 547, 465, 551, 355, 551, 222, 548, 193, 546],
    }, {
      shape: 'poly', name: 'cabins/owners', displayName: "Owner's Cabin", coords: [193, 14, 278, 12, 345, 10, 418, 11, 491, 13, 583, 17, 584, 143, 472, 141, 369, 140, 318, 139, 194, 136],
    }, {
      shape: 'poly', name: 'cabins/portGuest', displayName: 'Port Guest Cabin', coords: [586, 16, 656, 18, 714, 21, 777, 25, 883, 33, 885, 123, 842, 128, 765, 133, 700, 137, 651, 140, 585, 143],
    }, {
      shape: 'poly', name: 'cabins/crew', displayName: 'Crew Cabin', coords: [552, 423, 661, 424, 764, 430, 885, 437, 885, 527, 764, 536, 553, 547],
    }, {
      shape: 'poly', name: 'saloon', displayName: 'Saloon', coords: [368, 142, 511, 144, 651, 144, 700, 140, 700, 212, 692, 215, 692, 279, 368, 277],
    }, {
      shape: 'poly', name: 'navAndGalley', displayName: 'Nav Station & Galley', coords: [367, 279, 693, 280, 694, 347, 709, 353, 708, 424, 660, 422, 368, 418],
    },
  ],
};
