import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { updateAlarm } from '../../../../api/alarms';

const Dismiss = ({
  name, dismissed, size = 'default', hasPermissions, isActive,
}) => {
  const dismissAlarm = () => {
    updateAlarm(name, { acknowledge: !dismissed });
  };

  if (!hasPermissions) {
    return (
      <Button
        type="link"
        size={size}
        className={`no-permissions ${isActive && 'active'}`}
      >
        Active
      </Button>
    );
  }
  return (
    <Button
      type="primary"
      size={size}
      onClick={dismissAlarm}
      disabled={!hasPermissions}
    >
        Dismiss
    </Button>

  );
};
Dismiss.propTypes = {
  name: PropTypes.string.isRequired,
  dismissed: PropTypes.bool.isRequired,
  size: PropTypes.string,
  hasPermissions: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
};
export default Dismiss;
