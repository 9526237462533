import Axios from 'axios';
import ENDPOINT from './addresses';

export const getPersistentData = () => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/persistence`)
    .then((response) => resolve(response.data))
    .catch((error) => {
      console.log(error);
    });
});

export const postPersistentData = ({ name, key, value }) => new Promise((resolve) => {
  Axios.post(`${ENDPOINT}/api/v1/persistence/${name}`, { key, value })
    .then((response) => resolve(response.data))
    .catch((error) => {
      console.log(error);
    });
});

export const updatePersistentData = ({
  name, key, value, id,
}) => new Promise((resolve) => {
  Axios.put(`${ENDPOINT}/api/v1/persistence/${name}/${id}`, { name, key, value })
    .then((response) => resolve(response.data))
    .catch((error) => {
      console.log(error);
    });
});

export const removePersistentData = ({ name, id }) => new Promise((resolve) => {
  Axios.delete(`${ENDPOINT}/api/v1/persistence/${name}/${id}`)
    .then((response) => resolve(response.data))
    .catch((error) => {
      console.log(error);
    });
});
