import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Radio, Switch,
} from 'antd';
import { useSelector } from 'react-redux';
import { postCircuitStateUpdate } from '../../../api/dc';
import CornerIcon from '../shared/CornerIcon';
import CircuitLabelValuePair from '../shared/CircuitLabelValuePair';
import { updateAlarm } from '../../../api/alarms';
import InactiveSwitch from '../../../assets/bilge/Inactive_Switch.svg';
import ActiveSwitch from '../../../assets/bilge/Active_Switch.svg';
import usePermissions from '../../../customHooks/usePermissions';


const { Text, Paragraph } = Typography;


const BilgeWidget = ({
  autoName, onOffName, title, alarmName, statusName, cycleCountName,
}) => {
  const alarm = useSelector((state) => state.Alarms[alarmName].enabled);
  const status = useSelector((state) => state.Circuits.loads[statusName]);
  const auto = useSelector((state) => state.Circuits.loads[autoName]);
  const onOff = useSelector((state) => state.Circuits.loads[onOffName]);
  const cycleCount = useSelector((state) => state.Circuits.values[cycleCountName]);
  const hasPermissions = usePermissions(onOff.meta.privilege);
  const hasAlarmPermissions = usePermissions('Alarms');
  const handleChange = (e) => {
    if (e.target.value === 'Auto') {
      postCircuitStateUpdate({ ...auto, state: 1 });
    }
    if (e.target.value === 'On') {
      postCircuitStateUpdate({ ...auto, state: 0 });
      postCircuitStateUpdate({ ...onOff, state: 1 });
    } if (e.target.value === 'Off') {
      postCircuitStateUpdate({ ...onOff, state: 0 });
      postCircuitStateUpdate({ ...auto, state: 0 });
    }
  };
  const selectValue = useMemo(() => {
    if (auto.state) {
      return 'Auto';
    }
    if (onOff.actualState) {
      return 'On';
    }
    return 'Off';
  }, [onOff, auto]);
  return (
    <div className="bilge-container">

      <CornerIcon
        icon="poweroff"
        side="left"
        width={150}
        tooltipTitle={(
          <div>
            <CircuitLabelValuePair
              label="Pump Running"
              leftSpan={18}
              rightSpan={6}
              value={onOff.actualState
                ? (<Text className="active">Yes</Text>)
                : (<Text>No</Text>)}
            />
            {auto.actualState ? (
              <CircuitLabelValuePair
                label="Cycle Count"
                leftSpan={18}
                rightSpan={6}
                value={cycleCount.value}
              />
            ) : null}
          </div>

        )}
        warning={!!onOff.actualState}
      />
      <Switch
        checked={!!alarm}
        onChange={() => updateAlarm(alarmName, { enabled: !alarm })}
        checkedChildren="Alarm On"
        unCheckedChildren="Alarm Off"
        disabled={!hasAlarmPermissions}
        style={{ position: 'absolute', right: 24, top: 24 }}
      />
      <img
        src={status.actualState ? ActiveSwitch : InactiveSwitch}
        width="42px"
        alt={status.actualState ? 'Active' : 'Inactive'}
        className={status.actualState
          ? 'bilge-switch-image switch-active'
          : 'bilge-switch-image switch-inactive'}
      />
      <Paragraph style={{ fontSize: 18, marginBottom: 6 }}>
        {title}
      </Paragraph>
      <Radio.Group
        onChange={handleChange}
        defaultValue={selectValue}
        value={selectValue}
        buttonStyle="solid"
        disabled={!hasPermissions}
      >
        <Radio.Button style={{ width: 75 }} value="Off">Off</Radio.Button>
        <Radio.Button style={{ width: 75 }} value="On">On</Radio.Button>
        <Radio.Button style={{ width: 75 }} value="Auto">Auto</Radio.Button>
      </Radio.Group>
    </div>
  );
};

BilgeWidget.propTypes = {
  autoName: PropTypes.string.isRequired,
  onOffName: PropTypes.string.isRequired,
  alarmName: PropTypes.string.isRequired,
  statusName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cycleCountName: PropTypes.string.isRequired,
};

export default BilgeWidget;
