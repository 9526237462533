import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import useInterval from '../../../customHooks/useInterval';

const ParameterButton = ({
  onRelease, onClick, speed, disabled, type,
}) => {
  const [clicked, setClicked] = useState(false);
  const renderIconByType = useMemo(() => {
    if (type === 'up') {
      return <ArrowUpOutlined />;
    }
    if (type === 'down') {
      return <ArrowDownOutlined />;
    }
    return <ExclamationCircleOutlined />;
  }, [type]);
  useInterval(() => {
    onClick();
  }, clicked ? speed : null);
  const buttonReleased = () => {
    if (clicked) {
      onRelease();
      setClicked(false);
    }
  };
  return (
    <Button
      icon={renderIconByType}
      type={clicked ? 'primary' : 'default'}
      onMouseDown={() => setClicked(true)}
      onTouchStart={() => setClicked(true)}
      onTouchEnd={buttonReleased}
      onTouchCancel={buttonReleased}
      disabled={disabled}
      onMouseUp={buttonReleased}
      onBlur={buttonReleased}
      onMouseLeave={buttonReleased}
      onMouseOut={buttonReleased}
      className="hydraulic-parameter-button"
    />
  );
};

ParameterButton.propTypes = {
  type: PropTypes.string.isRequired,
  onRelease: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  speed: PropTypes.number,
  disabled: PropTypes.bool,
};

export default ParameterButton;
