import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Row,
  Collapse,
  Col,
} from 'antd';
import Tank from '../../widgets/tanks/TankWidget';
import WasteTankLevelFullWidget from '../../widgets/tanks/WasteTankLevelFullWidget';
import TankAlarm from '../../widgets/tanks/TankAlarm';

const { Panel } = Collapse;

const TankDetails = () => {
  const { pathname } = useLocation();
  const tankName = pathname.replace('/tanks/', '');
  return (
    <div>
      <Row justify="space-around">
        <Col xs={24} sm={22} md={12}>
          <Tank
            tankName={tankName}
            showDetails={false}
          />
        </Col>
      </Row>
      <Collapse bordered={false} defaultActiveKey={['0']}>
        <Panel header="Alarms" key="0">
          <Row justify="space-around">
            <TankAlarm type="Low" tankName={tankName} />
            <TankAlarm type="High" tankName={tankName} />
          </Row>
        </Panel>
        { tankName.includes('Waste') && (
          <Panel header="Waste Full Shut Off" key="1">
            <WasteTankLevelFullWidget />
          </Panel>
        )}
      </Collapse>
    </div>
  );
};

export default TankDetails;
