import React, { useMemo } from 'react';
import { Radio, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { Blower_Bilge_Port, Bilge_Fan_Auto_Port } from '../../../api/circuits';
import { postCircuitStateUpdate } from '../../../api/dc';
import CircuitLabelValuePair from '../shared/CircuitLabelValuePair';
import CornerIcon from '../shared/CornerIcon';
import usePermissions from '../../../customHooks/usePermissions';

const { Paragraph, Text } = Typography;

const PortBilgeBlower = () => {
  const bilgeAuto = useSelector((state) => state.Circuits.loads[Bilge_Fan_Auto_Port]);
  const bilgeBlowerPort = useSelector((state) => state.Circuits.loads[Blower_Bilge_Port]);
  const sendUpdate = (circuit, state) => {
    postCircuitStateUpdate({ ...circuit, state });
  };
  const hasPermissions = usePermissions('Unrestricted');
  const handleChange = ({ target: { value } }) => {
    if (value === 'Off') {
      sendUpdate(bilgeAuto, 0);
      sendUpdate(bilgeBlowerPort, 0);
    } else if (value === 'On') {
      sendUpdate(bilgeAuto, 0);
      sendUpdate(bilgeBlowerPort, 1);
    } else if (value === 'Auto') {
      sendUpdate(bilgeAuto, 1);
      sendUpdate(bilgeBlowerPort, 0);
    }
  };
  const selectValue = useMemo(() => {
    if (!bilgeAuto.state) {
      if (bilgeBlowerPort.state) {
        return 'On';
      }
      return 'Off';
    }
    return 'Auto';
  }, [bilgeAuto, bilgeBlowerPort]);
  return (
    <div className="circuit-container">
      <CornerIcon
        side="left"
        width={100}
        active={!!bilgeBlowerPort.actualState}
        tooltipTitle={(
          <CircuitLabelValuePair
            className="ac-label-value-pair"
            label="Status"
            value={bilgeBlowerPort.actualState
              ? <Text style={{ float: 'right', color: '#6f92a2' }}>On</Text>
              : <Text style={{ float: 'right' }} type="secondary">Off</Text>}
          />
      )}
      />
      <Paragraph style={{ fontSize: 16 }}>Port Bilge Blower</Paragraph>
      <Radio.Group
        onChange={handleChange}
        defaultValue={selectValue}
        value={selectValue}
        buttonStyle="solid"
        disabled={!hasPermissions}
      >
        <Radio.Button style={{ width: 75 }} value="Off">Off</Radio.Button>
        <Radio.Button style={{ width: 75 }} value="On">On</Radio.Button>
        <Radio.Button style={{ width: 75 }} value="Auto">Auto</Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default PortBilgeBlower;
