import React from 'react';
import { Row, Col } from 'antd';
import {
  Windlass_Up, Windlass_Down, Windlass_Washdown_Valve,
} from '../../api/circuits';
import { Windlass_Over_Temperature } from '../../api/alarmsList';
import RenderCircuitByType from '../widgets/circuits/DCM/RenderCircuitByType';
import MomentaryCircuit from '../widgets/circuits/DCM/MomentaryCircuit';
import AlarmWidget from '../widgets/alarms/AlarmWidget';

const WindlassPage = () => (
  <div>
    <Row>
      <Col xs={24} sm={12}>
        <MomentaryCircuit circuitName={Windlass_Up} title="Windlass Up" />
      </Col>
      <Col xs={24} sm={12}>
        <MomentaryCircuit circuitName={Windlass_Down} title="Windlass Down" />
      </Col>
      <Col xs={24}>
        <RenderCircuitByType circuitName={Windlass_Washdown_Valve} title="Chain Washdown" />
      </Col>
    </Row>
    <Row justify="center">
      <AlarmWidget alarmName={Windlass_Over_Temperature} label="Motor Over Temp" boolean />
    </Row>
  </div>
);

export default WindlassPage;
