import Axios from 'axios';
import ENDPOINT from './addresses';

export const getEnables = () => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/enables`)
    .then((response) => resolve(response.data))
    .catch((error) => console.log(error));
});

export const sendEnableUpdate = ({ group_id, enable_id, enabled }) => new Promise((resolve) => {
  Axios.put(`${ENDPOINT}/api/v1/enables/${group_id}/${enable_id}`, { enabled })
    .then((response) => resolve(response.data))
    .catch((error) => console.log(error));
});
