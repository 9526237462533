import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import TouchButton from '../../../widgets/hydraulics/TouchButton';
import {
  MOVE, FORCE_STOP,
} from '../../../../actions/hydraulicActions';
import Sensor from '../../../widgets/hydraulics/Sensor';
import Parameter from '../../../widgets/hydraulics/Parameter';
import ZeroFiller from '../../../widgets/hydraulics/ZeroFiller';
import {
  displayToSixteenBitSigned, sixteenBitSignedToDisplay, multiply, divide,
} from '../../../widgets/hydraulics/parameterConversions';
import CommitParameters from '../../../widgets/hydraulics/CommitParameters';

const { Panel } = Collapse;

const LinearAbsolute = ({ actuator }) => {
  const targetPositionMinimum = useSelector((state) => state.Hydraulics[actuator].parameter_1);
  const targetPositionMaximum = useSelector((state) => state.Hydraulics[actuator].parameter_4);
  return (
    <div>
      <div className="hydraulic-actions">
        <Row>
          <Col span={12}>
            <TouchButton name="Go" action={MOVE} signalName={actuator} />
          </Col>
          <Col span={12}>
            <TouchButton name="Stop" action={FORCE_STOP} signalName={actuator} />
          </Col>
          <Col sm={12} xs={24}>
            <Parameter
              name="Target Angle"
              signalName={actuator}
              min={sixteenBitSignedToDisplay(targetPositionMinimum)}
              max={sixteenBitSignedToDisplay(targetPositionMaximum)}
              sendConversion={displayToSixteenBitSigned}
              displayConversion={sixteenBitSignedToDisplay}
              step={0.1}
              float={1}
              unit="&deg;"
              parameter={9}
            />
          </Col>
          <Col sm={12} xs={24}>
            <Sensor
              label="Current Angle"
              actuator={actuator}
              displayConversion={sixteenBitSignedToDisplay}
              float={1}
              id={0}
              format="&deg;"
            />
          </Col>
        </Row>
      </div>
      <Collapse bordered={false} defaultActiveKey={['1']}>
        <Panel header="Sensors" key="1">
          <Row justify="space-around">
            <Col sm={12} xs={24}>
              <Sensor
                label="Speed"
                actuator={actuator}
                displayConversion={(e) => divide(e, 10)}
                id={1}
                format="mm / second"
              />
            </Col>
            <Col sm={12} xs={24}>
              <Sensor
                label="Current"
                actuator={actuator}
                id={2}
                format="amps"
              />
            </Col>
          </Row>
        </Panel>
        <Panel header="Parameters" key="2">
          <CommitParameters actuator={actuator} />
          <Row justify="space-around">
            <Col sm={12} xs={24}>
              <Parameter
                name="Retracted Position"
                signalName={actuator}
                min={0}
                max={999}
                unit="mm"
                parameter={0}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Retracted Angle"
                signalName={actuator}
                step={0.1}
                float={1}
                min={-10}
                max={10}
                format="&deg;"
                sendConversion={displayToSixteenBitSigned}
                displayConversion={sixteenBitSignedToDisplay}
                parameter={1}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Zero Position"
                signalName={actuator}
                min={0}
                max={999}
                unit="mm"
                parameter={2}
              />
            </Col>
            <Col sm={12} xs={24}>
              <ZeroFiller
                label="Zero Angle"
                unit="&deg;"
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Extended Position"
                signalName={actuator}
                min={0}
                max={999}
                unit="mm"
                parameter={3}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Extended Angle"
                signalName={actuator}
                step={0.1}
                float={1}
                min={-10}
                max={10}
                format="&deg;"
                sendConversion={displayToSixteenBitSigned}
                displayConversion={sixteenBitSignedToDisplay}
                parameter={4}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Current Limit"
                signalName={actuator}
                min={1}
                max={99}
                unit="amps"
                parameter={5}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Speed Limit"
                signalName={actuator}
                min={1}
                max={100}
                unit="%"
                parameter={6}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Start Ramp"
                signalName={actuator}
                step={0.1}
                float={1}
                min={0.1}
                max={5}
                unit="s"
                sendConversion={(value) => multiply(value, 10)}
                displayConversion={(value) => divide(value, 10)}
                parameter={7}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Stop Ramp"
                signalName={actuator}
                step={0.1}
                float={1}
                min={0.1}
                max={5}
                unit="s"
                sendConversion={(value) => multiply(value, 10)}
                displayConversion={(value) => divide(value, 10)}
                parameter={8}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};

LinearAbsolute.propTypes = {
  actuator: PropTypes.string.isRequired,
};


export default LinearAbsolute;
