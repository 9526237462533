import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Typography } from 'antd';
import { postPersistentData } from '../../../../api/persistence';

const DimmingValueSetter = ({
  name, value, kind, currentValue, disabled,
}) => {
  const renderedCurrentValue = useMemo(() => (currentValue * 100).toFixed(), [currentValue]);
  const renderedNewValue = useMemo(() => (value * 100).toFixed(), [value]);
  if (currentValue !== value) {
    return (
      <Button disabled={disabled} type="link" onClick={() => postPersistentData({ name, key: kind, value })}>{`${renderedCurrentValue}% to ${renderedNewValue}%`}</Button>
    );
  }
  return (
    <Tooltip placement="bottom" title="Move dimmer to set new value" overlayClassName="button-dimming-level-tooltip">
      <Typography.Text ellipsis>{`${renderedCurrentValue}%`}</Typography.Text>
    </Tooltip>
  );
};

DimmingValueSetter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  kind: PropTypes.string.isRequired,
  currentValue: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DimmingValueSetter;
