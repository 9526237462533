import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Row, Col } from 'antd';
import TouchButton from '../../../widgets/hydraulics/TouchButton';
import {
  PUMP, EASE, DUMP, PUMP_FAST,
} from '../../../../actions/hydraulicActions';
import Sensor from '../../../widgets/hydraulics/Sensor';
import Parameter from '../../../widgets/hydraulics/Parameter';
import CommitParameters from '../../../widgets/hydraulics/CommitParameters';

const { Panel } = Collapse;

const PassiveRamWithDump = ({ actuator }) => (
  <div>
    <div className="hydraulic-actions">
      <Row>
        <Col sm={6} xs={12}>
          <TouchButton name="Pump" action={PUMP} signalName={actuator} />
        </Col>
        <Col sm={6} xs={12}>
          <TouchButton name="Pump Fast" action={PUMP_FAST} signalName={actuator} />
        </Col>
        <Col sm={6} xs={12}>
          <TouchButton name="Ease" action={EASE} signalName={actuator} />
        </Col>
        <Col sm={6} xs={12}>
          <TouchButton name="Dump" action={DUMP} signalName={actuator} />
        </Col>
      </Row>
    </div>
    <Collapse bordered={false} defaultActiveKey={['1']}>
      <Panel header="Sensors" key="1">
        <Row justify="space-around">
          <Col sm={12} xs={24}>
            <Sensor
              label="Pressure"
              actuator={actuator}
              id={0}
              format="BAR"
            />
          </Col>
        </Row>
      </Panel>
      <Panel header="Parameters" key="2">
        <CommitParameters actuator={actuator} />
        <Row justify="space-around">
          <Col sm={12} xs={24}>
            <Parameter
              name="Pressure Limit"
              signalName={actuator}
              min={0}
              max={actuator === 'Mainsheet' ? 250 : 400}
              unit="BAR"
              parameter={0}
            />
          </Col>
        </Row>
      </Panel>
    </Collapse>
  </div>
);

PassiveRamWithDump.propTypes = {
  actuator: PropTypes.string.isRequired,
};


export default PassiveRamWithDump;
