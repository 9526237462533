import React from 'react';
import { useLocation } from 'react-router-dom';
import RenderCircuitByType from '../../widgets/circuits/DCM/RenderCircuitByType';

const CircuitDetails = () => {
  const { pathname } = useLocation();
  const circuitName = pathname.replace('/circuits/', '');

  return (
    <RenderCircuitByType circuitName={circuitName} title={circuitName.replace(/_/g, ' ')} />
  );
};

export default CircuitDetails;
