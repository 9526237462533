import React from 'react';
import { Row, Col } from 'antd';
import {
  USB_Charging_Port_Fwd,
  USB_Charging_Port_Aft,
  USB_Charging_Stbd_Fwd,
  USB_Charging_Stbd_Aft,
  USB_Charging_Nav,
  USB_Charging_Helm_Port_Stbd,
} from '../../../api/circuits';
import RenderCircuitByType from '../../widgets/circuits/DCM/RenderCircuitByType';

const USBCharging = () => (
  <Row>
    <Col xs={24} sm={12}>
      <RenderCircuitByType circuitName={USB_Charging_Port_Fwd} title="Port Guest Cabin" />
    </Col>
    <Col xs={24} sm={12}>
      <RenderCircuitByType circuitName={USB_Charging_Stbd_Fwd} title="Crew Cabin" />
    </Col>
    <Col xs={24} sm={12}>
      <RenderCircuitByType circuitName={USB_Charging_Port_Aft} title="Owner's Cabin" />
    </Col>
    <Col xs={24} sm={12}>
      <RenderCircuitByType circuitName={USB_Charging_Stbd_Aft} title="Stbd Guest Cabin" />
    </Col>
    <Col xs={24} sm={12}>
      <RenderCircuitByType circuitName={USB_Charging_Nav} title="Nav Station" />
    </Col>
    <Col xs={24} sm={12}>
      <RenderCircuitByType circuitName={USB_Charging_Helm_Port_Stbd} title="Helm Port & Stbd" />
    </Col>
  </Row>
);

export default USBCharging;
