import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { updateAlarm } from '../../../../api/alarms';

const Enable = ({
  name, enabled, size = 'default', hasPermissions, isActive,
}) => {
  const setAlarmEnable = () => {
    updateAlarm(name, { enabled: !enabled });
  };
  if (!hasPermissions) {
    return (
      <Button
        type="link"
        size={size}
        className={`no-permissions ${isActive && 'active'}`}
      >
        {enabled ? 'Enabled' : 'Disabled'}
      </Button>
    );
  }
  return (
    <Button
      type={enabled ? 'primary' : 'default'}
      size={size}
      onClick={setAlarmEnable}
    >
      {enabled ? 'Disable' : 'Enable'}
    </Button>
  );
};
Enable.propTypes = {
  name: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  size: PropTypes.string,
  hasPermissions: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
};
export default Enable;
