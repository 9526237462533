import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import Icon, { DeliveredProcedureOutlined, PoweroffOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as PumpIcon } from '../../../assets/icons/pump.svg';

const System = () => (
  <div style={{ padding: 16 }}>
    <Row gutter={16} justify="space-around">
      <Col lg={6} md={12} sm={22} xs={24}>
        <Link to="/enables">
          <Button type="primary" ghost className="hydraulic-menu-item" size="large">
            <PoweroffOutlined />
            Enables
          </Button>
        </Link>
      </Col>
      <Col lg={6} md={12} sm={22} xs={24}>
        <Link to="/pumps">
          <Button type="primary" ghost className="hydraulic-menu-item" size="large">
            <Icon component={PumpIcon} />
        Pump Pressures
          </Button>
        </Link>
      </Col>
      <Col lg={6} md={12} sm={22} xs={24}>
        <Link to="/firmware">
          <Button type="primary" ghost className="hydraulic-menu-item" size="large">
            <DeliveredProcedureOutlined />
        Firmware
          </Button>
        </Link>
      </Col>
      <Col lg={6} md={12} sm={22} xs={24}>
        <Link to="/status">
          <Button type="primary" ghost className="hydraulic-menu-item" size="large">
            <InfoCircleOutlined />
            Status
          </Button>
        </Link>
      </Col>
    </Row>
  </div>
);

export default System;
