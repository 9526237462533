/* eslint-disable import/prefer-default-export */
import { message } from 'antd';
import {
  RECEIVE_CIRCUITS_UPDATE,
  RECEIVE_ACTUATOR_UPDATE,
  RECEIVE_STATUS_UPDATE,
  RECEIVE_STATUS_MESSAGE_UPDATE,
  FUSE_RESET,
  FUSE_TRIPPED,
} from './types';

export function updateCircuitState(update) {
  return ((dispatch) => {
    if (update.name === 'Server_Running') {
      return null;
    }
    if (update.type === 'fuse') {
      dispatch({
        type: RECEIVE_CIRCUITS_UPDATE,
        payload: {
          type: `${update.type}s`,
          circuit: update.name,
          [update.name]: {
            ...update,
          },
        },
      });
      const fuse = update.name;
      if (update.tripped) {
        dispatch({
          type: FUSE_TRIPPED,
          fuse,
        });
        message.warning(`Fuse Tripped: ${fuse.replace(/_/g, ' ')}`);
      } else {
        dispatch({ type: FUSE_RESET, fuse });
      }
    } else if (update.type === 'actuator') {
      dispatch({ type: RECEIVE_ACTUATOR_UPDATE, update });
    } else if (update.type === 'status_message') {
      dispatch({ type: RECEIVE_STATUS_MESSAGE_UPDATE, update });
    } else if (update.type === 'status') {
      dispatch({ type: RECEIVE_STATUS_UPDATE, update });
    } else if (update.type === 'value') {
      dispatch({
        type: RECEIVE_CIRCUITS_UPDATE,
        payload: {
          type: `${update.type}s`,
          circuit: update.name,
          [update.name]: {
            ...update,
          },
        },
      });
    } else if (update.type === 'load') {
      dispatch({
        type: RECEIVE_CIRCUITS_UPDATE,
        payload: {
          type: `${update.type}s`,
          circuit: update.name,
          [update.name]: {
            ...update,
          },
        },
      });
    } else {
      // console.log(update);
    }

    return null;
  });
}
