import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { postCircuitStateUpdate } from '../../../api/dc';

const { Text } = Typography;

const BlownFuse = ({ fuse, setVisible }) => {
  const resetFuse = () => {
    postCircuitStateUpdate({ name: fuse.name, state: 0 });
  };
  return (
    <div className="active-block">
      <Button type="link" className="reset-button" onClick={resetFuse}>Reset</Button>
      {fuse.meta.originalKind === 'Slave'
        ? (<Text style={{ padding: '0px 8px' }}>{fuse.name.replace(/_/g, ' ')}</Text>)
        : (
          <Tooltip title="View circuit page" placement="bottom">
            <Link
              style={{ padding: '0px 8px' }}
              onClick={() => setVisible(false)}
              to={`/circuits/${fuse.name}`}
            >
              {fuse.name.replace(/_/g, ' ')}
            </Link>
          </Tooltip>
        )}
    </div>
  );
};

BlownFuse.propTypes = {
  fuse: PropTypes.instanceOf(Object).isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default BlownFuse;
