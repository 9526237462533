import React from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Collapse, Col } from 'antd';
import AlarmWidget from '../../widgets/alarms/AlarmWidget';
import EngineWidget from '../../widgets/engine/EngineWidget';

const { Panel } = Collapse;

const EngineDetails = () => {
  const { pathname } = useLocation();
  const engineName = pathname.replace('/engines/', '');

  return (
    <div>
      <Row justify="space-around">
        <Col lg={12} md={16} sm={22} xs={24}>
          <EngineWidget engineName={engineName} showDetails={false} />
        </Col>
      </Row>
      <Collapse bordered={false} defaultActiveKey={['0']}>
        <Panel header="Alarms" key="0">
          <Row justify="space-around">
            <AlarmWidget
              label="Temperature High"
              alarmName={`${engineName}_Temperature`}
              span={12}
              step={1}
              thresholdMin={0}
              thresholdMax={100}
            />
            <AlarmWidget
              label="Low Oil Pressure"
              alarmName={`${engineName}_Oil_Pressure`}
              span={12}
              step={0.1}
              thresholdMin={0}
              thresholdMax={10}
            />
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};

export default EngineDetails;
