import React from 'react';
import {
  Row, Card, Typography, Col,
} from 'antd';
import { useSelector } from 'react-redux';
import KineticLogo from '../../assets/kinetic-scientific-about.png';

const { Text, Paragraph } = Typography;

const About = () => {
  const version = useSelector((state) => state.ServerState.version);
  // const role = useSelector((state) => state.UI.role)
  return (
    <Row justify="center" align="middle">
      <Col xs={20} sm={16} md={12}>
        <div className="about-page">
          <Card
            className="about-card"
            bordered={false}
            cover={<img alt="Kinetic Scientific" src={KineticLogo} />}
          />
          <div style={{ textAlign: 'center' }}>

            <Paragraph>
                    Server:
              <Text className="version-number">{version}</Text>
            </Paragraph>
            <Paragraph>
                    User Interface:
              <Text className="version-number">{process.env.REACT_APP_VERSION}</Text>
            </Paragraph>
            <Paragraph>
              {`© 2018 - ${new Date().getFullYear()} Kinetic Scientific`}
            </Paragraph>
            <Paragraph>+1 415 218 8103 | support@kineticscientific.com</Paragraph>
            {/* {role === 'Technical' ? <Link to="/debug" style={{ color: '#d9d9d9', textDecoration: 'none' }}><h6><small>Debug Mode</small></h6></Link> : null } */}
          </div>
        </div>
      </Col>

    </Row>
  );
};

export default About;
