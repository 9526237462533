import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import Sensor from '../../widgets/hydraulics/Sensor';
import HydraulicsDisconnectedSpinner from '../../widgets/hydraulics/HydraulicsDisconnectedSpinner';

const Pumps = () => {
  const hydraulicsConnected = useSelector((state) => state.ServerState.hydraulics);
  if (!hydraulicsConnected) {
    return <HydraulicsDisconnectedSpinner />;
  }
  return (
    <Row justify="space-around">
      <Col span={12}>
        <Sensor label="Power Pack 1 Pressure" span={11} format="BAR" actuator="Pump_Power_Pack_1" id={0} />
      </Col>
      <Col span={12}>
        <Sensor label="Power Pack 2 Pressure" span={11} format="BAR" actuator="Pump_Power_Pack_2" id={0} />
      </Col>
    </Row>
  );
};

export default Pumps;
