import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

const { Text, Paragraph } = Typography;

const ZeroFiller = ({
  label, unit = '',
}) => (
  <div className="hydraulic-sensor">
    <Text
      className="hydraulic-sensor-label"
      type="secondary"
    >
      {label}
    </Text>
    <Paragraph
      className="hydraulic-sensor-value"
    >
      {`0 ${unit}`}
    </Paragraph>
  </div>
);

ZeroFiller.propTypes = {
  label: PropTypes.string.isRequired,
  unit: PropTypes.string,
};

export default ZeroFiller;
