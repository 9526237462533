import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Col, Row,
} from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import RPM from './RPM';
import CornerIcon from '../shared/CornerIcon';

const { Paragraph, Text } = Typography;

const NotAvailable = () => (
  <Paragraph>
      N/A
  </Paragraph>
);
const ValueTitle = ({ title }) => (
  <Paragraph type="secondary">
    {title}
  </Paragraph>
);

ValueTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
const RenderedValue = ({
  conversion, value, roundingValue, unit,
}) => {
  if (value < 0) {
    return <NotAvailable />;
  }
  return (
    <Paragraph>
      {(conversion(value)).toFixed(roundingValue)}
      {unit && (<Text type="secondary" className="value-unit">{unit}</Text>)}
    </Paragraph>
  );
};
RenderedValue.propTypes = {
  conversion: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  roundingValue: PropTypes.number.isRequired,
  unit: PropTypes.string,
};

const DisconnectedEngine = ({ name }) => (
  <div>
    <RPM name={name} ignitionOn={0} rpm={-1} />
    <Paragraph style={{ textAlign: 'center' }} className="engine-name">{name}</Paragraph>
    <Row style={{ textAlign: 'center' }}>
      <Col span={8}>
        <ValueTitle title="Fuel Rate" />
        <NotAvailable />
      </Col>
      <Col span={8}>
        <ValueTitle title="Fuel Pressure" />
        <NotAvailable />
      </Col>
      <Col span={8}>
        <ValueTitle title="Oil Pressure" />
        <NotAvailable />
      </Col>
      <Col span={8}>
        <ValueTitle title="Temperature" />
        <NotAvailable />
      </Col>
      <Col span={8}>
        <ValueTitle title="Engine Load" />
        <NotAvailable />
      </Col>
      <Col span={8}>
        <ValueTitle title="Engine Hours" />
        <NotAvailable />
      </Col>
    </Row>
  </div>
);

DisconnectedEngine.propTypes = {
  name: PropTypes.string.isRequired,
};

const ConnectedEngine = ({ engineName, name, ignitionOn }) => {
  const {
    revolutions,
    runTime,
    engineLoad,
    oilPressure,
    temperature,
    rate,
    pressure,
  } = useSelector((state) => state.NMEA[engineName]);

  return (
    <div>
      <RPM name={name} ignitionOn={ignitionOn} rpm={revolutions.value} />
      <Paragraph style={{ textAlign: 'center' }} className="engine-name">{name}</Paragraph>
      <Row style={{ textAlign: 'center' }}>
        <Col span={8}>
          <ValueTitle title="Fuel Rate" />
          <RenderedValue
            conversion={(value) => value * 3600000}
            roundingValue={1}
            value={rate.value}
            unit="LPM"
          />
        </Col>
        <Col span={8}>
          <ValueTitle title="Fuel Pressure" />
          <RenderedValue
            conversion={(value) => value / 100000}
            roundingValue={1}
            value={pressure.value}
            unit="BAR"
          />
        </Col>
        <Col span={8}>
          <ValueTitle title="Oil Pressure" />
          <RenderedValue
            conversion={(value) => value / 100000000}
            roundingValue={1}
            value={oilPressure.value}
            unit="BAR"
          />
        </Col>
        <Col span={8}>
          <ValueTitle title="Temperature" />
          <RenderedValue
            conversion={(value) => value - 273}
            roundingValue={0}
            value={temperature.value.toFixed(0)}
            unit="&deg;C"
          />
        </Col>
        <Col span={8}>
          <ValueTitle title="Engine Load" />
          <RenderedValue
            conversion={(value) => value * 100}
            roundingValue={0}
            value={engineLoad.value}
            unit="%"
          />
        </Col>
        <Col span={8}>
          <ValueTitle title="Engine Hours" />
          <RenderedValue
            conversion={(value) => (value / 60) / 60}
            roundingValue={1}
            value={runTime.value}
          />
        </Col>
      </Row>
    </div>
  );
};

ConnectedEngine.propTypes = {
  engineName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ignitionOn: PropTypes.number.isRequired,
};

const EngineWidget = ({ engineName, showDetails = true }) => {
  const connected = useSelector((state) => state.ServerState.nmeaStatus[engineName]);
  const ignitionOn = useSelector((state) => state.Circuits.loads[`${engineName}_Ignition`].actualState);
  const name = engineName.replace('Engine_', '');
  return (
    <div className="engine-widget">
      {showDetails && (
      <span>
        <Link to={`/engines/${engineName}`}>
          <CornerIcon
            width={90}
            active={ignitionOn}
            tooltipTitle={<Text>View Details</Text>}
            side="left"
            trigger="hover"
          />
        </Link>
      </span>
      )}
      {!ignitionOn || !connected.transmitting ? (
        <DisconnectedEngine name={name} />
      ) : (
        <ConnectedEngine engineName={engineName} name={name} ignitionOn={ignitionOn} />
      )}
    </div>
  );
};

EngineWidget.propTypes = {
  engineName: PropTypes.string.isRequired,
  showDetails: PropTypes.bool,
};

export default EngineWidget;
