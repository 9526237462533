import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Collapse, Col } from 'antd';
import AlarmWidget from '../../widgets/alarms/AlarmWidget';
import BatteryWidget from '../../widgets/batteries/BatteryWidget';

const { Panel } = Collapse;

const BatteryDetails = () => {
  const { pathname } = useLocation();
  const batteryName = pathname.replace('/batteries/', '');

  const hasCells = batteryName === 'House_Bank';

  const minThresholdVoltage = useMemo(() => (batteryName.includes('Start') ? 9 : 18), [batteryName]);
  const maxThresholdVoltage = useMemo(() => (batteryName.includes('Start') ? 15 : 30), [batteryName]);

  return (
    <div>
      <Row justify="space-around">
        <Col lg={12} md={18} sm={24}>
          <BatteryWidget
            batteryName={batteryName}
            showDetails={false}
          />
        </Col>
      </Row>
      <Collapse bordered={false} defaultActiveKey={['1']}>
        {hasCells && (
        <Panel header="Batteries" key="0">
          <Row justify="space-around">
            <Col md={8} sm={24}>
              <BatteryWidget batteryName="House_Battery_1" />
            </Col>
            <Col md={8} sm={24}>
              <BatteryWidget batteryName="House_Battery_2" />
            </Col>
            <Col md={8} sm={24}>
              <BatteryWidget batteryName="House_Battery_3" />
            </Col>
          </Row>
        </Panel>
        )}
        <Panel header="Alarms" key="1">
          <Row justify="space-around">
            <AlarmWidget
              label="Low Voltage"
              alarmName={`${batteryName}_Voltage_Low`}
              span={12}
              step={0.1}
              thresholdMin={minThresholdVoltage}
              thresholdMax={maxThresholdVoltage}
            />
            <AlarmWidget
              label="High Voltage"
              alarmName={`${batteryName}_Voltage_High`}
              span={12}
              step={0.1}
              thresholdMin={minThresholdVoltage}
              thresholdMax={maxThresholdVoltage}
            />
            <AlarmWidget
              label="Low SOC"
              alarmName={`${batteryName}_SOC_Low`}
              span={12}
              step={0.01}
              thresholdMin={0}
              thresholdMax={1}
            />
            <AlarmWidget
              label="High SOC"
              alarmName={`${batteryName}_SOC_High`}
              span={12}
              step={0.01}
              thresholdMin={0}
              thresholdMax={1}
            />
            {batteryName.includes('House') && (
            <AlarmWidget
              label="Low Temperature"
              alarmName={`${batteryName}_Temperature_Low`}
              span={12}
              step={1}
              thresholdMin={-50}
              thresholdMax={50}
            />
            )}
            {batteryName.includes('House') && (
            <AlarmWidget
              label="High Temperature"
              alarmName={`${batteryName}_Temperature_High`}
              span={12}
              step={1}
              thresholdMin={-50}
              thresholdMax={50}
            />
            )}
          </Row>
        </Panel>

      </Collapse>
    </div>
  );
};

export default BatteryDetails;
