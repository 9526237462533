import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch } from 'antd';
import { updateAlarm } from '../../../../api/alarms';


const AudibleSwitch = ({ alarmName, hasPermissions }) => {
  const isAudible = useSelector((state) => state.Alarms[alarmName].isAudible);
  const active = useSelector((state) => state.Alarms[alarmName].active);
  const acknowledge = useSelector((state) => state.Alarms[alarmName].acknowledge);

  const isActive = useMemo(() => active && !acknowledge, [active, acknowledge]);
  return (
    <Switch
      checked={!!isAudible}
      onChange={() => updateAlarm(alarmName, { isAudible: !isAudible })}
      checkedChildren="Audible"
      unCheckedChildren="Visual"
      disabled={!hasPermissions}
      style={isActive && isAudible ? {
        position: 'absolute', right: 16, top: 16, background: '#de8d05',
      } : { position: 'absolute', right: 16, top: 16 }}
    />
  );
};

AudibleSwitch.propTypes = {
  alarmName: PropTypes.string.isRequired,
  hasPermissions: PropTypes.bool.isRequired,
};

export default AudibleSwitch;
