import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Slider, Input, Col, Typography, message, Row, Tooltip,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { postHydraulicParam } from '../../../api/hydraulics';
import ParameterButton from './ParameterButton';
import usePermissions from '../../../customHooks/usePermissions';

const { Text } = Typography;

const Parameter = ({
  signalName,
  parameter,
  name,
  min,
  max,
  unit = '',
  step = 1,
  float = 0,
  speed = 60,
  displayConversion = (e) => e,
  sendConversion = (e) => e,
}) => {
  const value = useSelector((state) => state.Hydraulics[signalName][`parameter_${parameter}`]);
  const valueIsFault = useMemo(() => {
    if (displayConversion(value) === 65535) {
      return true;
    }
    return false;
  }, [value, displayConversion]);
  const displayValue = useMemo(() => displayConversion(value), [value, displayConversion]);
  const [sliderValue, setSliderValue] = useState(displayValue);
  const [inputValue, setInputValue] = useState(displayValue);
  const [sliderChanging, setSliderChanging] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [editingValue, setEditingValue] = useState(false);
  const [loadingInput, setLoadingInput] = useState(false);
  const [lastSentValue, setLastSentValue] = useState(displayValue);
  const hasPermissions = usePermissions('Hydraulics');

  useEffect(() => {
    if (displayValue === sliderValue) {
      setSliderChanging(false);
    } else {
      setSliderChanging(true);
    }
    // Sets the loading value back to false once the sent value has been received.
    if (loadingInput && sliderChanging) {
      if (displayValue === sliderValue) {
        setLoadingInput(false);
      }
    }
    if (!sliderChanging) {
      if (displayValue !== sliderValue) {
        setSliderValue(displayValue);
      }
    }
  }, [setSliderChanging, sliderValue, displayValue, sliderChanging, loadingInput]);

  useEffect(() => {
    if (loadingInput && buttonPressed) {
      // Sets the loading & button state back to false once the sent value has been received.
      if (parseFloat(displayValue) === parseFloat(inputValue)) {
        setButtonPressed(false);
        setLoadingInput(false);
      }
    } else if (!editingValue && !buttonPressed) {
      setInputValue(displayValue);
    }
  }, [
    displayValue,
    buttonPressed,
    editingValue,
    inputValue,
    lastSentValue,
    loadingInput,
  ]);
  const onSliderChange = (val) => {
    setSliderChanging(true);
    setButtonPressed(false);
    setSliderValue(val);
  };

  const onAfterSliderChange = (val) => {
    if (lastSentValue !== val) {
      setLoadingInput(true);
      postHydraulicParam(signalName, parameter, sendConversion(val));
      setLastSentValue(val);
      setInputValue(val);
    }
  };

  const sendButtonReleaseValue = () => {
    if (lastSentValue !== inputValue) {
      setLoadingInput(true);
      postHydraulicParam(
        signalName,
        parameter,
        sendConversion(inputValue),
      );
      setLastSentValue(parseFloat(inputValue));
    } else {
      setButtonPressed(false);
    }
  };

  const isNumber = (n) => /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  const renderSliderValueByState = useMemo(() => {
    if (sliderChanging) {
      return sliderValue;
    }
    return displayValue;
  }, [displayValue, sliderValue, sliderChanging]);
  const submitInputValue = () => {
    setEditingValue(false);
    let val = inputValue;
    if (isNumber(val)) {
      if (val > max) {
        val = max;
        message.warning(`That value is too high, try a number between ${min} & ${max}`);
        setInputValue(displayValue);
      } else if (val < min) {
        val = min;
        message.warning(`That value is too low, try a number between ${min} & ${max}`);
        setInputValue(displayValue);
      } else {
        postHydraulicParam(
          signalName,
          parameter,
          sendConversion(val),
        );
        setLastSentValue(val);
      }
    } else {
      message.warning(`Please submit a number between ${min} and ${max}, what you have submitted is not a number`);
      setInputValue(displayValue);
    }
  };

  if (!hasPermissions) {
    return (
      <Row className="hydraulic-parameter">
        <Col span={24}>
          <Text type="secondary">{name}</Text>
          <div
            className="hydraulic-parameter-input"
            style={{
              marginTop: 28,
              marginBottom: 27,
            }}
          >
            <Text
              className="parameter-value-text"
              onClick={setEditingValue}
            >
              {valueIsFault ? <Text type="secondary">FAULT</Text> : `${parseFloat(inputValue).toFixed(float)} `}
              {!valueIsFault && (
              <Text type="secondary">
                {loadingInput
                  ? <LoadingOutlined style={{ marginLeft: 9, marginRight: 9 }} />
                  : unit }
              </Text>
              )}
            </Text>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="hydraulic-parameter">
      <Col span={24}>
        <Text type="secondary">{name}</Text>
        <Slider
          value={valueIsFault ? 0 : renderSliderValueByState.toFixed(float)}
          disabled={!hasPermissions}
          min={min}
          max={max}
          step={step}
          onChange={onSliderChange}
          onAfterChange={onAfterSliderChange}
          tipFormatter={(e) => `${e.toFixed(float)} ${unit}`}
        />
      </Col>
      <Col span={24}>
        <div className="hydraulic-parameter-input">
          <ParameterButton
            onClick={() => {
              setButtonPressed(true);
              const belowMin = inputValue <= min;
              if (!belowMin) {
                const val = (parseFloat(inputValue) - parseFloat(step)).toFixed(float);
                setInputValue(val);
              } else {
                setInputValue(min);
              }
            }}
            step={step}
            float={float}
            speed={speed}
            disabled={(displayValue <= min) || !hasPermissions}
            onRelease={() => sendButtonReleaseValue()}
            type="down"
          />
          {editingValue ? (
            <Tooltip title="Press enter to submit value" visible placement="bottom">
              <Input
                min={min}
                max={max}
                value={inputValue}
                disabled={!hasPermissions}
                onPressEnter={submitInputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                step={step}
                onBlur={() => {
                  if (lastSentValue !== inputValue) {
                    setInputValue(displayValue);
                  }
                  setEditingValue(false);
                }}
                autoFocus
              />
            </Tooltip>
          ) : (
            <Text
              className="parameter-value-text"
              onClick={setEditingValue}
            >
              {valueIsFault ? <Text type="secondary">FAULT</Text> : `${parseFloat(inputValue).toFixed(float)} `}
              {!valueIsFault && (
              <Text type="secondary">
                {loadingInput
                  ? <LoadingOutlined style={{ marginLeft: 9, marginRight: 9 }} />
                  : unit }
              </Text>
              )}
            </Text>
          )}
          <ParameterButton
            onClick={() => {
              setButtonPressed(true);
              const aboveMax = inputValue >= max;
              if (!aboveMax) {
                const val = (parseFloat(inputValue) + parseFloat(step)).toFixed(float);
                setInputValue(val);
              } else {
                setInputValue(max);
              }
            }}
            speed={speed}
            disabled={(displayValue >= max) || !hasPermissions}
            onRelease={() => sendButtonReleaseValue()}
            type="up"
          />
        </div>
      </Col>
    </Row>
  );
};

Parameter.propTypes = {
  name: PropTypes.string.isRequired,
  signalName: PropTypes.string.isRequired,
  parameter: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  unit: PropTypes.string,
  step: PropTypes.number,
  displayConversion: PropTypes.func,
  sendConversion: PropTypes.func,
  float: PropTypes.number,
  speed: PropTypes.number,
};

export default Parameter;
