import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import TouchButton from '../../../widgets/hydraulics/TouchButton';
import {
  MOVE, FORCE_STOP, DUMP,
} from '../../../../actions/hydraulicActions';
import Sensor from '../../../widgets/hydraulics/Sensor';
import Parameter from '../../../widgets/hydraulics/Parameter';
import ZeroFiller from '../../../widgets/hydraulics/ZeroFiller';
import DaggerboardButton from '../../../widgets/hydraulics/DaggerboardButton';
import {
  divide,
  multiply,
  lengthValueToDisplay,
  displayToLengthValue,
  displayToSixteenBitSigned,
  sixteenBitSignedToDisplay,
} from '../../../widgets/hydraulics/parameterConversions';
import CommitParameters from '../../../widgets/hydraulics/CommitParameters';

const { Panel } = Collapse;

const DaggerboardRam = ({ daggerboard, rake }) => {
  const targetRakePositionMinimum = useSelector((state) => state.Hydraulics[rake].parameter_1);
  const targetRakePositionMaximum = useSelector((state) => state.Hydraulics[rake].parameter_4);
  return (
    <div>
      <div className="hydraulic-actions">
        <Row>
          <Col xs={24} sm={8}>
            <DaggerboardButton name="Go" action={MOVE} daggerboard={daggerboard} rake={rake} />
          </Col>
          <Col xs={24} sm={8}>
            <DaggerboardButton name="Stop" action={FORCE_STOP} daggerboard={daggerboard} rake={rake} />
          </Col>
          <Col xs={24} sm={8}>
            <TouchButton name="Float" action={DUMP} signalName={daggerboard} />
          </Col>
          <Col sm={12} xs={24}>
            <Parameter
              name="Target Vertical Position"
              signalName={daggerboard}
              min={0}
              max={100}
              unit="%"
              parameter={4}
            />
          </Col>
          <Col sm={12} xs={24}>
            <Sensor
              label="Current Vertical Position"
              actuator={daggerboard}
              id={3}
              format="%"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={24}>
            <Parameter
              name="Target Rake Angle"
              signalName={rake}
              step={0.1}
              float={1}
              min={sixteenBitSignedToDisplay(targetRakePositionMinimum)}
              max={sixteenBitSignedToDisplay(targetRakePositionMaximum)}
              sendConversion={displayToSixteenBitSigned}
              displayConversion={sixteenBitSignedToDisplay}
              unit="&deg;"
              parameter={9}
            />
          </Col>
          <Col sm={12} xs={24}>
            <Sensor
              label="Current Rake Angle"
              actuator={rake}
              float={1}
              displayConversion={sixteenBitSignedToDisplay}
              id={0}
              format="&deg;"
            />
          </Col>
        </Row>
      </div>
      <Collapse bordered={false} defaultActiveKey={['1', '2']}>
        <Panel header="Vertical Sensors" key="1">
          <Row justify="space-around">
            <Col sm={8} xs={24}>
              <Sensor
                label="Uphaul Pressure"
                actuator={daggerboard}
                id={0}
                format="BAR"
              />
            </Col>
            <Col sm={8} xs={24}>
              <Sensor
                label="Downhaul Pressure"
                actuator={daggerboard}
                id={1}
                format="BAR"
              />
            </Col>
            <Col sm={8} xs={24}>
              <Sensor
                label="Position Data"
                actuator={daggerboard}
                id={2}
                displayConversion={lengthValueToDisplay}
                format=""
              />
            </Col>
          </Row>
        </Panel>
        <Panel header="Rake Sensors" key="2">
          <Row justify="space-around">
            <Col sm={12} xs={24}>
              <Sensor
                label="Speed"
                actuator={rake}
                displayConversion={(e) => divide(e, 10)}
                id={1}
                format="mm / second"
              />
            </Col>
            <Col sm={12} xs={24}>
              <Sensor
                label="Current"
                actuator={rake}
                id={2}
                format="amps"
              />
            </Col>
          </Row>
        </Panel>
        <Panel header="Vertical Parameters" key="3">
          <CommitParameters actuator={daggerboard} />
          <Row justify="space-around">
            <Col sm={12} xs={24}>
              <Parameter
                name="Up Pressure Limit"
                signalName={daggerboard}
                min={0}
                max={400}
                unit="BAR"
                parameter={0}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Down Pressure Limit"
                signalName={daggerboard}
                min={0}
                max={400}
                unit="BAR"
                parameter={1}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Fully Up Position"
                signalName={daggerboard}
                min={0}
                max={4095}
                sendConversion={displayToLengthValue}
                displayConversion={lengthValueToDisplay}
                parameter={2}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Fully Down Position"
                signalName={daggerboard}
                min={0}
                max={4095}
                sendConversion={displayToLengthValue}
                displayConversion={lengthValueToDisplay}
                parameter={3}
              />
            </Col>
          </Row>
        </Panel>
        <Panel header="Rake Parameters" key="4">
          <CommitParameters actuator={rake} />
          <Row justify="space-around">
            <Col sm={12} xs={24}>
              <Parameter
                name="Retracted Position"
                signalName={rake}
                min={0}
                max={999}
                unit="mm"
                parameter={0}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Retracted Angle"
                signalName={rake}
                step={0.1}
                float={1}
                min={-10}
                max={10}
                unit="&deg;"
                sendConversion={displayToSixteenBitSigned}
                displayConversion={sixteenBitSignedToDisplay}
                parameter={1}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Zero Position"
                signalName={rake}
                min={0}
                max={999}
                unit="mm"
                parameter={2}
              />
            </Col>
            <Col sm={12} xs={24}>
              <ZeroFiller
                label="Zero Angle"
                unit="&deg;"
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Extended Position"
                signalName={rake}
                min={0}
                max={999}
                unit="mm"
                parameter={3}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Extended Angle"
                signalName={rake}
                step={0.1}
                float={1}
                min={-10}
                max={10}
                unit="&deg;"
                sendConversion={displayToSixteenBitSigned}
                displayConversion={sixteenBitSignedToDisplay}
                parameter={4}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Current Limit"
                signalName={rake}
                min={1}
                max={99}
                unit="amps"
                parameter={5}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Speed Limit"
                signalName={rake}
                min={1}
                max={100}
                unit="%"
                parameter={6}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Start Ramp"
                signalName={rake}
                step={0.1}
                float={1}
                min={0.1}
                max={5}
                unit="s"
                sendConversion={(value) => multiply(value, 10)}
                displayConversion={(value) => divide(value, 10)}
                parameter={7}
              />
            </Col>
            <Col sm={12} xs={24}>
              <Parameter
                name="Stop Ramp"
                signalName={rake}
                step={0.1}
                float={1}
                min={0.1}
                max={5}
                unit="s"
                sendConversion={(value) => multiply(value, 10)}
                displayConversion={(value) => divide(value, 10)}
                parameter={8}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};

DaggerboardRam.propTypes = {
  daggerboard: PropTypes.string.isRequired,
  rake: PropTypes.string.isRequired,
};


export default DaggerboardRam;
