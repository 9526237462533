import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

const { Text, Paragraph } = Typography;

const LabelValue = ({ value = '', suffix = '', title }) => (
  <div className="label-value-pair">
    <Text
      className="label-value-pair-title"
      type="secondary"
    >
      {title}
    </Text>
    <Paragraph
      className="label-value-pair-value"
    >
      {value}
      <Text type="secondary" className="label-value-pair-format">{suffix}</Text>
    </Paragraph>
  </div>
);
LabelValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  suffix: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default LabelValue;
