import Axios from 'axios';
import ENDPOINT from './addresses';

export const getAlarms = () => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/alarms`)
    .then((response) => resolve(response.data))
    .catch((error) => console.log(error));
});

export const unAcknowledgeAll = () => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/alarm/all/unacknowledge`)
    .then((response) => resolve(response.data))
    .catch((error) => console.log(error));
});

// ALARMS

export const updateAlarm = (name, update) => new Promise((resolve) => {
  Axios.put(`${ENDPOINT}/api/v1/alarm/${name}`, update)
    .then((response) => resolve(response.data))
    .catch((error) => console.log(error));
});
