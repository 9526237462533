import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Row, Col } from 'antd';
import TouchButton from '../../../widgets/hydraulics/TouchButton';
import {
  CLOCKWISE, COUNTER_CLOCKWISE, DUMP, TACK,
} from '../../../../actions/hydraulicActions';
import Sensor from '../../../widgets/hydraulics/Sensor';
import Parameter from '../../../widgets/hydraulics/Parameter';
import {
  displayToSixteenBitSigned, sixteenBitSignedToDisplay,
} from '../../../widgets/hydraulics/parameterConversions';
import ZeroFiller from '../../../widgets/hydraulics/ZeroFiller';
import CommitParameters from '../../../widgets/hydraulics/CommitParameters';

const { Panel } = Collapse;

const RotatorRam = ({ actuator }) => (
  <div>
    <div className="hydraulic-actions">
      <Row>
        <Col xs={12} sm={6}>
          <TouchButton name="CCW" action={COUNTER_CLOCKWISE} signalName={actuator} />
        </Col>
        <Col xs={12} sm={6}>
          <TouchButton name="CW" action={CLOCKWISE} signalName={actuator} />
        </Col>
        <Col xs={12} sm={6}>
          <TouchButton name="Tack" action={TACK} signalName={actuator} />
        </Col>
        <Col xs={12} sm={6}>
          <TouchButton name="Float" action={DUMP} signalName={actuator} />
        </Col>
        <Col span={24}>
          <Sensor
            label="Angle"
            displayConversion={sixteenBitSignedToDisplay}
            actuator={actuator}
            id={3}
            float={1}
            format="&deg;"
            rotatorPositionAngle
          />
        </Col>
      </Row>
    </div>
    <Collapse bordered={false} defaultActiveKey={['1']}>
      <Panel header="Sensors" key="1">
        <Row justify="space-around">
          <Col sm={8} xs={24}>
            <Sensor
              label="CCW Pressure"
              actuator={actuator}
              id={1}
              format="BAR"
            />
          </Col>
          <Col sm={8} xs={24}>
            <Sensor
              label="CW Pressure"
              actuator={actuator}
              id={0}
              format="BAR"
            />
          </Col>
          <Col sm={8} xs={24}>
            <Sensor
              label="Position Sensor"
              actuator={actuator}
              id={2}
              format=""
            />
          </Col>
        </Row>
      </Panel>
      <Panel header="Parameters" key="2">
        <CommitParameters actuator={actuator} />
        <Row justify="space-around">
          <Col sm={12} xs={24}>
            <Parameter
              name="Max Pressure CCW"
              signalName={actuator}
              min={0}
              max={400}
              unit="BAR"
              parameter={0}
            />
          </Col>
          <Col sm={12} xs={24}>
            <Parameter
              name="Max Pressure CW"
              signalName={actuator}
              min={0}
              max={400}
              unit="BAR"
              parameter={1}
            />
          </Col>
          <Col sm={12} xs={24}>
            <Parameter
              name="CCW Calibration Sensor"
              signalName={actuator}
              min={0}
              max={5000}
              parameter={5}
            />
          </Col>
          <Col sm={12} xs={24}>
            <Parameter
              name="CCW Calibration Angle"
              signalName={actuator}
              min={-90}
              max={90}
              unit="&deg;"
              float={1}
              step={0.1}
              sendConversion={displayToSixteenBitSigned}
              displayConversion={sixteenBitSignedToDisplay}
              parameter={4}
            />
          </Col>
          <Col sm={12} xs={24}>
            <Parameter
              name="0&deg; Calibration Sensor"
              signalName={actuator}
              min={0}
              max={5000}
              parameter={6}
            />
          </Col>
          <Col sm={12} xs={24}>
            <ZeroFiller label="Zero Position Angle" />
          </Col>
          <Col sm={12} xs={24}>
            <Parameter
              name="CW Calibration Sensor"
              signalName={actuator}
              min={0}
              max={5000}
              parameter={7}
            />
          </Col>
          <Col sm={12} xs={24}>
            <Parameter
              name="CW Calibration Angle"
              signalName={actuator}
              min={-90}
              max={90}
              unit="&deg;"
              float={1}
              step={0.1}
              sendConversion={displayToSixteenBitSigned}
              displayConversion={sixteenBitSignedToDisplay}
              parameter={8}
            />
          </Col>
          <Col sm={12} xs={24}>
            <Parameter
              name="CCW Limit Sensor"
              signalName={actuator}
              min={0}
              max={5000}
              parameter={2}
            />
          </Col>
          <Col sm={12} xs={24}>
            <Parameter
              name="CW Limit Sensor"
              signalName={actuator}
              min={0}
              max={5000}
              parameter={3}
            />
          </Col>
        </Row>
      </Panel>
    </Collapse>
  </div>
);

RotatorRam.propTypes = {
  actuator: PropTypes.string.isRequired,
};


export default RotatorRam;
