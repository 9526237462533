import React, { useMemo } from 'react';
import {
  Table, Typography, Row, Col,
} from 'antd';
import { useSelector } from 'react-redux';
import HydraulicsDisconnectedSpinner from '../../widgets/hydraulics/HydraulicsDisconnectedSpinner';

const { Text } = Typography;

const Status = () => {
  const messages = useSelector((state) => state.ServerState.statusMessages);
  const hydraulicsConnected = useSelector((state) => state.ServerState.hydraulics);
  const statusArray = useMemo(() => Object.keys(messages).map((key) => messages[key]), [messages]);
  const sortedArray = statusArray.sort((a, b) => parseInt(a.source, 16) - parseInt(b.source, 16));

  const columns = [{
    title: 'Name',
    dataIndex: ['actuator', 'name'],
    key: 'id',
    render: (name) => (name ? name.replace(/_/g, ' ') : ''),
    width: 200,
  }, {
    title: 'Node',
    dataIndex: 'source',
    key: 'source',
    width: 50,
  }, {
    title: 'Status',
    dataIndex: 'warnings_bits',
    key: 'warnings_bits',
    width: 110,
  }, {
    title: 'Tx Errors',
    dataIndex: 'tx_error_count',
    key: 'tx_error_count',
    width: 110,
  }, {
    title: 'Tx Messages',
    dataIndex: 'tx_messages_counted',
    key: 'tx_messages_counted',
    width: 110,
  }, {
    title: 'Rx Errors',
    dataIndex: 'rx_error_count',
    key: 'rx_error_count',
    width: 110,
  }, {
    title: 'Rx Messages',
    dataIndex: 'rx_message_count',
    key: 'rx_message_count',
    width: 110,
  }];

  if (!hydraulicsConnected) {
    return <HydraulicsDisconnectedSpinner />;
  }
  return (
    <div className="status-table">
      <Table
        dataSource={sortedArray}
        tableLayout="fixed"
        columns={columns}
        ellipsis
        size="small"
        rowKey={(e) => e.source}
        pagination={false}
        scroll={{ x: '700px' }}
        bordered
        expandable={{
          expandedRowRender: (item) => (
            <Row>
              <Col span={12}>
                <Text>{`Software Version: ${item.versionMajor}.${item.versionMinor}`}</Text>
              </Col>
              <Col span={12}>
                <Text>{`Actuator ID: ${item.actuator.actuatorId}`}</Text>
              </Col>
            </Row>
          ),
          rowExpandable: (record) => !!record.actuator,
        }}
      />
    </div>
  );
};

export default Status;
