import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Slider, Typography } from 'antd';
import { updateAlarm } from '../../../../api/alarms';
import LabelValue from '../../shared/LabelValue';

const { Paragraph } = Typography;

const Threshold = ({
  name,
  threshold,
  maximum,
  minimum,
  format,
  convertFunction = null,
  step,
  hasPermissions,
}) => {
  const [value, setValue] = useState(threshold);
  const formatter = () => {
    if (value === -999) {
      return 'N/A';
    }
    if (maximum === 1) {
      return `${(value * 100).toFixed(0)}%`;
    }
    if (format) {
      return `${value}${format}`;
    }
    return value;
  };
  const updateValueFromThreshold = useCallback(() => setValue(threshold), [threshold]);
  useEffect(() => {
    updateValueFromThreshold();
  }, [threshold, updateValueFromThreshold]);
  const sendThreshold = () => {
    if (convertFunction) {
      updateAlarm(name, { threshold: convertFunction(value) });
    } else {
      updateAlarm(name, { threshold: value });
    }
  };
  return (
    <div className="alarm-threshold">
      {hasPermissions ? (
        <div>
          <Paragraph type="secondary">Threshold</Paragraph>
          <Slider
            tipFormatter={formatter}
            defaultValue={threshold}
            value={value}
            onChange={setValue}
            onAfterChange={sendThreshold}
            max={maximum}
            min={minimum}
            step={step}
            disabled={!hasPermissions}
          />
        </div>
      ) : (
        <LabelValue title="Threshold" value={formatter(threshold)} />
      )}

    </div>
  );
};

Threshold.propTypes = {
  threshold: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  maximum: PropTypes.number.isRequired,
  minimum: PropTypes.number.isRequired,
  format: PropTypes.string.isRequired,
  convertFunction: PropTypes.func,
  step: PropTypes.number.isRequired,
  hasPermissions: PropTypes.bool.isRequired,
};

export default Threshold;
