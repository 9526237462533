import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Typography } from 'antd';
import { postCircuitStateUpdate } from '../../../../api/dc';
import CornerIcon from '../../shared/CornerIcon';
import CircuitInfoContainer from './CircuitInfoContainer';


const { Paragraph } = Typography;

const PresetCircuit = ({
  circuitName, disabled, title,
}) => {
  const [loading, setLoading] = useState(false);
  const onOff = useSelector((state) => state.Circuits.loads[circuitName].state);
  useEffect(() => {
    if (loading === onOff) {
      setLoading(false);
    }
  }, [onOff, loading]);
  const sendUpdate = (e) => {
    setLoading(e ? 1 : 0);
    postCircuitStateUpdate({ name: circuitName, state: e ? 1 : 0 });
  };
  return (
    <div className="circuit-container">
      <span>
        <CornerIcon
          width={330}
          active={!!onOff}
          tooltipTitle={<CircuitInfoContainer name={circuitName} />}
          icon="poweroff"
          side="left"
          trigger="hover"
        />
      </span>
      <Paragraph style={{ fontSize: 16 }}>
        {title}
      </Paragraph>
      <Switch
        className="binary-circuit-switch"
        checked={onOff === 1}
        loading={loading !== false}
        onChange={(e) => sendUpdate(e)}
        disabled={disabled}
      />
    </div>
  );
};

PresetCircuit.propTypes = {
  circuitName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default PresetCircuit;
