import React from 'react';
import { Spin, Row } from 'antd';

const HydraulicsDisconnectedSpinner = () => (
  <Row justify="center" align="middle" style={{ height: '75vh' }}>
    <Spin size="large" tip="Hydraulics System Connecting" />
  </Row>
);

export default HydraulicsDisconnectedSpinner;
