import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Radio, Typography,
} from 'antd';
// import { Link } from 'react-router-dom';
import { postCircuitStateUpdate } from '../../../../api/dc';
import { Inverter_Output_Detect, Group_1_Shore_Detect, Group_2_Shore_Detect } from '../../../../api/circuits';
import CircuitLabelValuePair from '../../shared/CircuitLabelValuePair';
import RenderCircuitByType from '../DCM/RenderCircuitByType';
import CornerIcon from '../../shared/CornerIcon';
import usePermissions from '../../../../customHooks/usePermissions';

const { Paragraph, Text } = Typography;

const ACCircuitWidget = ({
  shore, inverter, circuit, name, showDetails = true,
}) => {
  const shoreStatus = useSelector((state) => state.Circuits.loads[shore]);
  const inverterStatus = useSelector((state) => state.Circuits.loads[inverter]);
  const circuitStatus = useSelector((state) => state.Circuits.loads[circuit]);
  const tripped = useSelector((state) => state.Circuits.fuses[circuit].tripped);
  const InverterPresent = useSelector((state) => state.Circuits.loads[Inverter_Output_Detect]);
  const Shore1Present = useSelector((state) => state.Circuits.loads[Group_1_Shore_Detect]);
  const Shore2Present = useSelector((state) => state.Circuits.loads[Group_2_Shore_Detect]);
  const hasPermissions = usePermissions('AC_Power');
  const selectDefaultValue = useMemo(() => {
    if (shoreStatus.actualState) {
      return 'Shore';
    }
    if (inverterStatus.actualState) {
      return 'On';
    }
    return 'Off';
  }, [shoreStatus, inverterStatus]);
  const [buttonState, setButtonState] = useState(selectDefaultValue);
  useEffect(() => {
    if (shoreStatus.actualState) {
      return setButtonState('Shore');
    }
    if (inverterStatus.actualState) {
      return setButtonState('On');
    }
    return setButtonState('Off');
  }, [shoreStatus, inverterStatus]);

  const handleChange = (e) => {
    if (e.target.value === 'Shore') {
      postCircuitStateUpdate({ ...shoreStatus, state: 1 }).then((res) => res.acknowledged && setButtonState('Shore'));
      postCircuitStateUpdate({ ...inverterStatus, state: 0 });
    }
    if (e.target.value === 'On') {
      postCircuitStateUpdate({ ...inverterStatus, state: 1 }).then((res) => res.acknowledged && setButtonState('On'));
      postCircuitStateUpdate({ ...shoreStatus, state: 0 });
    } if (e.target.value === 'Off') {
      postCircuitStateUpdate({ ...inverterStatus, state: 0 });
      postCircuitStateUpdate({ ...shoreStatus, state: 0 }).then((res) => res.acknowledged && setButtonState('Off'));
    }
  };
  if (tripped) {
    return <RenderCircuitByType circuitName={circuitStatus.name} title={name} />;
  }
  return (
    <div className="ac-circuit">
      <CornerIcon
        icon="poweroff"
        side="left"
        width={100}
        active={!!circuitStatus.actualState}
        tooltipTitle={(
          <CircuitLabelValuePair
            className="ac-label-value-pair"
            label="Status"
            value={circuitStatus.actualState
              ? <Text style={{ float: 'right', color: '#6f92a2' }}>On</Text>
              : <Text style={{ float: 'right' }} type="secondary">Off</Text>}
          />
      )}
      />
      {showDetails && (
        <CornerIcon
          icon="api"
          side="right"
          width={125}
          active={
            [InverterPresent, Shore1Present, Shore2Present].some((element) => !!element.actualState)
          }
          tooltipTitle={(
            <div>
              <CircuitLabelValuePair
                label="Inverter"
                className="ac-label-value-pair"
                leftSpan={14}
                rightSpan={10}
                value={InverterPresent.actualState
                  ? <Text className="status-yes">Yes</Text>
                  : <Text type="secondary">No</Text>}
              />
              <CircuitLabelValuePair
                label="Shore 1"
                className="ac-label-value-pair"
                leftSpan={14}
                rightSpan={10}
                value={Shore1Present.actualState
                  ? <Text className="status-yes">Yes</Text>
                  : <Text type="secondary">No</Text>}
              />
              <CircuitLabelValuePair
                label="Shore 2"
                className="ac-label-value-pair"
                leftSpan={14}
                rightSpan={10}
                value={Shore2Present.actualState
                  ? <Text className="status-yes">Yes</Text>
                  : <Text type="secondary">No</Text>}
              />
            </div>
        )}
        />
      )}
      <Paragraph className="title">{name}</Paragraph>
      <Radio.Group
        className="buttons"
        onChange={handleChange}
        defaultValue={selectDefaultValue}
        value={buttonState}
        buttonStyle="solid"
        disabled={!hasPermissions}
      >
        <Radio.Button style={{ width: 68 }} value="Off">Off</Radio.Button>
        <Radio.Button style={{ width: 68 }} value="Shore">Shore</Radio.Button>
        <Radio.Button style={{ width: 68 }} value="On">On</Radio.Button>
      </Radio.Group>
    </div>
  );
};

ACCircuitWidget.propTypes = {
  shore: PropTypes.string.isRequired,
  inverter: PropTypes.string.isRequired,
  circuit: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showDetails: PropTypes.bool,
};

export default ACCircuitWidget;
