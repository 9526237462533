import React from 'react';
import {
  Row, Col, Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import LabelValue from '../widgets/shared/LabelValue';

const { Paragraph } = Typography;

const ChargeSources = () => {
  const Solar = useSelector((state) => state.NMEA.Solar);
  const AlternatorPort = useSelector((state) => state.NMEA.Alternator_Port);
  const AlternatorStbd = useSelector((state) => state.NMEA.Alternator_Stbd);
  return (
    <Row justify="space-around">
      <Col xs={24} md={8} className="ac-source-section">
        <div className={Solar ? 'ac-source-values connected' : 'ac-source-values'}>
          <Paragraph className="ac-title">Solar</Paragraph>
          <Row justify="space-around">
            <Col span={8}>
              <LabelValue
                title="Voltage"
                value={Solar.voltage.value}
                suffix="V"
              />
            </Col>
            <Col span={8}>
              <LabelValue
                title="Current"
                value={Solar.current.value}
                suffix="A"
              />
            </Col>
            <Col span={8}>
              <LabelValue
                title="Temperature"
                value={Solar.temperature.value ? (Solar.temperature.value - 273).toFixed(0) : 0}
                suffix="&deg;"
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={24} md={8} className="ac-source-section">
        <div className={AlternatorPort ? 'ac-source-values connected' : 'ac-source-values'}>
          <Paragraph className="ac-title">Alternator Port</Paragraph>
          <Row justify="space-around">
            <Col span={8}>
              <LabelValue
                title="Voltage"
                value={AlternatorPort.voltage.value}
                suffix="V"
              />
            </Col>
            <Col span={8}>
              <LabelValue
                title="Current"
                value={AlternatorPort.current.value}
                suffix="A"
              />
            </Col>
            <Col span={8}>
              <LabelValue
                title="Temperature"
                value={AlternatorPort.temperature.value ? (AlternatorPort.temperature.value - 273).toFixed(0) : 0}
                suffix="&deg;"
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={24} md={8} className="ac-source-section">
        <div className={AlternatorStbd ? 'ac-source-values connected' : 'ac-source-values'}>
          <Paragraph className="ac-title">Alternator Stbd</Paragraph>
          <Row justify="space-around">
            <Col span={8}>
              <LabelValue
                title="Voltage"
                value={AlternatorStbd.voltage.value}
                suffix="V"
              />
            </Col>
            <Col span={8}>
              <LabelValue
                title="Current"
                value={AlternatorStbd.current.value}
                suffix="A"
              />
            </Col>
            <Col span={8}>
              <LabelValue
                title="Temperature"
                value={AlternatorStbd.temperature.value ? (AlternatorStbd.temperature.value - 273).toFixed(0) : 0}
                suffix="&deg;"
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default ChargeSources;
