import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AlarmWidget from '../alarms/AlarmWidget';

const TankAlarm = ({ tankName, type }) => {
  const alarm = useSelector((state) => state.Alarms[`${tankName}_${type}`]);
  return (
    <AlarmWidget alarmName={alarm.name} span={12} key={alarm.id} label={alarm.name.replace(/_/g, ' ')} step={0.01} />
  );
};

TankAlarm.propTypes = {
  tankName: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['Low', 'High']),
};

export default TankAlarm;
