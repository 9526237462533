import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  Progress, Col, Typography, Row,
} from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CornerIcon from '../shared/CornerIcon';

const { Paragraph, Text } = Typography;


const DisconnectedTank = ({
  name, color, width,
}) => (
  <div className="tank-widget">
    <Paragraph className="tank-widget-title">
      {name}
    </Paragraph>
    <Progress
      type="dashboard"
      percent={0}
      format={() => '--'}
      gapDegree={140}
      gapPosition="top"
      strokeColor={color}
      strokeWidth={8}
      width={width}
    />
    <Row>
      <Col span={12}>
        <Paragraph className="tank-widget-capacity">
          <Text style={{ fontSize: 18 }}>--</Text>
          <Text type="secondary" style={{ paddingLeft: 4 }}>L capacity</Text>
        </Paragraph>
      </Col>
      <Col span={12}>
        <div className="tank-widget-progress-bar-capacity-remaining">
          <Text>-- remaining</Text>
        </div>
      </Col>
    </Row>
  </div>
);

DisconnectedTank.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

const ConnectedTank = ({
  tankName, name, width, color,
}) => {
  const currentLevel = useSelector((state) => state.NMEA[tankName].currentLevel.value);
  const capacity = useSelector((state) => state.NMEA[tankName].capacity.value);
  const levelInLitres = useMemo(() => (
    ((currentLevel * 100) * capacity) / 100).toFixed(0),
  [currentLevel, capacity]);

  const renderPercent = useMemo(() => {
    if (currentLevel === 1.31068) {
      return '--';
    }
    return (
      <Paragraph style={{ fontSize: 24 }}>
        {(currentLevel * 100).toFixed(0)}
        <Text type="secondary" style={{ fontSize: 18 }}>
          %
        </Text>
      </Paragraph>
    );
  }, [currentLevel]);
  return (
    <div className="tank-widget">
      <Paragraph className="tank-widget-title">
        {name}
      </Paragraph>
      <Progress
        type="dashboard"
        percent={currentLevel === 1.31068 ? 0 : currentLevel * 100}
        format={() => renderPercent}
        gapDegree={140}
        strokeColor={color}
        strokeWidth={8}
        width={width}
      />
      <Row>
        <Col span={12}>
          <Paragraph className="tank-widget-capacity">
            <Text style={{ fontSize: 18 }}>{capacity.toFixed(0)}</Text>
            <Text type="secondary" style={{ paddingLeft: 4 }}>L capacity</Text>
          </Paragraph>
        </Col>
        <Col span={12}>
          <div className="tank-widget-progress-bar-capacity-remaining">
            {currentLevel === 1.31068
              ? <Text>--</Text>
              : (
                <Text>
                  <Text style={{ fontSize: 18 }}>{levelInLitres}</Text>
                  <Text type="secondary" style={{ paddingLeft: 4 }}>
                    {tankName.includes('Waste') ? 'L used' : 'L remaining'}
                  </Text>
                </Text>
              )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

ConnectedTank.propTypes = {
  name: PropTypes.string.isRequired,
  tankName: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
const Tank = ({
  tankName,
  showDetails = true,
  width = 160,
}) => {
  const connected = useSelector((state) => state.ServerState.nmeaStatus[tankName]);
  const name = tankName.replace(/_/g, ' ');
  const color = useMemo(() => {
    if (tankName.includes('Waste')) {
      return 'gray';
    }
    if (tankName.includes('Fuel')) {
      return '#cab995';
    }
    return '#6f92a2';
  }, [tankName]);
  return (
    <div>
      {showDetails && (
      <span>
        <Link to={`/tanks/${tankName}`}>
          <CornerIcon
            width={90}
            active={!!connected.transmitting}
            tooltipTitle={<Text>View Details</Text>}
            side="left"
            trigger="hover"
          />
        </Link>
      </span>
      )}
      {connected.transmitting
        ? <ConnectedTank color={color} name={name} tankName={tankName} width={width} />
        : <DisconnectedTank name={name} color={color} width={width} />}
    </div>
  );
};

Tank.propTypes = {
  tankName: PropTypes.string.isRequired,
  showDetails: PropTypes.bool,
  width: PropTypes.number,
};

export default Tank;
