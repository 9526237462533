import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'antd';

const Daggerboards = () => (
  <div style={{ padding: 16 }}>
    <Row gutter={16} justify="space-around">
      <Col sm={12} xs={24}>
        <Link to="/actuators/Daggerboard_Port">
          <Button type="primary" ghost className="hydraulic-menu-item" size="large">
            Daggerboard Port
          </Button>
        </Link>
      </Col>
      <Col sm={12} xs={24}>
        <Link to="/actuators/Daggerboard_Stbd">
          <Button type="primary" ghost className="hydraulic-menu-item" size="large">
            Daggerboard Stbd
          </Button>
        </Link>
      </Col>
    </Row>
  </div>
);

export default Daggerboards;
