import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { useSelector } from 'react-redux';
import { postHydraulicAction } from '../../../api/hydraulics';
import { STOP } from '../../../actions/hydraulicActions';
import usePermissions from '../../../customHooks/usePermissions';

const DaggerboardButton = ({
  name, daggerboard, rake, action,
}) => {
  const [mouseDown, setMouseDown] = useState(false);
  const [active, setActive] = useState(false);
  const verticalActuation = useSelector((state) => state.Hydraulics[daggerboard].actuation);
  const rakeActuation = useSelector((state) => state.Hydraulics[rake].actuation);
  const hasPermissions = usePermissions('Hydraulics');
  const disabled = !hasPermissions;

  useEffect(() => {
    if ((verticalActuation === action) || (rakeActuation === action)) {
      setActive(true);
    } else (setActive(false));
  }, [verticalActuation, rakeActuation, action]);

  const activeType = name === 'Stop' ? 'danger' : 'primary';

  const startAction = () => {
    setMouseDown(true);
    postHydraulicAction(daggerboard, action);
    postHydraulicAction(rake, action);
  };

  const stopAction = () => {
    setMouseDown(false);
    postHydraulicAction(daggerboard, STOP);
    postHydraulicAction(rake, STOP);
  };

  return (
    <div className="touch-button">
      <Button
        type={active ? activeType : 'default'}
        size="large"
        onMouseDown={!disabled ? startAction : () => message.info('You are not authorized to use this feature')}
        onTouchStart={!disabled ? startAction : undefined}
        onMouseUp={!disabled ? stopAction : undefined}
        onTouchEnd={(!disabled && mouseDown) ? stopAction : undefined}
        onMouseLeave={(!disabled && mouseDown) ? stopAction : undefined}
      >
        {name}
      </Button>
    </div>
  );
};

DaggerboardButton.propTypes = {
  name: PropTypes.string.isRequired,
  daggerboard: PropTypes.string.isRequired,
  rake: PropTypes.string.isRequired,
  action: PropTypes.number.isRequired,
};

export default DaggerboardButton;
