import React from 'react';
import {
  Row, Col, Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import LabelValue from '../widgets/shared/LabelValue';
import { Inverter_Output_Detect, Group_1_Shore_Detect, Group_2_Shore_Detect } from '../../api/circuits';

const { Paragraph } = Typography;

const ACSources = () => {
  const InverterPresent = useSelector((state) => state.Circuits.loads[Inverter_Output_Detect]);
  const ShoreAirConditioning = useSelector((state) => state.NMEA.Shore_Air_Con);
  const Shore1Present = useSelector((state) => state.Circuits.loads[Group_1_Shore_Detect]);
  const ShoreUtility = useSelector((state) => state.NMEA.Shore_Utility);
  const Shore2Present = useSelector((state) => state.Circuits.loads[Group_2_Shore_Detect]);
  const ACHouse = useSelector((state) => state.NMEA.AC_House);
  return (
    <Row justify="space-around">
      <Col xs={24} md={8} className="ac-source-section">
        <div className={Shore1Present.actualState ? 'ac-source-values connected' : 'ac-source-values'}>
          <Paragraph className="ac-title">Shore 1</Paragraph>
          {Shore1Present.actualState ? (
            <Row justify="space-around">
              <Col span={8}>
                <LabelValue
                  title="Voltage"
                  value={ShoreUtility.lineNeutralVoltage.value.toFixed(0)}
                  suffix="V"
                />
              </Col>
              <Col span={8}>
                <LabelValue
                  title="Frequency"
                  value={ShoreUtility.frequency.value.toFixed(1)}
                  suffix="Hz"
                />
              </Col>
              <Col span={8}>
                <LabelValue
                  title="Current"
                  value={ShoreUtility.current.value.toFixed(0)}
                  suffix="A"
                />
              </Col>
            </Row>
          ) : (
            <LabelValue title="Not Connected" />
          )}
        </div>
      </Col>
      <Col xs={24} md={8} className="ac-source-section">
        <div className={Shore2Present.actualState ? 'ac-source-values connected' : 'ac-source-values'}>
          <Paragraph className="ac-title">Shore 2</Paragraph>
          {Shore2Present.actualState ? (
            <Row justify="space-around">
              <Col span={8}>
                <LabelValue
                  title="Voltage"
                  value={ShoreAirConditioning.lineNeutralVoltage.value.toFixed(0)}
                  suffix="V"
                />
              </Col>
              <Col span={8}>
                <LabelValue
                  title="Frequency"
                  value={ShoreAirConditioning.frequency.value.toFixed(1)}
                  suffix="Hz"
                />
              </Col>
              <Col span={8}>
                <LabelValue
                  title="Current"
                  value={ShoreAirConditioning.current.value.toFixed(0)}
                  suffix="A"
                />
              </Col>
            </Row>
          ) : (
            <LabelValue title="Not Connected" />
          )}
        </div>
      </Col>
      <Col xs={24} md={8} className="ac-source-section">
        <div className={InverterPresent.actualState ? 'ac-source-values connected' : 'ac-source-values'}>
          <Paragraph className="ac-title">Inverter</Paragraph>
          {InverterPresent.actualState ? (
            <Row justify="space-around">
              <Col span={8}>
                <LabelValue
                  title="Voltage"
                  value={ACHouse.lineNeutralVoltage.value.toFixed(0)}
                  suffix="V"
                />
              </Col>
              <Col span={8}>
                <LabelValue
                  title="Frequency"
                  value={ACHouse.frequency.value.toFixed(1)}
                  suffix="Hz"
                />
              </Col>
              <Col span={8}>
                <LabelValue
                  title="Current"
                  value={ACHouse.current.value.toFixed(0)}
                  suffix="A"
                />
              </Col>
            </Row>
          ) : (
            <LabelValue title="Not Connected" />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ACSources;
