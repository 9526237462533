import { RECEIVE_PERSISTENT_DATA_ITEM, RECEIVE_PERSISTENT_DATA } from './types';

const receivePersistentData = (data) => (dispatch) => {
  data.map((item) => dispatch({ type: RECEIVE_PERSISTENT_DATA_ITEM, payload: item }));
  return {
    type: RECEIVE_PERSISTENT_DATA,
    data,
  };
};

export default receivePersistentData;
