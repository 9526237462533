import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import {
  COUNTER_CLOCKWISE, CLOCKWISE, PORT, STARBOARD,
} from '../../../../actions/hydraulicActions';
import TouchButton from '../../../widgets/hydraulics/TouchButton';

const Winch = ({ type, actuator }) => {
  const renderByType = useMemo(() => {
    switch (type) {
      case 'Brushed_DC_Motor': {
        return (
          <Row>
            <Col span={12}>
              <TouchButton signalName={actuator} name="1/3" action={CLOCKWISE} />
            </Col>
            <Col span={12}>
              <TouchButton signalName={actuator} name="2" action={COUNTER_CLOCKWISE} />
            </Col>
          </Row>
        );
      }
      case 'Line_Driver': {
        return (
          <Row>
            <Col span={12}>
              <TouchButton signalName={actuator} name="Port" action={PORT} />
            </Col>
            <Col span={12}>
              <TouchButton signalName={actuator} name="Stbd" action={STARBOARD} />
            </Col>
          </Row>
        );
      }
      default:
        return <div>Test</div>;
    }
  }, [type, actuator]);

  return (
    <div className="hydraulic-actions">
      {renderByType}
    </div>
  );
};

Winch.propTypes = {
  type: PropTypes.string.isRequired,
  actuator: PropTypes.string.isRequired,
};

export default Winch;
