/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  BrowserRouter as Router, Route,
} from 'react-router-dom';
import Layout from './Layout';
import './App.less';
import ScrollToTop from './components/shared/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Route path="/" render={(props) => <Layout {...props} />} />
    </Router>
  );
}

export default App;
