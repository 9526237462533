import React from 'react';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { commitHydraulicParams } from '../../../api/hydraulics';
import usePermissions from '../../../customHooks/usePermissions';

const CommitParameters = ({ actuator }) => {
  const hasPermissions = usePermissions('Hydraulics');
  return (
    <div className="commit-parameters-div">
      <Button
        type="primary"
        disabled={!hasPermissions}
        icon={<DownloadOutlined />}
        onClick={() => commitHydraulicParams(actuator).then((res) => res && message.success('Parameters Committed'))}
      >
      Commit
      </Button>
    </div>
  );
};

CommitParameters.propTypes = {
  actuator: PropTypes.string.isRequired,
};

export default CommitParameters;
