import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircuitInfoContent from './CircuitInfoContent';

const CircuitInfoContainer = ({ name }) => {
  const { pathname } = useLocation();
  const circuitName = name || pathname.replace('/circuits/', '');
  const slavesList = useSelector((state) => state.Circuits.loads[circuitName].meta.slaves);

  return (
    <div id="circuit-details-tooltip">
      <CircuitInfoContent circuitName={circuitName} />
      {slavesList && slavesList.map((slave) => <CircuitInfoContent key={slave} circuitName={slave.replace('loads.', '')} />)}
    </div>
  );
};

CircuitInfoContainer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CircuitInfoContainer;
