import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Progress,
  Col,
  Typography,
  Row,
} from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThunderboltFilled, PercentageOutlined } from '@ant-design/icons';
import CornerIcon from '../shared/CornerIcon';
import LabelValue from '../shared/LabelValue';

const { Paragraph, Text } = Typography;

const DisconnectedBattery = ({ name }) => (
  <div className="battery-widget">
    <Paragraph className="battery-widget-title">
      {name}
    </Paragraph>
    <Progress
      className="battery-widget-progress-bar"
      percent="N/A"
      gapDegree={0}
      strokeWidth={25}
      status={null}
      strokeColor="#6f92a2"
    />
    <Row>
      <Col span={12} style={{ textAlign: 'left', paddingLeft: 8 }}>
        <Text type="secondary"> N/A</Text>
      </Col>
      <Col span={12} style={{ textAlign: 'right', paddingRight: 8 }}>
        <Text type="secondary"> N/A</Text>
      </Col>
    </Row>
    <Row>
      <Col span={8}>
        <Paragraph type="secondary">Current</Paragraph>
        <Paragraph>
          <Text type="secondary" className="value-unit">N/A</Text>
        </Paragraph>
      </Col>
      <Col span={8}>
        <Paragraph type="secondary">
            Voltage
        </Paragraph>
        <Paragraph>
          <Text type="secondary" className="value-unit">N/A</Text>
        </Paragraph>
      </Col>
      <Col span={8}>
        <Paragraph type="secondary">Temperature</Paragraph>
        <Paragraph>
          <Text type="secondary" className="value-unit">N/A</Text>
        </Paragraph>
      </Col>
    </Row>
  </div>
);

DisconnectedBattery.propTypes = {
  name: PropTypes.string.isRequired,
};

const ConnectedBattery = ({ name, batteryName }) => {
  const current = useSelector((state) => state.NMEA[batteryName].current);
  const voltage = useSelector((state) => state.NMEA[batteryName].voltage);
  const temperature = useSelector((state) => state.NMEA[batteryName].temperature);
  const stateOfCharge = useSelector((state) => state.NMEA[batteryName].stateOfCharge);
  const timeRemaining = useSelector((state) => state.NMEA[batteryName].timeRemaining);

  const chargePercent = useMemo(() => {
    if (stateOfCharge.value) {
      return stateOfCharge.value * 100;
    }
    return 0;
  },
  [stateOfCharge]);

  const renderVoltage = useMemo(() => {
    if (voltage.value) {
      return voltage.value.toFixed(2);
    }
    return 0;
  }, [voltage]);

  const renderCurrent = useMemo(() => {
    if (current.value) {
      return current.value.toFixed(1);
    }
    return 0;
  }, [current.value]);

  const renderTemperature = useMemo(() => {
    if (temperature.value) {
      return (temperature.value - 273).toFixed(0);
    }
    return 0;
  }, [temperature.value]);

  const chooseStrokeColor = useMemo(() => {
    if (current.value > 0) {
      if (chargePercent > 80) {
        return '#49A078';
      }
      return '#de8d05';
    }
    if (chargePercent > 50) {
      return '#49A078';
    }
    if (chargePercent > 10) {
      return '#6F92A2';
    }
    return '#ff4d4f';
  }, [chargePercent, current.value]);

  const renderStatus = useMemo(() => {
    const color = chooseStrokeColor;
    if (chargePercent === 100) {
      return (
        <Text className="charged-battery-percent">
          <ThunderboltFilled style={{ color }} />
          Charged
        </Text>
      );
    }
    if (current.value > 0) {
      return (
        <Text type="warning">
          <ThunderboltFilled style={{ color }} />
          Charging
        </Text>
      );
    }
    if (timeRemaining.value) {
      let m = timeRemaining.value / 60;
      const h = Math.floor(m / 60);
      m %= 60;
      if (m === 0) { m = '00'; }
      return (
        <div>

          <Text className="battery-widget-hours" style={{ color }}>
            {h}
          </Text>
          <Text type="secondary" style={{ color }}>h</Text>
          <Text className="battery-widget-minutes" style={{ color }}>{m}</Text>
          <Text type="secondary" style={{ color }}>m</Text>
          <Text type="secondary" style={{ color, paddingLeft: 4 }}>remaining</Text>
        </div>
      );
    }
    return <div><Text type="danger" className="battery-widget-hours">0</Text></div>;
  }, [chargePercent, current.value, timeRemaining.value, chooseStrokeColor]);

  const renderCharge = useMemo(() => {
    if (current.value > 0) {
      if (chargePercent > 80) {
        return (
          <Paragraph className="charged-battery-percent">
            {chargePercent.toFixed(1)}
            <PercentageOutlined />
          </Paragraph>
        );
      }
      return (
        <Paragraph type="warning">
          {chargePercent.toFixed(1)}
          <PercentageOutlined />
        </Paragraph>
      );
    }
    if (chargePercent > 50) {
      return (
        <Paragraph className="charged-battery-percent">
          {chargePercent.toFixed(1)}
          <PercentageOutlined />
        </Paragraph>
      );
    }
    if (chargePercent > 10) {
      return (
        <Paragraph style={{ color: '#6F92A2' }}>
          {chargePercent.toFixed(1)}
          <PercentageOutlined />
        </Paragraph>
      );
    }
    return (
      <Paragraph type="danger">
        {chargePercent.toFixed(1)}
        <PercentageOutlined />
      </Paragraph>
    );
  }, [chargePercent, current.value]);

  return (
    <div className="battery-widget">
      <Paragraph className="battery-widget-title">
        {name}
      </Paragraph>
      <Progress
        className="battery-widget-progress-bar"
        percent={chargePercent}
        showInfo={false}
        gapDegree={0}
        strokeWidth={25}
        status={chargePercent < 10 ? 'exception' : null}
        strokeColor={chooseStrokeColor}
      />
      <Row>
        <Col span={12} style={{ textAlign: 'left', paddingLeft: 8 }}>
          { renderStatus }
        </Col>
        <Col span={12} style={{ textAlign: 'right', paddingRight: 8 }}>
          {renderCharge}
        </Col>
      </Row>
      <Row>
        <Col span={batteryName.includes('House') ? 8 : 12}>
          <LabelValue title="Current" value={renderCurrent} suffix="A" />
          {/* <Paragraph type="secondary">Current</Paragraph>
          <Paragraph>
            {renderCurrent}
            <Text type="secondary" className="value-unit">A</Text>
          </Paragraph> */}
        </Col>
        <Col span={batteryName.includes('House') ? 8 : 12}>
          <LabelValue title="Voltage" value={renderVoltage} suffix="V" />
        </Col>
        {batteryName.includes('House') && (
        <Col span={8}>
          <LabelValue title="Temperature" value={renderTemperature} suffix="&deg;C" />
        </Col>
        )}
      </Row>
    </div>
  );
};

ConnectedBattery.propTypes = {
  name: PropTypes.string.isRequired,
  batteryName: PropTypes.string.isRequired,
};


const BatteryWidget = ({
  batteryName, showDetails = true,
}) => {
  const connected = useSelector((state) => state.ServerState.nmeaStatus[batteryName]);
  const name = batteryName.replace(/_/g, ' ');

  return (
    <div>
      {showDetails && (
      <span>
        <Link to={`/batteries/${batteryName}`}>
          <CornerIcon
            width={90}
            tooltipTitle={<Text>View Details</Text>}
            side="left"
            trigger="hover"
            active={connected}
          />
        </Link>
      </span>
      )}
      {connected.transmitting ? (
        <ConnectedBattery name={name} batteryName={batteryName} />
      ) : (
        <DisconnectedBattery name={name} />
      )}
    </div>
  );
};

BatteryWidget.propTypes = {
  batteryName: PropTypes.string.isRequired,
  showDetails: PropTypes.bool,
};

export default BatteryWidget;
