import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import DimmableCircuit from './DimmableCircuit';
import BinaryCircuit from './BinaryCircuit';
import CornerIcon from '../../shared/CornerIcon';
import CircuitInfoContainer from './CircuitInfoContainer';
import usePermissions from '../../../../customHooks/usePermissions';

const { Paragraph } = Typography;

const RenderCircuitByType = React.memo(({
  circuitName, title, noDetails = false,
}) => {
  const kind = useSelector((state) => state.Circuits.loads[circuitName].meta.originalKind);
  const status = useSelector((state) => state.Circuits.loads[circuitName].actualState);
  const privilege = useSelector((state) => state.Circuits.loads[circuitName].meta.privilege);
  const hasPermissions = usePermissions(privilege);
  const renderType = useMemo(() => {
    if (kind.includes('Dim 1x')) {
      return (
        <DimmableCircuit circuitName={circuitName} disabled={!hasPermissions} />
      );
    }
    if (kind.includes('On/Off')) {
      return (
        <BinaryCircuit
          circuitName={circuitName}
          disabled={!hasPermissions}
        />
      );
    }
    return null;
  }, [kind, circuitName, hasPermissions]);
  return (
    <div className="circuit-container">
      <span>
        {!noDetails && (
        <CornerIcon
          width={330}
          active={!!status}
          tooltipTitle={<CircuitInfoContainer name={circuitName} />}
          icon="poweroff"
          side="left"
          trigger="hover"
        />
        )}
      </span>
      <Paragraph style={{ fontSize: 16 }}>
        {title}
      </Paragraph>
      {renderType}
    </div>
  );
});

RenderCircuitByType.propTypes = {
  circuitName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  noDetails: PropTypes.bool,
};

export default RenderCircuitByType;
