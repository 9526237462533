import React from 'react';
import { Row, Col } from 'antd';
import PresetCircuit from '../../widgets/circuits/DCM/PresetCircuit';

const Presets = () => (
  <Row>
    <Col xs={24} sm={12}>
      <PresetCircuit circuitName="All_Lights" title="All Lights" />
    </Col>
    <Col xs={24} sm={12}>
      <PresetCircuit circuitName="Cabin" title="Cabin" />
    </Col>
    <Col xs={24} sm={12}>
      <PresetCircuit circuitName="Utility" title="Utility" />
    </Col>
    <Col xs={24} sm={12}>
      <PresetCircuit circuitName="Boarding" title="Boarding" />
    </Col>
  </Row>
);

export default Presets;
