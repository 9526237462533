import React from 'react';
import { Row, Col } from 'antd';
import BilgeWidget from '../widgets/bilge/BilgeWidget';
import {
  Bilge_Alarm_Port_Forepeak,
  Bilge_Auto_Forepeak_Port,
  Bilge_Pump_Port_Forepeak,
  Bilge_Cycle_Forepeak_Port,
  Bilge_Alarm_Stbd_Forepeak,
  Bilge_Auto_Forepeak_Stbd,
  Bilge_Pump_Stbd_Forepeak,
  Bilge_Alarm_Port_Midship,
  Bilge_Auto_Midship_Port,
  Bilge_Pump_Port_Midship,
  Bilge_Alarm_Stbd_Midship,
  Bilge_Auto_Midship_Stbd,
  Bilge_Pump_Stbd_Midship,
  Bilge_Alarm_Port_Engine,
  Bilge_Auto_Engine_Port,
  Bilge_Pump_Port_Engine,
  Bilge_Alarm_Stbd_Engine,
  Bilge_Auto_Engine_Stbd,
  Bilge_Pump_Stbd_Engine,
  Bilge_Cycle_Forepeak_Stbd,
  Bilge_Cycle_Engine_Stbd,
  Bilge_Cycle_Engine_Port,
  Bilge_Cycle_Midship_Port,
  Bilge_Cycle_Midship_Stbd,
} from '../../api/circuits';

const Bilges = () => (
  <Row justify="space-around">
    <Col xs={24} sm={12} lg={{ span: 8, order: 1 }}>
      <BilgeWidget
        alarmName="Forepeak_Bilge_Port"
        statusName={Bilge_Alarm_Port_Forepeak}
        cycleCountName={Bilge_Cycle_Forepeak_Port}
        autoName={Bilge_Auto_Forepeak_Port}
        onOffName={Bilge_Pump_Port_Forepeak}
        title="Forepeak Port"
      />
    </Col>
    <Col xs={24} sm={12} lg={{ span: 8, order: 4 }}>
      <BilgeWidget
        alarmName="Forepeak_Bilge_Stbd"
        statusName={Bilge_Alarm_Stbd_Forepeak}
        cycleCountName={Bilge_Cycle_Forepeak_Stbd}
        autoName={Bilge_Auto_Forepeak_Stbd}
        onOffName={Bilge_Pump_Stbd_Forepeak}
        title="Forepeak Stbd"
      />
    </Col>
    <Col xs={24} sm={12} lg={{ span: 8, order: 2 }}>
      <BilgeWidget
        alarmName="Mid_Bilge_Port"
        statusName={Bilge_Alarm_Port_Midship}
        cycleCountName={Bilge_Cycle_Midship_Port}
        autoName={Bilge_Auto_Midship_Port}
        onOffName={Bilge_Pump_Port_Midship}
        title="Midship Port"
      />
    </Col>
    <Col xs={24} sm={12} lg={{ span: 8, order: 5 }}>
      <BilgeWidget
        alarmName="Mid_Bilge_Stbd"
        statusName={Bilge_Alarm_Stbd_Midship}
        cycleCountName={Bilge_Cycle_Midship_Stbd}
        autoName={Bilge_Auto_Midship_Stbd}
        onOffName={Bilge_Pump_Stbd_Midship}
        title="Midship Stbd"
      />
    </Col>
    <Col xs={24} sm={12} lg={{ span: 8, order: 3 }}>
      <BilgeWidget
        alarmName="Engine_Bilge_Port"
        statusName={Bilge_Alarm_Port_Engine}
        cycleCountName={Bilge_Cycle_Engine_Port}
        autoName={Bilge_Auto_Engine_Port}
        onOffName={Bilge_Pump_Port_Engine}
        title="Engine Port"
      />
    </Col>
    <Col xs={24} sm={12} lg={{ span: 8, order: 6 }}>
      <BilgeWidget
        alarmName="Engine_Bilge_Stbd"
        statusName={Bilge_Alarm_Stbd_Engine}
        cycleCountName={Bilge_Cycle_Engine_Stbd}
        autoName={Bilge_Auto_Engine_Stbd}
        onOffName={Bilge_Pump_Stbd_Engine}
        title="Engine Stbd"
      />
    </Col>
  </Row>
);
export default Bilges;
