import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'antd';

const Rams = () => (
  <div style={{ padding: 16 }}>
    <Row gutter={16} justify="space-around">
      <Col sm={8} xs={24}>
        <Link to="/actuators/Outhaul">
          <Button ghost type="primary" className="hydraulic-menu-item" size="large">
            Outhaul
          </Button>
        </Link>
      </Col>
      <Col sm={8} xs={24}>
        <Link to="/actuators/Cunningham">
          <Button ghost type="primary" className="hydraulic-menu-item" size="large">
            Cunningham
          </Button>
        </Link>
      </Col>
      <Col sm={8} xs={24}>
        <Link to="/actuators/Tackline">
          <Button ghost type="primary" className="hydraulic-menu-item" size="large">
            Tackline
          </Button>
        </Link>
      </Col>
      <Col sm={12} xs={24}>
        <Link to="/actuators/Mainsheet">
          <Button ghost type="primary" className="hydraulic-menu-item" size="large">
            Mainsheet
          </Button>
        </Link>
      </Col>
      <Col sm={12} xs={24}>
        <Link to="/actuators/Rotator">
          <Button ghost type="primary" className="hydraulic-menu-item" size="large">
            Rotator
          </Button>
        </Link>
      </Col>
    </Row>
  </div>
);
export default Rams;
