import React from 'react';
import { Row, Col } from 'antd';
import {
  ACA_Dimming_Glass_Controller_Port,
  ACA_Dimming_Glass_Controller_Stbd,
  ACB_Dimming_Glass_Controller_Port,
  ACB_Dimming_Glass_Controller_Stbd,
  ACC_Dimming_Glass_Controller_Port,
  ACC_Dimming_Glass_Controller_Stbd,
  Light_White_Saloon,
  Entertainment_System,
} from '../../api/circuits';
import RenderCircuitByType from '../widgets/circuits/DCM/RenderCircuitByType';
import ACCircuitWidget from '../widgets/circuits/120V/ACCircuitWidget';

const SaloonPage = () => (
  <Row>
    <Col xs={24} sm={12}>
      <ACCircuitWidget shore={ACC_Dimming_Glass_Controller_Port} inverter={ACB_Dimming_Glass_Controller_Port} circuit={ACA_Dimming_Glass_Controller_Port} name="Dimmable Glass Port" />
    </Col>
    <Col xs={24} sm={12}>
      <ACCircuitWidget shore={ACC_Dimming_Glass_Controller_Stbd} inverter={ACB_Dimming_Glass_Controller_Stbd} circuit={ACA_Dimming_Glass_Controller_Stbd} name="Dimmable Glass Stbd" />
    </Col>
    <Col xs={24} sm={12}>
      <RenderCircuitByType circuitName={Light_White_Saloon} title="Lights" />
    </Col>
    <Col xs={24} sm={12}>
      <RenderCircuitByType circuitName={Entertainment_System} title="Entertainment" />
    </Col>
  </Row>
);

export default SaloonPage;
