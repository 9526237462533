import React from 'react';
import {
  Row, Col, Divider,
} from 'antd';
import {
  ACA_Air_Con_Port_Aft,
  ACA_Air_Con_Saloon,
  ACA_Air_Con_Stbd_Aft,
  ACA_Dimming_Glass_Controller_Port,
  ACA_Dimming_Glass_Controller_Stbd,
  ACA_Ice_Maker,
  ACA_Satellite_Communication,
  ACA_Sockets_Cockpit,
  ACA_Sockets_Forepeak,
  ACA_Sockets_Port,
  ACA_Sockets_Saloon_Galley,
  ACA_Sockets_Stbd,
  ACA_Water_Heater,
  ACA_Wine_Chiller,
  ACB_Air_Con_Port_Aft,
  ACB_Air_Con_Saloon,
  ACB_Air_Con_Stbd_Aft,
  ACB_Dimming_Glass_Controller_Port,
  ACB_Dimming_Glass_Controller_Stbd,
  ACB_Ice_Maker,
  ACB_Satellite_Communication,
  ACB_Sockets_Cockpit,
  ACB_Sockets_Forepeak,
  ACB_Sockets_Port,
  ACB_Sockets_Saloon_Galley,
  ACB_Sockets_Stbd,
  ACB_Water_Heater,
  ACB_Wine_Chiller,
  ACC_Air_Con_Port_Aft,
  ACC_Air_Con_Saloon,
  ACC_Air_Con_Stbd_Aft,
  ACC_Dimming_Glass_Controller_Port,
  ACC_Dimming_Glass_Controller_Stbd,
  ACC_Ice_Maker,
  ACC_Satellite_Communication,
  ACC_Sockets_Cockpit,
  ACC_Sockets_Forepeak,
  ACC_Sockets_Port,
  ACC_Sockets_Saloon_Galley,
  ACC_Sockets_Stbd,
  ACC_Water_Heater,
  ACC_Wine_Chiller,
} from '../../api/circuits';
import ACCircuitWidget from '../widgets/circuits/120V/ACCircuitWidget';
import ACSources from '../shared/120VSources';

const ACPower = () => (
  <div className="ac-page">
    <ACSources />
    <Divider className="ac-divider" />
    <Row justify="space-around">
      <Col md={8} xs={24}>
        <ACCircuitWidget
          name="A/C Port Aft"
          showDetails={false}
          shore={ACC_Air_Con_Port_Aft}
          inverter={ACB_Air_Con_Port_Aft}
          circuit={ACA_Air_Con_Port_Aft}
        />
      </Col>
      <Col md={8} xs={24}>
        <ACCircuitWidget
          name="A/C Stbd Aft"
          showDetails={false}
          shore={ACC_Air_Con_Stbd_Aft}
          inverter={ACB_Air_Con_Stbd_Aft}
          circuit={ACA_Air_Con_Stbd_Aft}
        />
      </Col>
      <Col md={8} xs={24}>
        <ACCircuitWidget
          name="A/C Saloon"
          showDetails={false}
          shore={ACC_Air_Con_Saloon}
          inverter={ACB_Air_Con_Saloon}
          circuit={ACA_Air_Con_Saloon}
        />
      </Col>
      <Divider className="ac-divider" />
      <Col md={12} xs={24}>
        <ACCircuitWidget
          name="Dimmable Glass Port"
          showDetails={false}
          shore={ACC_Dimming_Glass_Controller_Port}
          inverter={ACB_Dimming_Glass_Controller_Port}
          circuit={ACA_Dimming_Glass_Controller_Port}
        />
      </Col>
      <Col md={12} xs={24}>
        <ACCircuitWidget
          name="Dimmable Glass Stbd"
          showDetails={false}
          shore={ACC_Dimming_Glass_Controller_Stbd}
          inverter={ACB_Dimming_Glass_Controller_Stbd}
          circuit={ACA_Dimming_Glass_Controller_Stbd}
        />
      </Col>
      <Divider className="ac-divider" />
      <Col md={8} xs={24}>
        <ACCircuitWidget
          name="Sockets Cockpit"
          showDetails={false}
          shore={ACC_Sockets_Cockpit}
          inverter={ACB_Sockets_Cockpit}
          circuit={ACA_Sockets_Cockpit}
        />
      </Col>
      <Col md={8} xs={24}>
        <ACCircuitWidget
          name="Sockets Forepeak"
          showDetails={false}
          shore={ACC_Sockets_Forepeak}
          inverter={ACB_Sockets_Forepeak}
          circuit={ACA_Sockets_Forepeak}
        />
      </Col>
      <Col md={8} xs={24}>
        <ACCircuitWidget
          name="Sockets Saloon / Galley"
          showDetails={false}
          shore={ACC_Sockets_Saloon_Galley}
          inverter={ACB_Sockets_Saloon_Galley}
          circuit={ACA_Sockets_Saloon_Galley}
        />
      </Col>
      <Col md={12} xs={24}>
        <ACCircuitWidget
          name="Sockets Port"
          showDetails={false}
          shore={ACC_Sockets_Port}
          inverter={ACB_Sockets_Port}
          circuit={ACA_Sockets_Port}
        />
      </Col>
      <Col md={12} xs={24}>
        <ACCircuitWidget
          name="Sockets Stbd"
          showDetails={false}
          shore={ACC_Sockets_Stbd}
          inverter={ACB_Sockets_Stbd}
          circuit={ACA_Sockets_Stbd}
        />
      </Col>
      <Divider className="ac-divider" />
      <Col md={12} xs={24}>
        <ACCircuitWidget
          name="Satellite Communication"
          showDetails={false}
          shore={ACC_Satellite_Communication}
          inverter={ACB_Satellite_Communication}
          circuit={ACA_Satellite_Communication}
        />
      </Col>
      <Col md={12} xs={24}>
        <ACCircuitWidget
          name="Ice Maker"
          showDetails={false}
          shore={ACC_Ice_Maker}
          inverter={ACB_Ice_Maker}
          circuit={ACA_Ice_Maker}
        />
      </Col>
      <Col md={12} xs={24}>
        <ACCircuitWidget
          name="Water Heater"
          showDetails={false}
          shore={ACC_Water_Heater}
          inverter={ACB_Water_Heater}
          circuit={ACA_Water_Heater}
        />
      </Col>
      <Col md={12} xs={24}>
        <ACCircuitWidget
          name="Wine Chiller"
          showDetails={false}
          shore={ACC_Wine_Chiller}
          inverter={ACB_Wine_Chiller}
          circuit={ACA_Wine_Chiller}
        />
      </Col>
    </Row>
  </div>
);
export default ACPower;
