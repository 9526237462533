import Axios from 'axios';
import ENDPOINT from './addresses';

export const getRoleCookie = () => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/role/permissions?sessionId=${localStorage.getItem('login_id')}`)
    .then((response) => resolve(response))
    .catch((response) => resolve(response));
});

export const authenticateRole = (role, code) => new Promise((resolve) => {
  Axios.post(`${ENDPOINT}/api/v1/role/authenticate`, { role: role || 'Guest', password: code })
    .then((response) => {
      if (response.data.id && localStorage.getItem('login_id')) {
        localStorage.removeItem('login_id');
        localStorage.setItem('login_id', response.data.id);
      } else if (response.data.id) {
        localStorage.setItem('login_id', response.data.id);
      }
      resolve(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
});

export const postRolePIN = (role, currentPassword, newPassword) => new Promise((resolve) => {
  Axios.post(`${ENDPOINT}/api/v1/role/password/set`, { role, current_password: currentPassword, new_password: newPassword })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
});

export const removeRolePIN = (role, currentPassword) => new Promise((resolve) => {
  Axios.post(`${ENDPOINT}/api/v1/role/password/unset`, { role, current_password: currentPassword })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
});
export const getRolePermissions = () => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/role/permissions`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
});
