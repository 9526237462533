import { authenticateRole, postRolePIN, removeRolePIN } from '../api/roles';
import { UPDATE_ROLE } from './types';

export const updateRole = (role, pin) => (dispatch) => authenticateRole(role, pin)
  .then((res) => {
    if (res.authenticated) {
      dispatch({ type: UPDATE_ROLE, payload: res });
    }
    return res;
  });

export const updateRolePIN = (role, currentPin, newPin) => () => {
  if (newPin) {
    return postRolePIN(role, currentPin, newPin).then((res) => res);
  }
  return removeRolePIN(role, currentPin).then((res) => res);
};
