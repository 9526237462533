import receivePersistentData from './persistence';
import {
  SET_CONNECTION,
  SET_CONNECTING,
  RECEIVE_ENABLES_DATA,
  UPDATE_ROLE,
  RECEIVE_CIRCUITS_DATA,
  FUSE_TRIPPED,
  RECEIVE_ALARMS_DATA,
  RECEIVE_HYDRAULICS_DATA,
  RECEIVE_NMEA_DATA,
  RECEIVE_NMEA_ENTITIES,
  RECEIVE_SERVER_STATE,
  RECEIVE_ALARMS_NAME_LIST,
} from './types';
import { convertArrayToObject } from '../helpers';

const initializeData = (data) => (dispatch) => {
  dispatch({ type: SET_CONNECTING, payload: true });
  dispatch({
    type: RECEIVE_SERVER_STATE,
    payload: {
      ...data.circuits.status,
      statusMessages: { ...data.circuits.statusMessages, ...data.circuits.reports },
    },
  });
  dispatch({ type: RECEIVE_NMEA_ENTITIES, payload: data.nmeaStatus });
  dispatch({ type: RECEIVE_NMEA_DATA, payload: data.nmeaInitialState });
  dispatch({ type: RECEIVE_HYDRAULICS_DATA, payload: data.circuits.actuators });
  dispatch({ type: RECEIVE_ALARMS_DATA, payload: convertArrayToObject(data.alarms, 'name') });
  dispatch({ type: RECEIVE_ALARMS_NAME_LIST, payload: data.alarms });
  Object.keys(data.circuits.fuses).forEach((fuse) => {
    if (data.circuits.fuses[fuse].tripped) {
      dispatch({ type: FUSE_TRIPPED, fuse });
    }
  });
  dispatch({
    type: RECEIVE_CIRCUITS_DATA,
    payload: {
      loads: data.circuits.loads,
      fuses: data.circuits.fuses,
      values: data.circuits.values,
    },
  });
  if (data.role.status !== 403) {
    dispatch({ type: UPDATE_ROLE, payload: data.role });
  }
  const Group_0 = data.enables.filter((item) => item.group_id === 0)
    .sort((a, b) => a.enable_id - b.enable_id);
  const Group_1 = data.enables.filter((item) => item.group_id === 1)
    .sort((a, b) => a.enable_id - b.enable_id);
  const Group_2 = data.enables.filter((item) => item.group_id === 2)
    .sort((a, b) => a.enable_id - b.enable_id);
  const Group_3 = data.enables.filter((item) => item.group_id === 3)
    .sort((a, b) => a.enable_id - b.enable_id);
  const Group_4 = data.enables.filter((item) => item.group_id === 4)
    .sort((a, b) => a.enable_id - b.enable_id);
  const Group_5 = data.enables.filter((item) => item.group_id === 5)
    .sort((a, b) => a.enable_id - b.enable_id);
  const Group_6 = data.enables.filter((item) => item.group_id === 6)
    .sort((a, b) => a.enable_id - b.enable_id);
  const Group_7 = data.enables.filter((item) => item.group_id === 7)
    .sort((a, b) => a.enable_id - b.enable_id);
  dispatch({
    type: RECEIVE_ENABLES_DATA,
    payload: {
      0: Group_0,
      1: Group_1,
      2: Group_2,
      3: Group_3,
      4: Group_4,
      5: Group_5,
      6: Group_6,
      7: Group_7,
    },
  });
  dispatch(receivePersistentData(data.persistentData));
  dispatch({ type: SET_CONNECTING, payload: false });
  dispatch({ type: SET_CONNECTION, payload: true });
};

export default initializeData;
