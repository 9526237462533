import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { PoweroffOutlined, InfoCircleOutlined, ApiOutlined } from '@ant-design/icons';


const CornerIcon = ({
  tooltipTitle,
  active = false,
  icon = 'info-circle',
  side,
  width = 100,
  warning = false,
  onClick = () => {},
  trigger = 'hover',
}) => {
  const renderIconByType = useMemo(() => {
    if (icon === 'poweroff') {
      return (
        <PoweroffOutlined
          onClick={onClick}
          type={icon}
          className={`corner-icon ${side} ${active ? 'active' : ''} ${warning ? 'warning' : ''}`}
        />
      );
    }
    if (icon === 'api') {
      return (
        <ApiOutlined
          onClick={onClick}
          type={icon}
          className={`corner-icon ${side} ${active ? 'active' : ''} ${warning ? 'warning' : ''}`}
        />
      );
    }
    return (
      <InfoCircleOutlined
        onClick={onClick}
        type={icon}
        className={`corner-icon ${side} ${active ? 'active' : ''} ${warning ? 'warning' : ''}`}
      />
    );
  }, [icon, onClick, side, warning, active]);
  return (
    <Tooltip
      overlayClassName="corner-icon-tooltip"
      placement={`bottom${side.charAt(0).toUpperCase() + side.slice(1)}`}
      trigger={trigger}
      title={<div style={{ width }}>{tooltipTitle}</div>}
    >
      {renderIconByType}
    </Tooltip>
  );
};

CornerIcon.propTypes = {
  tooltipTitle: PropTypes.instanceOf(Object).isRequired,
  active: PropTypes.bool,
  warning: PropTypes.bool,
  side: PropTypes.string.isRequired,
  width: PropTypes.number,
  onClick: PropTypes.func,
  trigger: PropTypes.string,
};

export default CornerIcon;
