import {
  RECEIVE_ALARMS_DATA,
  RECEIVE_ALARM_UPDATE,
} from '../actions/types';

export default function Alarms(state = {}, action) {
  switch (action.type) {
    case RECEIVE_ALARMS_DATA:
      return action.payload;
    case RECEIVE_ALARM_UPDATE:
      return {
        ...state,
        [action.payload.name]: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
