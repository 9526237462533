import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';

const { Paragraph } = Typography;

const CircuitLabelValuePair = ({
  label, value, leftSpan = 12, rightSpan = 12, className = 'circuit-label-value-pair',
}) => (
  <Row className={className}>
    <Col span={leftSpan} className="circuit-label">
      <Paragraph ellipsis>
        {label}
      </Paragraph>
    </Col>
    <Col span={rightSpan} className="circuit-value">
      <Paragraph ellipsis>
        {value}
      </Paragraph>
    </Col>
  </Row>
);
CircuitLabelValuePair.propTypes = {
  className: PropTypes.string,
  leftSpan: PropTypes.number,
  rightSpan: PropTypes.number,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
    PropTypes.number,
  ]).isRequired,
};

export default CircuitLabelValuePair;
