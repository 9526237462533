import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Typography, Row,
} from 'antd';
import { useSelector } from 'react-redux';
import Enable from './alarmTools/Enable';
import Threshold from './alarmTools/Threshold';
import CornerIcon from '../shared/CornerIcon';
import CircuitLabelValuePair from '../shared/CircuitLabelValuePair';
import AudibleSwitch from './alarmTools/AudibleSwitch';
import Dismiss from './alarmTools/Dismiss';
import usePermissions from '../../../customHooks/usePermissions';

const { Paragraph, Text } = Typography;

const AlarmWidget = ({
  label,
  alarmName,
  format = '',
  thresholdMin = 0,
  thresholdMax = 1,
  convertFunction = null,
  step = 0.1,
  boolean,
  span = 12,
}) => {
  const alarm = useSelector((state) => state.Alarms[alarmName]);
  const {
    active, enabled, acknowledge, name, threshold, isAudible,
  } = alarm;
  const isActive = useMemo(() => active && !acknowledge, [active, acknowledge]);
  const hasPermissions = usePermissions('Alarms');
  return (
    <Col xs={24} sm={22} md={12} lg={span}>
      <div className={`alarm-widget ${isActive ? 'active-alarm' : ''}`}>
        {hasPermissions && (
          <CornerIcon
            side="left"
            width={150}
            warning={isActive}
            tooltipTitle={(
              <div>
                <CircuitLabelValuePair
                  leftSpan={12}
                  rightSpan={12}
                  label="Status"
                  value={isActive ? <Text>Active</Text> : <Text>Inactive</Text>}
                />
                <CircuitLabelValuePair
                  leftSpan={12}
                  rightSpan={12}
                  label="Audible"
                  value={isAudible ? <Text>Yes</Text> : <Text>No</Text>}
                />
                {threshold ? (
                  <CircuitLabelValuePair
                    leftSpan={12}
                    rightSpan={12}
                    label="Threshold"
                    value={<Text>{threshold}</Text>}
                  />
                ) : null}
              </div>
          )}
          />
        )}
        <AudibleSwitch alarmName={alarmName} hasPermissions={hasPermissions} />
        <div>
          <Paragraph className="alarm-name">
            {label}
          </Paragraph>
          <Row justify="space-around">
            {isActive
          && (
            <Col span={12}>
              <Dismiss
                dismissed={acknowledge}
                name={name}
                hasPermissions={hasPermissions}
                isActive={active}
              />
            </Col>
          )}
            <Col span={12}>
              <Enable
                enabled={enabled}
                name={name}
                isActive={active}
                hasPermissions={hasPermissions}
              />
            </Col>
          </Row>
          {!boolean
          && (
          <Threshold
            name={name}
            format={format}
            minimum={thresholdMin}
            maximum={thresholdMax}
            threshold={threshold}
            hasPermissions={hasPermissions}
            step={step}
            convertFunction={convertFunction}
          />
          )}
        </div>
      </div>
    </Col>
  );
};

AlarmWidget.propTypes = {
  label: PropTypes.string.isRequired,
  alarmName: PropTypes.string.isRequired,
  thresholdMin: PropTypes.number,
  thresholdMax: PropTypes.number,
  span: PropTypes.number,
  format: PropTypes.string,
  convertFunction: PropTypes.func,
  step: PropTypes.number,
  boolean: PropTypes.bool,
};

export default AlarmWidget;
