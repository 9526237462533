import React, { useState, useEffect } from 'react';
import { Slider } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { postCircuitDimmingLevelUpdate } from '../../../../api/dc';
import BinaryCircuit from './BinaryCircuit';

const DimmableCircuit = ({
  disabled, circuitName,
}) => {
  const dimmingLevel = useSelector((state) => state.Circuits.loads[circuitName].dimmingLevel);
  const onOff = useSelector((state) => state.Circuits.loads[circuitName].actualState);
  const tripped = useSelector((state) => state.Circuits.fuses[circuitName].tripped);
  const [value, setValue] = useState(dimmingLevel);
  const [changing, setChanging] = useState(false);
  useEffect(() => {
    if (!changing) {
      if (value !== dimmingLevel) {
        setValue(dimmingLevel);
      }
    }
  }, [dimmingLevel, changing, value]);
  const sendDimmingUpdate = (e) => {
    postCircuitDimmingLevelUpdate({ name: circuitName, dimmingLevel: e });
  };

  const setChangingToFalseTimeout = () => { setTimeout(() => setChanging(false), 1500); };

  useEffect(() => () => clearTimeout(setChangingToFalseTimeout));

  return (
    <div className="dimmable-circuit">
      <BinaryCircuit circuitName={circuitName} disabled={disabled} />
      { (onOff && !tripped) ? (
        <Slider
          style={{ maxWidth: 300 }}
          disabled={!onOff || disabled}
          tipFormatter={(e) => `${(Math.trunc(e * 100))}%`}
          max={1}
          min={0.01}
          step={0.01}
          value={value}
          onChange={(e) => {
            if (e !== value) {
              setChanging(true);
              sendDimmingUpdate(e);
              setValue(e);
            }
          }}
          onAfterChange={() => setChangingToFalseTimeout}
        />
      ) : null}
    </div>
  );
};

DimmableCircuit.propTypes = {
  circuitName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DimmableCircuit;
