import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Radio, Col,
} from 'antd';
import SummaryAlarmWidget from '../widgets/alarms/SummaryAlarmWidget';
import AlarmWidget from '../widgets/alarms/AlarmWidget';

function compareName(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

const Alarms = () => {
  const [currentFilter, setCurrentFilter] = useState('ACTIVE');
  const alarmsList = useSelector((state) => state.AlarmList);
  const alarms = useMemo(() => {
    switch (currentFilter) {
      case 'HCEC':
        return alarmsList.filter((alarm) => alarm.kind === 'HCEC').sort(compareName);
      case 'BILGE':
        return alarmsList.filter((alarm) => alarm.name.includes('Bilge')).sort(compareName);
      case 'BATTERIES':
        return alarmsList.filter((alarm) => alarm.path.includes('batteries')).sort(compareName);
      case 'ENGINE':
        return alarmsList.filter((alarm) => (alarm.source === 'Engine_Stbd' || alarm.source === 'Engine_Port')).sort(compareName);
      case 'TANKS':
        return alarmsList.filter((alarm) => alarm.path.includes('tanks')).sort(compareName);
      case 'ACTIVE':
        return alarmsList.filter((alarm) => alarm.active).sort(compareName);
      default:
        return alarmsList.sort(compareName);
    }
  }, [currentFilter, alarmsList]);

  return (
    <div className="alarms-page">
      <Row justify="space-around">
        <Col span={24}>
          <SummaryAlarmWidget />
        </Col>
        <Col span={24} className="alarms-page-filter">
          <Radio.Group onChange={(e) => setCurrentFilter(e.target.value)} defaultValue="ACTIVE" size="medium" style={{ margin: '10px' }} value={currentFilter} buttonStyle="solid">
            <Radio.Button value="ALL">All</Radio.Button>
            <Radio.Button value="ACTIVE">Active</Radio.Button>
            <Radio.Button value="TANKS">Tanks</Radio.Button>
            <Radio.Button value="ENGINE">Engine</Radio.Button>
            <Radio.Button value="BATTERIES">Batteries</Radio.Button>
            <Radio.Button value="BILGE">Bilge</Radio.Button>
            <Radio.Button value="HCEC">HCEC</Radio.Button>
          </Radio.Group>
        </Col>
        {alarms.map((alarm) => {
          if (alarm.name === 'Summary' || alarm.name === 'HTTP_Fault') {
            return null;
          }
          return (
            <AlarmWidget alarmName={alarm.name} boolean label={alarm.name.replace(/_/g, ' ')} key={`${alarm.id}`} />
          );
        })}
      </Row>
    </div>
  );
};

export default Alarms;
