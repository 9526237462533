import Axios from 'axios';
import ENDPOINT from './addresses';

export const postHydraulicAction = (actuatorName, action) => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/actuators/action/${actuatorName}/${action}`)
    .then((response) => resolve(response.data))
    .catch((error) => console.log(error));
});

export const postHydraulicParam = (signalName, parameter, value) => new Promise((resolve) => {
  Axios.put(`${ENDPOINT}/api/v1/actuator/${signalName}`, { [`parameter_${parameter}`]: value })
    .then((response) => resolve(response.data))
    .catch((error) => console.log(error));
});

export const commitHydraulicParams = (actuatorName) => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/actuator/${actuatorName}/commit`)
    .then((response) => resolve(response.data))
    .catch((error) => console.log(error));
});
