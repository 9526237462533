/**
 * @package      DC control software (UI)
 * @description  List of circuits, generated at 2020-04-09 18:05
 * @date         2020-04-09 18:05
 * @author       Decipher Industries <info@decipher.industies>
 * @copyright    2020 (c) Kinetic Scientific, all rights reserved.
 */

/* SWITCHES */
export const ACA_Air_Con_Port_Aft = 'ACA_Air_Con_Port_Aft';
export const ACA_Air_Con_Saloon = 'ACA_Air_Con_Saloon';
export const ACA_Air_Con_Stbd_Aft = 'ACA_Air_Con_Stbd_Aft';
export const ACA_Dimming_Glass_Controller_Port = 'ACA_Dimming_Glass_Controller_Port';
export const ACA_Dimming_Glass_Controller_Stbd = 'ACA_Dimming_Glass_Controller_Stbd';
export const ACA_Ice_Maker = 'ACA_Ice_Maker';
export const ACA_Satellite_Communication = 'ACA_Satellite_Communication';
export const ACA_Sockets_Cockpit = 'ACA_Sockets_Cockpit';
export const ACA_Sockets_Forepeak = 'ACA_Sockets_Forepeak';
export const ACA_Sockets_Port = 'ACA_Sockets_Port';
export const ACA_Sockets_Saloon_Galley = 'ACA_Sockets_Saloon_Galley';
export const ACA_Sockets_Stbd = 'ACA_Sockets_Stbd';
export const ACA_Source_Select = 'ACA_Source_Select';
export const ACA_Spare_05_16 = 'ACA_Spare_05_16';
export const ACA_Water_Heater = 'ACA_Water_Heater';
export const ACA_Wine_Chiller = 'ACA_Wine_Chiller';
export const ACB_Air_Con_Port_Aft = 'ACB_Air_Con_Port_Aft';
export const ACB_Air_Con_Saloon = 'ACB_Air_Con_Saloon';
export const ACB_Air_Con_Stbd_Aft = 'ACB_Air_Con_Stbd_Aft';
export const ACB_Dimming_Glass_Controller_Port = 'ACB_Dimming_Glass_Controller_Port';
export const ACB_Dimming_Glass_Controller_Stbd = 'ACB_Dimming_Glass_Controller_Stbd';
export const ACB_Ice_Maker = 'ACB_Ice_Maker';
export const ACB_Satellite_Communication = 'ACB_Satellite_Communication';
export const ACB_Sockets_Cockpit = 'ACB_Sockets_Cockpit';
export const ACB_Sockets_Forepeak = 'ACB_Sockets_Forepeak';
export const ACB_Sockets_Port = 'ACB_Sockets_Port';
export const ACB_Sockets_Saloon_Galley = 'ACB_Sockets_Saloon_Galley';
export const ACB_Sockets_Stbd = 'ACB_Sockets_Stbd';
export const ACB_Spare_05_16 = 'ACB_Spare_05_16';
export const ACB_Water_Heater = 'ACB_Water_Heater';
export const ACB_Wine_Chiller = 'ACB_Wine_Chiller';
export const ACC_Air_Con_Port_Aft = 'ACC_Air_Con_Port_Aft';
export const ACC_Air_Con_Saloon = 'ACC_Air_Con_Saloon';
export const ACC_Air_Con_Stbd_Aft = 'ACC_Air_Con_Stbd_Aft';
export const ACC_Dimming_Glass_Controller_Port = 'ACC_Dimming_Glass_Controller_Port';
export const ACC_Dimming_Glass_Controller_Stbd = 'ACC_Dimming_Glass_Controller_Stbd';
export const ACC_Ice_Maker = 'ACC_Ice_Maker';
export const ACC_Satellite_Communication = 'ACC_Satellite_Communication';
export const ACC_Sockets_Cockpit = 'ACC_Sockets_Cockpit';
export const ACC_Sockets_Forepeak = 'ACC_Sockets_Forepeak';
export const ACC_Sockets_Port = 'ACC_Sockets_Port';
export const ACC_Sockets_Saloon_Galley = 'ACC_Sockets_Saloon_Galley';
export const ACC_Sockets_Stbd = 'ACC_Sockets_Stbd';
export const ACC_Spare_05_16 = 'ACC_Spare_05_16';
export const ACC_Water_Heater = 'ACC_Water_Heater';
export const ACC_Wine_Chiller = 'ACC_Wine_Chiller';
export const AC_Mode = 'AC_Mode';
export const AIS_Silent = 'AIS_Silent';
export const Alarm_Audible = 'Alarm_Audible';
export const Alarm_Silence_Panel = 'Alarm_Silence_Panel';
export const Alarm_Sounder = 'Alarm_Sounder';
export const All_Lights = 'All_Lights';
export const Bilge_Alarm_Port_Engine = 'Bilge_Alarm_Port_Engine';
export const Bilge_Alarm_Port_Forepeak = 'Bilge_Alarm_Port_Forepeak';
export const Bilge_Alarm_Port_Midship = 'Bilge_Alarm_Port_Midship';
export const Bilge_Alarm_Stbd_Engine = 'Bilge_Alarm_Stbd_Engine';
export const Bilge_Alarm_Stbd_Forepeak = 'Bilge_Alarm_Stbd_Forepeak';
export const Bilge_Alarm_Stbd_Midship = 'Bilge_Alarm_Stbd_Midship';
export const Bilge_Auto_Engine_Port = 'Bilge_Auto_Engine_Port';
export const Bilge_Auto_Engine_Stbd = 'Bilge_Auto_Engine_Stbd';
export const Bilge_Auto_Forepeak_Port = 'Bilge_Auto_Forepeak_Port';
export const Bilge_Auto_Forepeak_Stbd = 'Bilge_Auto_Forepeak_Stbd';
export const Bilge_Auto_Midship_Port = 'Bilge_Auto_Midship_Port';
export const Bilge_Auto_Midship_Stbd = 'Bilge_Auto_Midship_Stbd';
export const Bilge_Fan_Auto_Port = 'Bilge_Fan_Auto_Port';
export const Bilge_Pump_Port_Engine = 'Bilge_Pump_Port_Engine';
export const Bilge_Pump_Port_Forepeak = 'Bilge_Pump_Port_Forepeak';
export const Bilge_Pump_Port_Midship = 'Bilge_Pump_Port_Midship';
export const Bilge_Pump_Stbd_Engine = 'Bilge_Pump_Stbd_Engine';
export const Bilge_Pump_Stbd_Forepeak = 'Bilge_Pump_Stbd_Forepeak';
export const Bilge_Pump_Stbd_Midship = 'Bilge_Pump_Stbd_Midship';
export const Binnacle_Lights = 'Binnacle_Lights';
export const Blower_Bilge_Port = 'Blower_Bilge_Port';
export const Blower_Bilge_Stbd = 'Blower_Bilge_Stbd';
export const Blower_Shower_Port_Aft = 'Blower_Shower_Port_Aft';
export const Blower_Shower_Port_Fwd = 'Blower_Shower_Port_Fwd';
export const Boarding = 'Boarding';
export const Button_Alarm_Silence = 'Button_Alarm_Silence';
export const Button_Blower_Port_Aft = 'Button_Blower_Port_Aft';
export const Button_Bunk_Port_Aft_A = 'Button_Bunk_Port_Aft_A';
export const Button_Bunk_Port_Aft_B = 'Button_Bunk_Port_Aft_B';
export const Button_Bunk_Stbd_Aft_A = 'Button_Bunk_Stbd_Aft_A';
export const Button_Bunk_Stbd_Aft_B = 'Button_Bunk_Stbd_Aft_B';
export const Button_Cabin_Port_Aft = 'Button_Cabin_Port_Aft';
export const Button_Cabin_Stbd_Aft = 'Button_Cabin_Stbd_Aft';
export const Button_Cockpit = 'Button_Cockpit';
export const Button_Cockpit_Rope = 'Button_Cockpit_Rope';
export const Button_Courtesy_Port_Aft = 'Button_Courtesy_Port_Aft';
export const Button_Courtesy_Stbd = 'Button_Courtesy_Stbd';
export const Button_Courtesy_Stbd_B = 'Button_Courtesy_Stbd_B';
export const Button_Davit = 'Button_Davit';
export const Button_Fan_Port_Aft = 'Button_Fan_Port_Aft';
export const Button_Fan_Stbd_Aft = 'Button_Fan_Stbd_Aft';
export const Button_Forepeak_Port = 'Button_Forepeak_Port';
export const Button_Forepeak_Stbd = 'Button_Forepeak_Stbd';
export const Button_Head_Red_Port_Aft = 'Button_Head_Red_Port_Aft';
export const Button_Head_Red_Stbd_Aft = 'Button_Head_Red_Stbd_Aft';
export const Button_Head_White_Port_Aft = 'Button_Head_White_Port_Aft';
export const Button_Head_White_Stbd_Aft = 'Button_Head_White_Stbd_Aft';
export const Button_Locker_Tech_Space = 'Button_Locker_Tech_Space';
export const Button_Locker_Wet = 'Button_Locker_Wet';
export const Button_Locker_Wing_Port_Fwd = 'Button_Locker_Wing_Port_Fwd';
export const Button_Locker_Wing_Stbd_Fwd = 'Button_Locker_Wing_Stbd_Fwd';
export const Button_Reading_Inboard_Port_Aft = 'Button_Reading_Inboard_Port_Aft';
export const Button_Reading_Inboard_Stbd_Aft = 'Button_Reading_Inboard_Stbd_Aft';
export const Button_Reading_Outboard_Port_Aft = 'Button_Reading_Outboard_Port_Aft';
export const Button_Reading_Outboard_Stbd_Aft = 'Button_Reading_Outboard_Stbd_Aft';
export const Button_Red_Nav_Galley_Galley = 'Button_Red_Nav_Galley_Galley';
export const Button_Red_Nav_Galley_Nav = 'Button_Red_Nav_Galley_Nav';
export const Button_Saloon = 'Button_Saloon';
export const Button_Saloon_A = 'Button_Saloon_A';
export const Button_Shower_Port_Aft = 'Button_Shower_Port_Aft';
export const Button_Shower_Stbd_Aft = 'Button_Shower_Stbd_Aft';
export const Button_Transom = 'Button_Transom';
export const Button_Transom_Port = 'Button_Transom_Port';
export const Button_Transom_Stbd = 'Button_Transom_Stbd';
export const Button_White_Galley = 'Button_White_Galley';
export const Button_White_Nav = 'Button_White_Nav';
export const Button_White_Nav_Galley = 'Button_White_Nav_Galley';
export const Button_Workshop = 'Button_Workshop';
export const Cabin = 'Cabin';
export const Engine_Port_Ignition = 'Engine_Port_Ignition';
export const Engine_Stbd_Ignition = 'Engine_Stbd_Ignition';
export const Entertainment_System = 'Entertainment_System';
export const Fan_Port_Aft = 'Fan_Port_Aft';
export const Fan_Port_Fwd = 'Fan_Port_Fwd';
export const Fan_Stbd_Aft = 'Fan_Stbd_Aft';
export const Fan_Stbd_Fwd = 'Fan_Stbd_Fwd';
export const Force_API_PGN_Transmit = 'Force_API_PGN_Transmit';
export const Freezer = 'Freezer';
export const Group_1_Shore_Detect = 'Group_1_Shore_Detect';
export const Group_2_Shore_Detect = 'Group_2_Shore_Detect';
export const House = 'House';
export const Inverter_Output_Detect = 'Inverter_Output_Detect';
export const LPG_Sniffer = 'LPG_Sniffer';
export const Light_Bunk_Port_Aft = 'Light_Bunk_Port_Aft';
export const Light_Bunk_Stbd_Aft = 'Light_Bunk_Stbd_Aft';
export const Light_Cabin_Port_Aft = 'Light_Cabin_Port_Aft';
export const Light_Cabin_Port_Fwd = 'Light_Cabin_Port_Fwd';
export const Light_Cabin_Stbd_Aft = 'Light_Cabin_Stbd_Aft';
export const Light_Cabin_Stbd_Fwd = 'Light_Cabin_Stbd_Fwd';
export const Light_Cockpit = 'Light_Cockpit';
export const Light_Courtesy_Port_Aft = 'Light_Courtesy_Port_Aft';
export const Light_Courtesy_Port_Fwd = 'Light_Courtesy_Port_Fwd';
export const Light_Courtesy_Stbd_Aft = 'Light_Courtesy_Stbd_Aft';
export const Light_Davit = 'Light_Davit';
export const Light_Forepeak_Port = 'Light_Forepeak_Port';
export const Light_Forepeak_Stbd = 'Light_Forepeak_Stbd';
export const Light_Head_White_Port_Aft = 'Light_Head_White_Port_Aft';
export const Light_Head_White_Port_Fwd = 'Light_Head_White_Port_Fwd';
export const Light_Head_White_Stbd_Aft = 'Light_Head_White_Stbd_Aft';
export const Light_Locker_Tech_Space = 'Light_Locker_Tech_Space';
export const Light_Locker_Wet = 'Light_Locker_Wet';
export const Light_Locker_Wing_Port = 'Light_Locker_Wing_Port';
export const Light_Locker_Wing_Stbd = 'Light_Locker_Wing_Stbd';
export const Light_Reading_Inboard_Port_Aft = 'Light_Reading_Inboard_Port_Aft';
export const Light_Reading_Inboard_Port_Fwd = 'Light_Reading_Inboard_Port_Fwd';
export const Light_Reading_Inboard_Stbd_Aft = 'Light_Reading_Inboard_Stbd_Aft';
export const Light_Reading_Inboard_Stbd_Fwd = 'Light_Reading_Inboard_Stbd_Fwd';
export const Light_Reading_Nav = 'Light_Reading_Nav';
export const Light_Reading_Outboard_Port_Aft = 'Light_Reading_Outboard_Port_Aft';
export const Light_Reading_Outboard_Port_Fwd = 'Light_Reading_Outboard_Port_Fwd';
export const Light_Reading_Outboard_Stbd_Aft = 'Light_Reading_Outboard_Stbd_Aft';
export const Light_Reading_Outboard_Stbd_Fwd = 'Light_Reading_Outboard_Stbd_Fwd';
export const Light_Red_Nav_Galley = 'Light_Red_Nav_Galley';
export const Light_Rope_Cockpit = 'Light_Rope_Cockpit';
export const Light_Shower_Port_Aft = 'Light_Shower_Port_Aft';
export const Light_Shower_Port_Fwd = 'Light_Shower_Port_Fwd';
export const Light_Shower_Stbd_Aft = 'Light_Shower_Stbd_Aft';
export const Light_Transom_Port = 'Light_Transom_Port';
export const Light_Underwater_Port = 'Light_Underwater_Port';
export const Light_Underwater_Stbd = 'Light_Underwater_Stbd';
export const Light_Wall_Port_Fwd = 'Light_Wall_Port_Fwd';
export const Light_Wall_Stbd_Fwd = 'Light_Wall_Stbd_Fwd';
export const Light_White_Galley = 'Light_White_Galley';
export const Light_White_Nav = 'Light_White_Nav';
export const Light_White_Saloon = 'Light_White_Saloon';
export const Light_Workshop = 'Light_Workshop';
export const Nav_Light_Anchor = 'Nav_Light_Anchor';
export const Nav_Light_Deck_Spot = 'Nav_Light_Deck_Spot';
export const Nav_Light_Inboard_Boom = 'Nav_Light_Inboard_Boom';
export const Nav_Light_Outboard_Boom = 'Nav_Light_Outboard_Boom';
export const Nav_Light_Side_Port = 'Nav_Light_Side_Port';
export const Nav_Light_Steaming = 'Nav_Light_Steaming';
export const Networking_Nav = 'Networking_Nav';
export const Networking_Port_Aft = 'Networking_Port_Aft';
export const Networking_Stbd_Aft = 'Networking_Stbd_Aft';
export const Orderly_Shutdown = 'Orderly_Shutdown';
export const Pump_Pressure_Fresh_Port = 'Pump_Pressure_Fresh_Port';
export const Pump_Pressure_Fresh_Stbd = 'Pump_Pressure_Fresh_Stbd';
export const Pump_Sump_Port_Aft = 'Pump_Sump_Port_Aft';
export const Pump_Sump_Port_Fwd = 'Pump_Sump_Port_Fwd';
export const Pump_Sump_Stbd_Aft = 'Pump_Sump_Stbd_Aft';
export const RI10 = 'RI10';
export const Refrigerator = 'Refrigerator';
export const SeaFire_Shutdown_Port = 'SeaFire_Shutdown_Port';
export const SeaFire_Shutdown_Stbd = 'SeaFire_Shutdown_Stbd';
export const Server_Power = 'Server_Power';
export const Server_Reset = 'Server_Reset';
export const Server_Running = 'Server_Running';
export const Spare_00_07 = 'Spare_00_07';
export const Spare_00_24 = 'Spare_00_24';
export const Spare_00_25 = 'Spare_00_25';
export const Spare_00_27 = 'Spare_00_27';
export const Spare_00_28 = 'Spare_00_28';
export const Spare_01_09 = 'Spare_01_09';
export const Spare_01_14 = 'Spare_01_14';
export const Spare_01_15 = 'Spare_01_15';
export const Spare_01_16 = 'Spare_01_16';
export const Spare_02_02 = 'Spare_02_02';
export const Spare_02_08 = 'Spare_02_08';
export const Spare_02_09 = 'Spare_02_09';
export const Spare_02_20 = 'Spare_02_20';
export const Spare_02_21 = 'Spare_02_21';
export const Spare_02_22 = 'Spare_02_22';
export const Spare_03_08 = 'Spare_03_08';
export const Spare_03_09 = 'Spare_03_09';
export const Spare_03_15 = 'Spare_03_15';
export const Spare_03_16 = 'Spare_03_16';
export const Spare_03_22 = 'Spare_03_22';
export const Spare_04_07 = 'Spare_04_07';
export const Spare_04_09 = 'Spare_04_09';
export const Spare_04_21 = 'Spare_04_21';
export const Spare_04_22 = 'Spare_04_22';
export const Spare_04_27 = 'Spare_04_27';
export const Spare_04_28 = 'Spare_04_28';
export const Spare_05_20 = 'Spare_05_20';
export const Spare_05_21 = 'Spare_05_21';
export const Spare_05_22 = 'Spare_05_22';
export const Spare_06_09 = 'Spare_06_09';
export const Spare_06_12 = 'Spare_06_12';
export const Spare_06_13 = 'Spare_06_13';
export const Spare_06_14 = 'Spare_06_14';
export const Spare_06_15 = 'Spare_06_15';
export const Spare_06_16 = 'Spare_06_16';
export const Spare_07_02 = 'Spare_07_02';
export const Spare_07_09 = 'Spare_07_09';
export const Spare_07_10 = 'Spare_07_10';
export const Spare_07_11 = 'Spare_07_11';
export const Spare_07_15 = 'Spare_07_15';
export const Spare_07_16 = 'Spare_07_16';
export const Spare_07_21 = 'Spare_07_21';
export const Spare_07_22 = 'Spare_07_22';
export const Spare_08_09 = 'Spare_08_09';
export const Spare_08_15 = 'Spare_08_15';
export const Spare_08_16 = 'Spare_08_16';
export const Spare_08_22 = 'Spare_08_22';
export const Spare_09_07 = 'Spare_09_07';
export const Spare_09_09 = 'Spare_09_09';
export const Spare_09_12 = 'Spare_09_12';
export const Spare_09_20 = 'Spare_09_20';
export const Spare_09_21 = 'Spare_09_21';
export const Spare_09_22 = 'Spare_09_22';
export const Spare_09_26 = 'Spare_09_26';
export const Spare_09_27 = 'Spare_09_27';
export const Spare_09_28 = 'Spare_09_28';
export const Spare_10_08 = 'Spare_10_08';
export const Spare_10_09 = 'Spare_10_09';
export const Spare_10_15 = 'Spare_10_15';
export const Spare_10_16 = 'Spare_10_16';
export const Spare_10_20 = 'Spare_10_20';
export const Spare_10_21 = 'Spare_10_21';
export const Spare_10_22 = 'Spare_10_22';
export const Spare_11_15 = 'Spare_11_15';
export const Spare_11_16 = 'Spare_11_16';
export const Spare_12_09 = 'Spare_12_09';
export const Spare_12_12 = 'Spare_12_12';
export const Spare_12_15 = 'Spare_12_15';
export const Spare_12_16 = 'Spare_12_16';
export const Spare_12_21 = 'Spare_12_21';
export const Spare_12_22 = 'Spare_12_22';
export const Spare_12_27 = 'Spare_12_27';
export const Spare_12_28 = 'Spare_12_28';
export const System_Debug = 'System_Debug';
export const Tank_Sender_Black_Port = 'Tank_Sender_Black_Port';
export const Tank_Sender_Black_Stbd = 'Tank_Sender_Black_Stbd';
export const Tank_Sender_Fuel_Port = 'Tank_Sender_Fuel_Port';
export const Tank_Sender_Fuel_Stbd = 'Tank_Sender_Fuel_Stbd';
export const Tank_Sender_Water_Port = 'Tank_Sender_Water_Port';
export const Tank_Sender_Water_Stbd = 'Tank_Sender_Water_Stbd';
export const Toilet_Port_Aft = 'Toilet_Port_Aft';
export const Toilet_Port_Fwd = 'Toilet_Port_Fwd';
export const Toilet_Stbd_Aft = 'Toilet_Stbd_Aft';
export const USB_Charging_Helm_Port_Stbd = 'USB_Charging_Helm_Port_Stbd';
export const USB_Charging_Nav = 'USB_Charging_Nav';
export const USB_Charging_Port_Aft = 'USB_Charging_Port_Aft';
export const USB_Charging_Port_Fwd = 'USB_Charging_Port_Fwd';
export const USB_Charging_Stbd_Aft = 'USB_Charging_Stbd_Aft';
export const USB_Charging_Stbd_Fwd = 'USB_Charging_Stbd_Fwd';
export const UpsideUp = 'UpsideUp';
export const Upside_Down = 'Upside_Down';
export const Utility = 'Utility';
export const VHF_AIS_Splitter_Charging = 'VHF_AIS_Splitter_Charging';
export const WTP3_Zeus_Nav = 'WTP3_Zeus_Nav';
export const Waste_Tank_Level_Enable = 'Waste_Tank_Level_Enable';
export const Water_Maker = 'Water_Maker';
export const Windlass_Down = 'Windlass_Down';
export const Windlass_Thermal_Switch = 'Windlass_Thermal_Switch';
export const Windlass_Up = 'Windlass_Up';
export const Windlass_Washdown_Valve = 'Windlass_Washdown_Valve';
export const Wireless_Blower_Port_Fwd = 'Wireless_Blower_Port_Fwd';
export const Wireless_Cabin_Port_Fwd = 'Wireless_Cabin_Port_Fwd';
export const Wireless_Cabin_Stbd_Fwd = 'Wireless_Cabin_Stbd_Fwd';
export const Wireless_Courtesy_Port_Fwd = 'Wireless_Courtesy_Port_Fwd';
export const Wireless_Courtesy_Stbd_Fwd = 'Wireless_Courtesy_Stbd_Fwd';
export const Wireless_Fan_Port_Fwd = 'Wireless_Fan_Port_Fwd';
export const Wireless_Fan_Stbd_Fwd = 'Wireless_Fan_Stbd_Fwd';
export const Wireless_Head_Red_Port_Fwd = 'Wireless_Head_Red_Port_Fwd';
export const Wireless_Head_White_Port_Fwd = 'Wireless_Head_White_Port_Fwd';
export const Wireless_Reading_Inboard_Port_Fwd = 'Wireless_Reading_Inboard_Port_Fwd';
export const Wireless_Reading_Inboard_Stbd_Fwd = 'Wireless_Reading_Inboard_Stbd_Fwd';
export const Wireless_Reading_Outboard_Port_Fwd = 'Wireless_Reading_Outboard_Port_Fwd';
export const Wireless_Reading_Outboard_Stbd_Fwd = 'Wireless_Reading_Outboard_Stbd_Fwd';
export const Wireless_Saloon = 'Wireless_Saloon';
export const Wireless_Shower_Port_Fwd = 'Wireless_Shower_Port_Fwd';
export const Wireless_Wall_Aft_Stbd_B = 'Wireless_Wall_Aft_Stbd_B';

/* VALUES */
export const Bilge_Cycle_Engine_Port = 'Bilge_Cycle_Engine_Port';
export const Bilge_Cycle_Engine_Stbd = 'Bilge_Cycle_Engine_Stbd';
export const Bilge_Cycle_Forepeak_Port = 'Bilge_Cycle_Forepeak_Port';
export const Bilge_Cycle_Forepeak_Stbd = 'Bilge_Cycle_Forepeak_Stbd';
export const Bilge_Cycle_Midship_Port = 'Bilge_Cycle_Midship_Port';
export const Bilge_Cycle_Midship_Stbd = 'Bilge_Cycle_Midship_Stbd';
export const Schema_Revision_Major = 'Schema_Revision_Major';
export const Waste_Tank_Level_Full = 'Waste_Tank_Level_Full';
