import React from 'react';
import { Row, Col } from 'antd';
import Tank from '../widgets/tanks/TankWidget';

const Tanks = () => (
  <Row justify="space-around">
    <Col xs={24} sm={22} md={12} lg={{ span: 8, order: 1 }}>
      <Tank tankName="Fuel_Port" />
    </Col>
    <Col xs={24} sm={22} md={12} lg={{ span: 8, order: 4 }}>
      <Tank tankName="Fuel_Stbd" />
    </Col>
    <Col xs={24} sm={22} md={12} lg={{ span: 8, order: 2 }}>
      <Tank tankName="Water_Port" />
    </Col>
    <Col xs={24} sm={22} md={12} lg={{ span: 8, order: 5 }}>
      <Tank tankName="Water_Stbd" />
    </Col>
    <Col xs={24} sm={22} md={12} lg={{ span: 8, order: 3 }}>
      <Tank tankName="Waste_Port" />
    </Col>
    <Col xs={24} sm={22} md={12} lg={{ span: 8, order: 6 }}>
      <Tank tankName="Waste_Stbd" />
    </Col>
  </Row>
);
export default Tanks;
