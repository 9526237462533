// CONNECTION
export const UPDATE_ENDPOINT = 'UPDATE_ENDPOINT';
export const SET_CONNECTION = 'SET_CONNECTION';
export const SET_CONNECTING = 'SET_CONNECTING';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const RESET_LAST_MESSAGE = 'RESET_LAST_MESSAGE';
// ALARMS
export const RECEIVE_ALARMS_DATA = 'RECEIVE_ALARMS_DATA';
export const RECEIVE_ALARM_UPDATE = 'RECEIVE_ALARM_UPDATE';
export const RECEIVE_ALARMS_NAME_LIST = 'RECEIVE_ALARMS_NAME_LIST';
// NMEA
export const RECEIVE_NMEA_DATA = 'RECEIVE_NMEA_DATA';
export const RECEIVE_NMEA_UPDATE = 'RECEIVE_NMEA_UPDATE';
// DCM11 Actions
export const RECEIVE_CIRCUITS_DATA = 'RECEIVE_CIRCUITS_DATA';
export const RECEIVE_CIRCUITS_UPDATE = 'RECEIVE_CIRCUITS_UPDATE';
export const RECEIVE_LOAD_UPDATE = 'RECEIVE_LOAD_UPDATE';
export const RECEIVE_SWITCH_UPDATE = 'RECEIVE_SWITCH_UPDATE';
export const RECEIVE_FUSE_UPDATE = 'RECEIVE_FUSE_UPDATE';
export const RECEIVE_VALUE_UPDATE = 'RECEIVE_VALUE_UPDATE';
export const UPDATE_TOP_UP_STATUS = 'UPDATE_TOP_UP_STATUS';
export const FUSE_TRIPPED = 'FUSE_TRIPPED';
export const FUSE_RESET = 'FUSE_RESET';
// ENABLES
export const RECEIVE_ENABLES_DATA = 'RECEIVE_ENABLES_DATA';
export const RECEIVE_ENABLE_UPDATE = 'RECEIVE_ENABLE_UPDATE';
// TANKS
export const RECEIVE_WATER_PORT_UPDATE = 'WATER_PORT_UPDATE';
export const RECEIVE_WATER_STBD_UPDATE = 'WATER_STBD_UPDATE';
export const RECEIVE_WASTE_PORT_UPDATE = 'WASTE_PORT_UPDATE';
export const RECEIVE_WASTE_STBD_UPDATE = 'WASTE_STBD_UPDATE';
export const RECEIVE_FUEL_PORT_UPDATE = 'FUEL_PORT_UPDATE';
export const RECEIVE_FUEL_STBD_UPDATE = 'FUEL_STBD_UPDATE';
export const RECEIVE_WATER_PORT_INITIAL_STATE = 'WATER_PORT_INITIAL_STATE';
export const RECEIVE_WATER_STBD_INITIAL_STATE = 'WATER_STBD_INITIAL_STATE';
export const RECEIVE_WATER_WASTE_INITIAL_STATE = 'WATER_WASTE_INITIAL_STATE';
export const RECEIVE_FUEL_PORT_INITIAL_STATE = 'FUEL_PORT_INITIAL_STATE';
export const RECEIVE_FUEL_STBD_INITIAL_STATE = 'FUEL_STBD_INITIAL_STATE';
export const RECEIVE_FUEL_DAY_INITIAL_STATE = 'FUEL_DAY_INITIAL_STATE';
// AC
export const SHORE_UTILITY_UPDATE = 'SHORE_UTILITY_UPDATE';
export const SHORE_AIR_CON_UPDATE = 'SHORE_AIR_CON_UPDATE';
export const AC_HOUSE_UPDATE = 'AC_HOUSE_UPDATE';
// PERSISTENCE API
export const RECEIVE_PERSISTENT_DATA = 'RECEIVE_PERSISTENT_DATA';
export const UPDATE_PERSISTENT_DATA = 'UPDATE_PERSISTENT_DATA';
// UI
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_SIDEBAR_WIDTH = 'SET_SIDEBAR_WIDTH';
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';
export const LOAD_NMEA_2000_STATE = 'LOAD_NMEA_2000_STATE';
export const RECEIVE_NMEA_MAP = 'RECEIVE_NMEA_MAP';
export const SET_SOCKET_ID = 'SET_SOCKET_ID';
export const REMOVE_SOCKET_ID = 'REMOVE_SOCKET_ID';
export const TEST_SERVER_UI_CONNECTION = 'TEST_SERVER_UI_CONNECTION';
export const UPDATE_NMEA_STATUS = 'UPDATE_NMEA_STATUS';
// ENGINE
export const UPDATE_ENGINE_TIMESTAMP = 'UPDATE_ENGINE_TIMESTAMP';
export const ENGINE_PORT_CONNECTED_FALSE = 'ENGINE_PORT_CONNECTED_FALSE';
export const ENGINE_STBD_CONNECTED_FALSE = 'ENGINE_STBD_CONNECTED_FALSE';
export const RECEIVE_ENGINE_PORT_UPDATE = 'RECEIVE_ENGINE_PORT_UPDATE';
export const RECEIVE_ENGINE_STBD_UPDATE = 'RECEIVE_ENGINE_STBD_UPDATE';
// BATTERIES
export const START_PORT_UPDATE = 'START_PORT_UPDATE';
export const START_STBD_UPDATE = 'START_STBD_UPDATE';
export const SOLAR_UPDATE = 'SOLAR_UPDATE';
export const HOUSE_A_UPDATE = 'HOUSE_A_UPDATE';
export const HOUSE_B_UPDATE = 'HOUSE_B_UPDATE';
export const HOUSE_C_UPDATE = 'HOUSE_C_UPDATE';
export const HOUSE_UPDATE = 'HOUSE_UPDATE';
export const UPDATE_HOUSE_A_TIMESTAMP = 'UPDATE_HOUSE_A_TIMESTAMP';
export const UPDATE_HOUSE_B_TIMESTAMP = 'UPDATE_HOUSE_B_TIMESTAMP';
export const UPDATE_HOUSE_C_TIMESTAMP = 'UPDATE_HOUSE_C_TIMESTAMP';
export const UPDATE_HOUSE_TIMESTAMP = 'UPDATE_HOUSE_TIMESTAMP';
export const UPDATE_START_PORT_TIMESTAMP = 'UPDATE_START_PORT_TIMESTAMP';
export const UPDATE_START_STBD_TIMESTAMP = 'UPDATE_START_STBD_TIMESTAMP';
export const HOUSE_BATTERY_CONNECTED_FALSE = 'HOUSE_BATTERY_CONNECTED_FALSE';
export const START_BATTERY_CONNECTED_FALSE = 'START_BATTERY_CONNECTED_FALSE';
// AUTHENTICATION
export const UPDATE_ROLE = 'UPDATE_ROLE';
// FIRMWARE
export const FIRMWARE_UNAVAILABLE = 'SET_FIRMWARE_UNAVAILABLE';
export const FIRMWARE_UPLOAD_RECEIVED = 'FIRMWARE_UPLOAD_RECEIVED';
export const FIRMWARE_CONTROLLERS_LISTENING = 'FIRMWARE_CONTROLLERS_LISTENING';
export const FIRMWARE_TRANSFER_READY = 'FIRMWARE_TRANSFER_READY';
export const FIRMWARE_CONTROLLERS_CONFIRMED = 'FIRMWARE_CONTROLLERS_CONFIRMED';
export const FIRMWARE_TRANSFER_PROGRESS = 'FIRMWARE_TRANSFER_PROGRESS';
export const FIRMWARE_UPDATE_ERROR = 'FIRMWARE_UPDATE_ERROR';
export const FIRMWARE_UPDATE_CANCELLED = 'FIRMWARE_TRANSFER_CANCELLED';
export const FIRMWARE_FLASHING_PROGRESS = 'FIRMWARE_FLASHING_PROGRESS';
export const FIRMWARE_UPDATE_DONE = 'FIRMWARE_UPDATE_DONE';
export const FIRMWARE_UPDATE_IN_PROGRESS = 'FIRMWARE_UPDATE_IN_PROGRESS';
export const FIRMWARE_UPDATE_READY_FOR_UPLOAD = 'FIRMWARE_UPDATE_READY_FOR_UPLOAD';
// SERVER STATE
export const RECEIVE_SERVER_STATE = 'RECEIVE_SERVER_STATE';
export const RECEIVE_STATUS_UPDATE = 'RECEIVE_STATUS_UPDATE';
export const RECEIVE_STATUS_MESSAGE_UPDATE = 'RECEIVE_STATUS_MESSAGE_UPDATE';
export const RECEIVE_REPORT_UPDATE = 'RECEIVE_REPORT_UPDATE';
export const RECEIVE_NMEA_TIMESTAMP = 'RECEIVE_NMEA_TIMESTAMP';
export const NMEA_DEVICE_DISCONNECTED = 'NMEA_DEVICE_DISCONNECTED';
// HYDRAULICS
export const RECEIVE_HYDRAULICS_DATA = 'RECEIVE_HYDRAULICS_DATA';
export const RECEIVE_ACTUATOR_UPDATE = 'RECEIVE_ACTUATOR_UPDATE';
export const RECEIVE_NMEA_ENTITIES = 'RECEIVE_NMEA_ENTITIES';
export const RECEIVE_NMEA_TRANSMITTING_UPDATE = 'RECEIVE_NMEA_TRANSMITTING_UPDATE';
// PERSISTENT DATA
export const RECEIVE_PERSISTENT_DATA_ITEM = 'RECEIVE_PERSISTENT_DATA_ITEM';
