import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { postCircuitStateUpdate } from '../../../../api/dc';
import CornerIcon from '../../shared/CornerIcon';
import CircuitInfoContainer from './CircuitInfoContainer';
import usePermissions from '../../../../customHooks/usePermissions';


const MomentaryCircuit = ({
  circuitName, disabledDefault,
}) => {
  const [loading, setLoading] = useState(false);
  const onOff = useSelector((state) => state.Circuits.loads[circuitName].actualState);
  const tripped = useSelector((state) => state.Circuits.fuses[circuitName].tripped);
  const privilege = useSelector((state) => state.Circuits.loads[circuitName].meta.privilege);
  const hasPermissions = usePermissions(privilege);
  const disabled = disabledDefault || !hasPermissions;
  useEffect(() => {
    if (loading === onOff) {
      setLoading(false);
    }
  }, [onOff, loading]);
  const sendUpdate = (e) => {
    if (!disabled) {
      postCircuitStateUpdate({ name: circuitName, state: e ? 1 : 0 });
    } else {
      message.info('You are not authorized to use this button');
    }
  };
  return (
    <div className="circuit-container momentary-circuit">
      <span>
        <CornerIcon
          width={330}
          active={!!onOff}
          tooltipTitle={<CircuitInfoContainer name={circuitName} />}
          icon="poweroff"
          side="left"
          trigger="hover"
        />
      </span>
      <Button
        type={onOff === 1 ? 'primary' : 'default'}
        className={(tripped) ? 'tripped' : ''}
        disabled={disabled || !hasPermissions}
        style={{ height: 40, marginTop: 32 }}
        onMouseDown={() => onOff !== 1 && sendUpdate(1)}
        onTouchStart={() => onOff !== 1 && sendUpdate(1)}
        onTouchEnd={() => sendUpdate(0)}
        onMouseUp={() => sendUpdate(0)}
        onMouseLeave={() => sendUpdate(0)}
      >
        {circuitName.replace(/_/g, ' ')}
      </Button>
    </div>
  );
};

MomentaryCircuit.propTypes = {
  circuitName: PropTypes.string.isRequired,
  disabledDefault: PropTypes.bool,
};

export default MomentaryCircuit;
