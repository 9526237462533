import Axios from 'axios';
import ENDPOINT from './addresses';

export const getNmeaMap = () => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/signalk/mapping`)
    .then((response) => resolve(response.data))
    .catch((error) => {
      console.log(error);
    });
});

export const getInitialNMEAState = (item) => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/signalk/${item}`)
    .then((response) => resolve(response.data))
    .catch((error) => console.log(error));
});
export const getNMEATransmittingStatus = () => new Promise((resolve) => {
  Axios.get(`${ENDPOINT}/api/v1/signalk/transmitting`)
    .then((response) => resolve(response.data))
    .catch((error) => console.log(error));
});
