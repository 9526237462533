import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from 'antd';
import { updateAlarm } from '../../../api/alarms';

const { Text } = Typography;

const ActiveAlarm = ({ alarm }) => {
  const deferAlarm = () => {
    updateAlarm(alarm.name, { acknowledge: true });
  };
  const disableAlarm = () => {
    updateAlarm(alarm.name, { enabled: false });
  };
  return (
    <div className="active-block">
      <Text className="alarm-name">{alarm.name.replace(/_/g, ' ')}</Text>
      <Button type="link" className="reset-button" onClick={disableAlarm}>Disable</Button>
      <Button
        type="link"
        disabled={!!alarm.acknowledge}
        className="reset-button"
        onClick={deferAlarm}
      >
        {alarm.acknowledge ? 'Dismissed' : 'Dismiss'}
      </Button>
    </div>
  );
};

ActiveAlarm.propTypes = {
  alarm: PropTypes.instanceOf(Object).isRequired,
};

export default ActiveAlarm;
