import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { postPersistentData, updatePersistentData } from '../../../../api/persistence';


const InitialValueSetter = ({
  name, kind, value, existingData = null, disabled,
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loading === value) {
      setLoading(false);
    }
  }, [value, loading]);

  const sendNewInitialValue = (e) => {
    setLoading(e ? 1 : 0);
    if (existingData) {
      updatePersistentData({
        name, key: kind, value: e ? 1 : 0, id: existingData.id,
      });
    } else {
      postPersistentData({ name, key: kind, value: e ? 1 : 0 });
    }
  };
  return (
    <Switch
      checked={value === 1}
      loading={loading !== false}
      disabled={disabled}
      onChange={(e) => sendNewInitialValue(e)}
    />
  );
};

InitialValueSetter.propTypes = {
  name: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  existingData: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool.isRequired,
};

export default InitialValueSetter;
