import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  BulbOutlined,
  CoffeeOutlined,
  CompassOutlined,
  CustomerServiceOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  FilterOutlined,
  GlobalOutlined,
  HomeOutlined,
  PlusCircleOutlined,
  PoweroffOutlined,
  SafetyOutlined,
  SaveOutlined,
  SettingOutlined,
  ToolOutlined,
  UsbOutlined,
  WarningOutlined,
  DisconnectOutlined,
  SettingFilled,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import { TOGGLE_SIDEBAR } from '../actions/types';
import Logo from '../assets/1x/hh66-logo.png';

const { SubMenu } = Menu;

const SidebarContainer = () => {
  const [openKeys, setOpenKeys] = useState([]);
  const sidebarCollapsed = useSelector((state) => state.UI.sidebar.collapsed);
  // const sidebarWidth = useSelector((state) => state.UI.sidebar.width);
  const dispatch = useDispatch();
  const hydraulicsConnected = useSelector((state) => state.ServerState.hydraulics);
  const location = useLocation();

  const menu = () => (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[location.pathname]}
      style={{ paddingTop: 16, width: 250, height: '100%' }}
      openKeys={openKeys}
      onOpenChange={(e) => {
        if (e.length > 1) {
          setOpenKeys([e[1]]);
        } else {
          setOpenKeys([e[0]]);
        }
      }}
      onSelect={() => dispatch({ type: TOGGLE_SIDEBAR })}
    >
      <Menu.Item
        key="home"
        style={{
          width: 250,
          padding: 16,
          height: 80,
          marginBottom: 24,
        }}
      >
        <Link to="/" style={{ textAlign: 'center' }}>
          <img src={Logo} alt="HH66-04" style={{ width: 150 }} />
        </Link>
      </Menu.Item>
      <SubMenu
        key="/lighting"
        title={(
          <span>
            <BulbOutlined />
            <span>Lighting</span>
          </span>
        )}
      >
        <Menu.Item key="/lighting/indoorUtility">
          <Link to="/lighting/indoorUtility">
            <span>
              <ToolOutlined />
                Indoor Utility
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/lighting/outdoorUtility">
          <Link to="/lighting/outdoorUtility">
            <span>
              <ToolOutlined />
                Outdoor Utility
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/lighting/presets">
          <Link to="/lighting/presets">
            <span>
              <SaveOutlined />
                Presets
            </span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="/location"
        title={(
          <span>
            <HomeOutlined />
            <span>Location</span>
          </span>
        )}
      >
        <Menu.Item key="/cabins/portGuest">
          <Link to="/cabins/portGuest">
            <span>
                Port Guest Cabin
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/cabins/owners">
          <Link to="/cabins/owners">
            <span>
                Owner&apos;s Cabin
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/cabins/crew">
          <Link to="/cabins/crew">
            <span>
                Crew Cabin
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/cabins/stbdGuest">
          <Link to="/cabins/stbdGuest">
            <span>
                Stbd Guest Cabin
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/saloon">
          <Link to="/saloon">
            <span>
               Saloon
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/navAndGalley">
          <Link to="/navAndGalley">
            <span>
                Nav Station & Galley
            </span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="/navigation"
        title={(
          <span>
            <CompassOutlined />
            <span>Navigation</span>
          </span>
        )}
      >
        <Menu.Item key="/navigation/lights">
          <Link to="/navigation/lights">
            <span>
              <BulbOutlined />
                Lights
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/navigation/instruments">
          <Link to="/navigation/instruments">
            <span>
              <GlobalOutlined />
                Instruments
            </span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/windlass">
        <Link to="/windlass">
          <span>
            <SettingFilled />
            <span>Windlass</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/energy">
        <Link to="/energy">
          <span>
            <PlusCircleOutlined />
            <span>Energy</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/120V">
        <Link to="/120V">
          <span>
            <PoweroffOutlined />
            <span>120V Power</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/tanks">
        <Link to="/tanks">
          <span>
            <FilterOutlined />
            <span>Tanks</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/bilges">
        <Link to="/bilges">
          <span>
            <SafetyOutlined />
            <span>Bilges</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/alarms">
        <Link to="/alarms">
          <span>
            <WarningOutlined />
            <span>Alarms</span>
          </span>
        </Link>
      </Menu.Item>
      <SubMenu
        key="/domestic"
        title={(
          <span>
            <HomeOutlined />
            <span>Domestic</span>
          </span>
)}
      >
        <Menu.Item key="/domestic/usb-charging">
          <Link to="/domestic/usb-charging">
            <span>
              <UsbOutlined />
                USB Charging
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/domestic/water">
          <Link to="/domestic/water">
            <span>
              <EnvironmentOutlined />
                Water
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/domestic/galley">
          <Link to="/domestic/galley">
            <span>
              <CoffeeOutlined />
                Galley
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/blowers">
          <Link to="/blowers">
            <span>
              <SafetyOutlined />
              <span>Bilge Blowers</span>
            </span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/engines">
        <Link to="/engines">
          <span>
            <DashboardOutlined />
            <span>Engines</span>
          </span>
        </Link>
      </Menu.Item>
      <SubMenu
        key="/hydraulics"
        disabled={!hydraulicsConnected}
        title={(
          <span>
            {!hydraulicsConnected ? <DisconnectOutlined /> : <SettingOutlined />}
            <span>Hydraulics</span>
          </span>
)}
      >
        <Menu.Item key="/system" disabled={!hydraulicsConnected}>
          <Link to="/system">
            <span>
              <SettingOutlined />
                System
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/winches" disabled={!hydraulicsConnected}>
          <Link to="/winches">
            <span>
              <SettingOutlined />
                Winches
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/rams" disabled={!hydraulicsConnected}>
          <Link to="/rams">
            <span>
              <SettingOutlined />
                Rams
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/daggerboards" disabled={!hydraulicsConnected}>
          <Link to="/daggerboards">
            <span>
              <SettingOutlined />
                Daggerboards
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/rudders" disabled={!hydraulicsConnected}>
          <Link to="/rudders">
            <span>
              <SettingOutlined />
                Rudders
            </span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/about">
        <Link to="/about">
          <span>
            <CustomerServiceOutlined />
            <span>About</span>
          </span>
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Sidebar
      sidebar={menu()}
      open={!sidebarCollapsed}
      onSetOpen={() => dispatch({ type: TOGGLE_SIDEBAR })}
      sidebarClassName="sidebar-class"
      contentClassName="sidebar-content-class"
      rootClassName="sidebar-root-class"
      overlayClassName="sidebar-overlay-class"
    >
      <br />
    </Sidebar>
  );
};

export default SidebarContainer;
