import { message } from 'antd';
import ENDPOINT from './addresses';
import store from '../store/store';
import {
  SET_CONNECTION,
  RESET_LAST_MESSAGE,
  RECEIVE_ENABLE_UPDATE,
  RECEIVE_ALARM_UPDATE,
  RECEIVE_NMEA_UPDATE,
  RECEIVE_MESSAGE,
  RECEIVE_NMEA_TRANSMITTING_UPDATE,
} from '../actions/types';
import { updateCircuitState } from '../actions/circuits';
import { getNMEATransmittingStatus, getNmeaMap, getInitialNMEAState } from './nmea';
import { getPersistentData } from './persistence';
import { getAlarms } from './alarms';
import { getEnables } from './enables';
import { getCircuits } from './dc';
import initializeData from '../actions/initializeData';
import receivePersistentData from '../actions/persistence';
import { mapEngine, mapBattery } from './mappers';

const socketIOClient = require('socket.io-client');
const sailsIOClient = require('sails.io.js');

const io = sailsIOClient(socketIOClient);

io.sails.url = ENDPOINT;
io.sails.reconnection = true;


function getCurrentRole() {
  return new Promise((resolve) => {
    io.socket.get(`/api/v1/role/permissions?sessionId=${localStorage.getItem('login_id')}`, (data) => {
      resolve(data);
    });
  });
}


io.socket.on('connect', () => {
  async function getStartupData() {
    const nmeaInitialState = {};
    const [
      role,
      nmeaMap,
      persistentData,
      alarms,
      enables,
      circuits,
      nmeaStatus,
    ] = await Promise.all([
      getCurrentRole(),
      getNmeaMap(),
      getPersistentData(),
      getAlarms(),
      getEnables(),
      getCircuits(),
      getNMEATransmittingStatus(),
    ]);
    // Create Object of NMEA entities with their initial state.
    // State will update with every message received using the RECEIVE_NMEA_UPDATE action.
    const promises = Object.keys(nmeaMap).map(async (entity) => {
      let entityIS = await getInitialNMEAState(entity);
      if (nmeaMap[entity].paths[0].includes('propulsion')) {
        entityIS = mapEngine(entityIS);
      } else if (nmeaMap[entity].paths[0].includes('batteries')) {
        entityIS = mapBattery(entityIS);
      }
      nmeaInitialState[entity] = entityIS;
      return nmeaInitialState;
    });
    await Promise.all(promises);
    store.dispatch(initializeData({
      role,
      persistentData,
      alarms,
      enables,
      circuits,
      nmeaStatus,
      nmeaInitialState,
    }));
  }
  getStartupData();
});
io.socket.on('disconnect', () => {
  store.dispatch({ type: RESET_LAST_MESSAGE });
  store.dispatch({ type: SET_CONNECTION, payload: false });
});
io.socket.on('enables:update', (info) => {
  if (store.getState().UI.connection.state) {
    info.updates.forEach((update) => {
      store.dispatch({ type: RECEIVE_ENABLE_UPDATE, payload: update });
    });
  }
});
io.socket.on('state:update', (info) => {
  if (store.getState().UI.connection.state) {
    if (info.updates[0].name === 'Server_Running') {
      if (Date.now() - store.getState().UI.connection.lastMessage > 1500) {
        store.dispatch({ type: RECEIVE_MESSAGE });
      }
    }
    info.updates.forEach((update) => {
      store.dispatch(updateCircuitState(update));
    });
  }
});
io.socket.on('persistence:update', (info) => store.dispatch(receivePersistentData(info.results)));
io.socket.on('alarm:update', (info) => {
  if (store.getState().UI.connection.state) {
    if (info.name !== 'Summary') {
      if (info.active && !info.acknowledge) {
        const currentAlarmState = store.getState().Alarms[info.name];
        if (!currentAlarmState.active || currentAlarmState.acknowledge) {
          message.destroy();
          message.warning(`${info.name.replace(/_/g, ' ')} is active`);
        }
      }
    }
    store.dispatch({ type: RECEIVE_ALARM_UPDATE, payload: info });
  }
});
io.socket.on('signalk', (info) => {
  if (store.getState().UI.connection.state) {
    info.updates.forEach((update) => {
      const structuredUpdate = {};
      update.values.map((value) => {
        structuredUpdate[value.path.match(/[^.]+$/)[0]] = { value: value.value };
        return null;
      });
      store.dispatch({
        type: RECEIVE_NMEA_UPDATE,
        payload: {
          entity: update.values[0].name,
          update: structuredUpdate,
        },
      });
    });
  }
});
io.socket.on('signalk:stopped_transmitting', (event) => store.dispatch({ type: RECEIVE_NMEA_TRANSMITTING_UPDATE, payload: event }));
io.socket.on('signalk:started_transmitting', (event) => store.dispatch({ type: RECEIVE_NMEA_TRANSMITTING_UPDATE, payload: event }));
io.socket.on('status:update', (update) => console.log('STATUS MESSAGE UPDATE', update));
io.socket.on('firmware', (update) => console.log(update));
