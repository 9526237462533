import React from 'react';
import { Divider, Typography } from 'antd';
import ACSources from '../shared/120VSources';
import Batteries from './Batteries';
import ChargeSources from './ChargeSources';

const Energy = () => (
  <div>
    <Typography.Paragraph className="energy-page-title">Storage</Typography.Paragraph>
    <Batteries />
    <Divider className="ac-divider" />
    <Typography.Paragraph className="energy-page-title">120V</Typography.Paragraph>
    <ACSources />
    <Divider className="ac-divider" />
    <Typography.Paragraph className="energy-page-title">Generation</Typography.Paragraph>
    <ChargeSources />
  </div>
);

export default Energy;
