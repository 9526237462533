import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { postCircuitStateUpdate } from '../../../../api/dc';


const BinaryCircuit = ({
  circuitName, disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const onOff = useSelector((state) => state.Circuits.loads[circuitName].actualState);
  const tripped = useSelector((state) => state.Circuits.fuses[circuitName].tripped);
  const partiallyOn = useSelector((state) => {
    if (state.Circuits.loads[circuitName].meta.slaves) {
      const slaves = state.Circuits.loads[circuitName].meta.slaves.map((e) => e.replace('loads.', ''));
      const fuses = slaves.map((item) => state.Circuits.fuses[item]);
      return fuses.filter((e) => e.tripped).length > 0;
    }
    return false;
  });
  useEffect(() => {
    if (loading === onOff) {
      setLoading(false);
    }
  }, [onOff, loading]);
  const sendUpdate = (e) => {
    setLoading(e ? 1 : 0);
    postCircuitStateUpdate({ name: circuitName, state: e ? 1 : 0 });
  };
  return (
    <Switch
      className={(tripped || partiallyOn) ? 'binary-circuit-switch tripped' : 'binary-circuit-switch'}
      checked={onOff === 1}
      loading={loading !== false}
      onChange={(e) => sendUpdate(e)}
      disabled={disabled}
    />
  );
};

BinaryCircuit.propTypes = {
  circuitName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default BinaryCircuit;
