import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Row, Col, Typography,
} from 'antd';
import CircuitLabelValuePair from '../../shared/CircuitLabelValuePair';
import DimmingValueSetter from './DimmingValueSetter';
import InitialValueSetter from './InitialValueSetter';
import usePermissions from '../../../../customHooks/usePermissions';

const { Text } = Typography;

const CircuitDetailList = ({ circuitName }) => {
  const circuit = useSelector((state) => state.Circuits.loads[circuitName]);
  const fuse = useSelector((state) => state.Circuits.fuses[circuitName]);
  const hasPermissions = usePermissions(circuit.meta.privilege);
  const initialValuePermissions = usePermissions('Advanced_Domestic');
  const status = useMemo(() => {
    if (circuit.meta.originalKind === 'Preset') {
      if (fuse.tripped) {
        return 'Tripped';
      }
      if (circuit.state) {
        return 'On';
      }
      return 'Off';
    }
    if (fuse.tripped) {
      return 'Tripped';
    }
    if (circuit.actualState) {
      return 'On';
    }
    return 'Off';
  }, [circuit, fuse]);

  function addZeroToNumber(n) {
    return n > 9 ? `${n}` : `0${n}`;
  }
  return (
    <div key={circuit.name} className="circuit-details" style={{ margin: 16 }}>
      { circuit.meta.originalKind.includes('IV') && (
      <Row className="circuit-label-value-pair" style={{ paddingBottom: 4 }}>
        <Col span={9} className="circuit-label">
          <Text ellipsis>On Start</Text>
        </Col>
        <Col span={15} className="circuit-value">
          <InitialValueSetter disabled={!initialValuePermissions} name={circuit.name} value={(!circuit.INITIAL_VALUE || circuit.INITIAL_VALUE.value === 0) ? 0 : 1} kind="INITIAL_VALUE" existingData={circuit.INITIAL_VALUE || null} />
        </Col>
      </Row>
      )}
      { circuit.meta.originalKind.includes('Dim 1x') && (
      <Row className="circuit-label-value-pair" style={{ paddingBottom: 4 }}>
        <Col span={9} className="circuit-label">
          <Text ellipsis>Button Level</Text>
        </Col>
        <Col span={15} className="circuit-value">
          <DimmingValueSetter disabled={!hasPermissions} currentValue={circuit.DIMMING_1X ? circuit.DIMMING_1X.value : 0.25} name={circuit.name} kind="DIMMING_1X" value={circuit.actualDimmingLevel} />
        </Col>
      </Row>
      )}
      <CircuitLabelValuePair label="Name" value={circuit.name.replace(/_/g, ' ')} leftSpan={9} rightSpan={15} />
      <CircuitLabelValuePair
        label="Status"
        value={status}
        leftSpan={9}
        rightSpan={15}
      />
      { circuit.meta.originalKind === 'Preset' && (
        <div style={{
          textAlign: 'left', marginTop: 24,
        }}
        >
          <Typography.Paragraph>Circuits Affected</Typography.Paragraph>
          <div style={{ overflow: 'auto', maxHeight: 200 }}>
            {circuit.meta.affectedCircuits.on.map((item) => <Typography.Paragraph key={item} style={{ margin: 0, lineHeight: '14px' }}><small>{item.replace(/_/g, ' ')}</small></Typography.Paragraph>)}
          </div>
        </div>
      )}
      { !circuit.meta.synthetic && (
      <CircuitLabelValuePair label="Location" value={`${circuit.meta.hardware.kind} ${addZeroToNumber(circuit.meta.hardware.instance)}:${addZeroToNumber(circuit.meta.hardware.channel)}`} leftSpan={9} rightSpan={15} />
      )}
      {circuit.meta.originalKind.includes('Dim') && (<CircuitLabelValuePair label="Dimming Level" value={`${circuit.actualDimmingLevel * 100}%`} leftSpan={9} rightSpan={15} />)}
    </div>
  );
};

CircuitDetailList.propTypes = {
  circuitName: PropTypes.string.isRequired,
};

export default CircuitDetailList;
