import React, { useState, useMemo } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import ImageMapper from 'react-image-mapper';
import { EyeOutlined } from '@ant-design/icons';
import useWindowWidth from '../../customHooks/useWindowWidth';
import HH66_MOBILE from '../../assets/boatViews/mobile.png';
import HH66_SM from '../../assets/boatViews/sm.png';
import HH66_MD from '../../assets/boatViews/md.png';
import HH66_LG from '../../assets/boatViews/lg.png';
import HH66_XL from '../../assets/boatViews/xl.png';
import HH66_FULL_SIZE from '../../assets/boatViews/hh66_full_size.png';
import {
  mobile_map, sm_map, md_map, lg_map, xl_map, full_size_map,
} from '../../assets/imagemaps';

const Home = () => {
  const width = useWindowWidth();
  const history = useHistory();
  const [hoveredArea, setHoveredArea] = useState(null);

  const linkToLocation = (area) => {
    history.push(`${area.name}`);
  };

  window.oncontextmenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  const enterArea = (area) => {
    setHoveredArea(area);
  };

  const leaveArea = () => {
    setHoveredArea(null);
  };

  const chooseMap = useMemo(() => {
    if (width > 1198) {
      return ({ map: full_size_map, src: HH66_FULL_SIZE });
    } if (width > 1078) {
      return ({ map: xl_map, src: HH66_XL });
    } if (width > 958) {
      return ({ map: lg_map, src: HH66_LG });
    } if (width > 818) {
      return ({ map: md_map, src: HH66_MD });
    } if (width > 500) {
      return ({ map: sm_map, src: HH66_SM });
    }
    return ({ map: mobile_map, src: HH66_MOBILE });
  }, [width]);

  return (
    <div>
      { hoveredArea && (
      <span className="home-view-tooltip">
        <EyeOutlined className="back-icon-page-title" />
        { hoveredArea && hoveredArea.displayName}
      </span>
      )}
      <Row justify="center">
        <Col span={24} className="location-view">
          <ImageMapper
            autofocus
            oncontext
            menu={false}
            src={chooseMap.src}
            map={chooseMap.map}
            onMouseEnter={(area) => enterArea(area)}
            onMouseLeave={(area) => leaveArea(area)}
            onClick={linkToLocation}
            fillColor="rgba(255,255,255,0.5)"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
