import React from 'react';
import { Row, Col } from 'antd';
import {
  Refrigerator,
  Water_Maker,
  Freezer,
  LPG_Sniffer,
} from '../../../api/circuits';
import RenderCircuitByType from '../../widgets/circuits/DCM/RenderCircuitByType';

const Galley = () => (
  <Row>
    <Col xs={24} sm={12}>
      <RenderCircuitByType circuitName={Refrigerator} title="Refrigerator" />
    </Col>
    <Col xs={24} sm={12}>
      <RenderCircuitByType circuitName={Freezer} title="Freezer" />
    </Col>
    <Col span={24} sm={12}>
      <RenderCircuitByType circuitName={LPG_Sniffer} title="LPG" />
    </Col>
    <Col xs={24} sm={12}>
      <RenderCircuitByType circuitName={Water_Maker} title="Water Maker" />
    </Col>
  </Row>
);

export default Galley;
