import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Slider, Typography, Col, Row,
} from 'antd';
import BinaryCircuit from '../circuits/DCM/BinaryCircuit';
import { postValueUpdate } from '../../../api/dc';
import { Waste_Tank_Level_Enable } from '../../../api/circuits';

const { Paragraph } = Typography;

const WasteTankLevelFullWidget = () => {
  const wasteTankLevel = useSelector((state) => state.Circuits.values.Waste_Tank_Level_Full);
  const [value, setValue] = useState(wasteTankLevel.value);
  return (
    <Row justify="center">
      <Col xs={24} sm={18} md={12}>
        <div className="circuit-container">
          <div className="dimmable-circuit">
            <Paragraph type="secondary">Auto Shutoff Settings</Paragraph>
            <BinaryCircuit circuitName={Waste_Tank_Level_Enable} />
            <Slider
              step={1}
              value={value}
              max={100}
              min={0}
              onChange={(e) => {
                setValue(e);
              }}
              onAfterChange={(e) => {
                postValueUpdate({ name: wasteTankLevel.name, value: e });
              }}
              tipFormatter={(e) => `${e}%`}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default WasteTankLevelFullWidget;
