/**
 * @package      DC control software (UI)
 * @description  List of alarms, generated at 2020-04-09 18:05
 * @date         2020-04-09 18:05
 * @author       Decipher Industries <info@decipher.industies>
 * @copyright    2020 (c) Kinetic Scientific, all rights reserved.
 */

/* ALARMS */
export const Summary = 'Summary';
export const Water_Port_Low = 'Water_Port_Low';
export const Water_Port_High = 'Water_Port_High';
export const Water_Stbd_Low = 'Water_Stbd_Low';
export const Water_Stbd_High = 'Water_Stbd_High';
export const Waste_Port_Low = 'Waste_Port_Low';
export const Waste_Port_High = 'Waste_Port_High';
export const Waste_Stbd_Low = 'Waste_Stbd_Low';
export const Waste_Stbd_High = 'Waste_Stbd_High';
export const Fuel_Port_Low = 'Fuel_Port_Low';
export const Fuel_Port_High = 'Fuel_Port_High';
export const Fuel_Stbd_Low = 'Fuel_Stbd_Low';
export const Fuel_Stbd_High = 'Fuel_Stbd_High';
export const Start_Port_Voltage_Low = 'Start_Port_Voltage_Low';
export const Start_Port_Voltage_High = 'Start_Port_Voltage_High';
export const Start_Port_SOC_Low = 'Start_Port_SOC_Low';
export const Start_Port_SOC_High = 'Start_Port_SOC_High';
export const Start_Stbd_Voltage_Low = 'Start_Stbd_Voltage_Low';
export const Start_Stbd_Voltage_High = 'Start_Stbd_Voltage_High';
export const Start_Stbd_SOC_Low = 'Start_Stbd_SOC_Low';
export const Start_Stbd_SOC_High = 'Start_Stbd_SOC_High';
export const House_Bank_Voltage_Low = 'House_Bank_Voltage_Low';
export const House_Bank_Voltage_High = 'House_Bank_Voltage_High';
export const House_Bank_SOC_Low = 'House_Bank_SOC_Low';
export const House_Bank_SOC_High = 'House_Bank_SOC_High';
export const House_Bank_Temperature_Low = 'House_Bank_Temperature_Low';
export const House_Bank_Temperature_High = 'House_Bank_Temperature_High';
export const House_Battery_1_Voltage_Low = 'House_Battery_1_Voltage_Low';
export const House_Battery_1_Voltage_High = 'House_Battery_1_Voltage_High';
export const House_Battery_1_SOC_Low = 'House_Battery_1_SOC_Low';
export const House_Battery_1_SOC_High = 'House_Battery_1_SOC_High';
export const House_Battery_1_Temperature_Low = 'House_Battery_1_Temperature_Low';
export const House_Battery_1_Temperature_High = 'House_Battery_1_Temperature_High';
export const House_Battery_2_Voltage_Low = 'House_Battery_2_Voltage_Low';
export const House_Battery_2_Voltage_High = 'House_Battery_2_Voltage_High';
export const House_Battery_2_SOC_Low = 'House_Battery_2_SOC_Low';
export const House_Battery_2_SOC_High = 'House_Battery_2_SOC_High';
export const House_Battery_2_Temperature_Low = 'House_Battery_2_Temperature_Low';
export const House_Battery_2_Temperature_High = 'House_Battery_2_Temperature_High';
export const House_Battery_3_Voltage_Low = 'House_Battery_3_Voltage_Low';
export const House_Battery_3_Voltage_High = 'House_Battery_3_Voltage_High';
export const House_Battery_3_SOC_Low = 'House_Battery_3_SOC_Low';
export const House_Battery_3_SOC_High = 'House_Battery_3_SOC_High';
export const House_Battery_3_Temperature_Low = 'House_Battery_3_Temperature_Low';
export const House_Battery_3_Temperature_High = 'House_Battery_3_Temperature_High';
export const Engine_Port_Temperature = 'Engine_Port_Temperature';
export const Engine_Port_Oil_Pressure = 'Engine_Port_Oil_Pressure';
export const Engine_Stbd_Temperature = 'Engine_Stbd_Temperature';
export const Engine_Stbd_Oil_Pressure = 'Engine_Stbd_Oil_Pressure';
export const Hydraulic_Oil_Temp_High = 'Hydraulic_Oil_Temp_High';
export const Hydraulic_Oil_Level_Low = 'Hydraulic_Oil_Level_Low';
export const Forepeak_Bilge_Port = 'Forepeak_Bilge_Port';
export const Forepeak_Bilge_Stbd = 'Forepeak_Bilge_Stbd';
export const Mid_Bilge_Port = 'Mid_Bilge_Port';
export const Mid_Bilge_Stbd = 'Mid_Bilge_Stbd';
export const Engine_Bilge_Port = 'Engine_Bilge_Port';
export const Engine_Bilge_Stbd = 'Engine_Bilge_Stbd';
export const SeaFire_Shutdown_Port = 'SeaFire_Shutdown_Port';
export const SeaFire_Shutdown_Stbd = 'SeaFire_Shutdown_Stbd';
export const Windlass_Over_Temperature = 'Windlass_Over_Temperature';
