export const mapEngine = (data) => ({
  temperature: data.temperature || { value: -1 },
  alternatorVoltage: data.alternatorVoltage,
  rate: data.fuel ? data.fuel.rate : { value: -1 },
  pressure: data.fuel ? data.fuel.pressure : { value: -1 },
  oilPressure: data.oilPressure || { value: -1 },
  runTime: data.runTime || { value: -1 },
  coolantPressure: data.coolantPressure || { value: -1 },
  engineLoad: data.engineLoad || { value: -1 },
  engineTorque: data.engineTorque || { value: -1 },
  revolutions: data.revolutions || { value: -1 },
});

export const mapBattery = (data) => ({
  voltage: data.voltage || { value: -1 },
  current: data.current || { value: -1 },
  temperature: data.temperature || { value: -1 },
  stateOfCharge: data.capacity ? data.capacity.stateOfCharge : { value: -1 },
  stateOfHealth: data.capacity ? data.capacity.stateOfHealth : { value: -1 },
  timeRemaining: data.capacity ? data.capacity.timeRemaining : { value: -1 },
});

export const mapTank = (data) => ({
  currentLevel: data.currentLevel || { value: -1 },
  capacity: data.capacity || { value: -1 },
});
