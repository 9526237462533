import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PassiveRam from './types/PassiveRam';
import PassiveRamWithDump from './types/PassiveRamWithDump';
import RotatorRam from './types/RotatorRam';
import DaggerboardRam from './types/DaggerboardRam';
import LinearAbsolute from './types/LinearAbsolute';
import HydraulicsDisconnectedSpinner from '../../widgets/hydraulics/HydraulicsDisconnectedSpinner';

const ActuatorLayout = () => {
  const { id } = useParams();
  const type = useSelector((state) => state.Hydraulics[id].kind);
  const actuator = useSelector((state) => state.Hydraulics[id].name);
  const hydraulicsConnected = useSelector((state) => state.ServerState.hydraulics);
  const renderActuator = useMemo(() => {
    switch (type) {
      case 'Passive_Ram': {
        return (
          <PassiveRam actuator={actuator} />
        );
      }
      case 'Passive_Ram_with_Dump': {
        return (
          <PassiveRamWithDump actuator={actuator} />
        );
      }
      case 'Rotator_Ram': {
        return (
          <RotatorRam actuator={actuator} />
        );
      }
      case 'Daggerboard_Ram': {
        return (
          <DaggerboardRam daggerboard={actuator} rake={actuator.replace('Daggerboard', 'Daggerboard_Rake')} />
        );
      }
      case 'Linear_Absolute': {
        return (
          <LinearAbsolute actuator={actuator} />
        );
      }
      default:
        return <div>Not Found</div>;
    }
  }, [actuator, type]);
  if (!hydraulicsConnected) {
    return <HydraulicsDisconnectedSpinner />;
  }
  return (
    <div>
      {renderActuator}
    </div>
  );
};

export default ActuatorLayout;
